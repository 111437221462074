import React, { useState, useEffect } from "react";
import { IdentityService } from "services/identity/IdentityService";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { IdentityResponse } from "services/apis/types/identity/IdentityResponse";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";

export default function LoginSuccess() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const getUserMetadata = async () => {
      getAccessTokenSilently({
        audience: process.env.REACT_APP_API_URL,
        scope: "read:current_user",
      }).then(e => {
        console.log("accesstoken ", e);
        if (e) setAccessToken(e);
      }
      ).catch(ex => console.error(ex));
    };

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (accessToken) {
      IdentityService.userInfo(accessToken)
        .then((response: IdentityResponse) => {
          console.log("response ", response);
        })
        .catch((ex) => {
          console.error(ex);

        })
        .finally(() => { });
    }
  }, [accessToken]);

  return (
    <LoginLayout>
      <LoginHeader title="" />
      <LoadingIndicator className="center" white />
    </LoginLayout>
  );
}
