import React from "react";
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator";

type Props = {
  white?: boolean;
  small?: boolean;
  className?: string;
};

export function ModalLoadingIndicator(props: Props) {
  return (
    <div className="overlay">
      <div className="overlay__inner">
        <div className="overlay__content"><LoadingIndicator /></div>
      </div>
    </div>
  );
}
