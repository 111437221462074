import React, { useState, FormEvent } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Alert
} from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import { IdentityApi } from "services/apis/IdentityApi";
import { AddError, HasErrors, Errors } from "components/framework/errorHandling/ErrorUtil";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import TextFormInputGroup from "components/framework/forms/TextFormInputGroup";
import { useErrors } from "services/customHooks/useErrors";
import { isEmail } from "services/util/StringUtil";

export default function ForgotPassword() {
  const { setErrors, getErrorHandler } = useErrors();
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [userName, setUserName] = useState("");
  // const [errors, setErrors] = useState<Errors>({});

  const intl = useIntl();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const errors = validateModel(userName, email);

    if (HasErrors(errors)) {
      setErrors(errors);
      setShowLoadingIndicator(false);
    } else {
      setShowLoadingIndicator(true);

      IdentityApi.requestPasswordReset({ userName, email })
        .then(() => setShowSuccessMessage(true))
        .catch(() => setShowError(true))
        .finally(() => setShowLoadingIndicator(false));
    }
  };

  return (
    <LoginLayout>
      <LoginHeader title={intl.formatMessage({ id: "account.forgotPassword.header" })} />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Alert color="danger" isOpen={showError} toggle={() => setShowError(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage id="account.forgotPassword.error" />
                </strong>
              </span>
            </Alert>
            <Alert color="info" isOpen={showSuccessMessage} toggle={() => setShowSuccessMessage(false)}>
              <span className="alert-text ml-1">
                <strong>
                  <FormattedMessage id="account.forgotPassword.successMessage" values={{ email: email }} />
                </strong>
              </span>
            </Alert>

            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <small>
                    <FormattedMessage id="account.forgotPassword.title" />
                  </small>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <TextFormInputGroup
                    icon="fa-user"
                    placeholderTranslationId="account.login.userName"
                    value={userName}
                    handleInputChange={(value: string) => setUserName(value)}
                    errorHandler={getErrorHandler("userName")}
                  />
                  <TextFormInputGroup
                    icon="fa-envelope"
                    placeholderTranslationId="account.login.email"
                    type="email"
                    value={email}
                    handleInputChange={(value: string) => setEmail(value)}
                    errorHandler={getErrorHandler("email")}
                  />
                  {/* <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail
                    })}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={intl.formatMessage({ id: "account.forgotPassword.email" })}
                        type="email"
                        onFocus={() => setFocusedEmail(true)}
                        onBlur={() => setFocusedEmail(false)}
                        value={email}
                        onChange={e => {
                          setEmail(e.target.value);
                          setErrors({ ...errors, email: [] });
                        }}
                      />
                    </InputGroup>
                    <InputError errors={errors["email"]} />
                  </FormGroup> */}
                  <div className="text-center">
                    <Button className="mt-4" color="primary" type="submit">
                      {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                      <FormattedMessage id="account.forgotPassword.resetPassword" />
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <small>
                  <Link to="/login" className="text-light">
                    <FormattedMessage id="account.forgotPassword.goBack" />
                  </Link>
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </LoginLayout>
  );
}

const validateModel = (userName: string, email: string): Errors => {
  const errors: Errors = {};

  if (!userName) {
    AddError(errors, "userName", "account.login.userName.emptyError");
  }
  if (!email) {
    AddError(errors, "email", "account.login.email.emptyError");
  } else if (!isEmail(email)) {
    AddError(errors, "email", "account.login.email.invalid");
  }

  return errors;
};
