import React from "react";
import {
    Container,
} from "reactstrap";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { ActivityDto } from "services/activities/ActivityDto";
import ActivityCard from "./ActivityCard";

type Props = {
    status: string;
    showLoadingIndicator : boolean;
    activities: Array<ActivityDto>;
};

export default function Activities(props: Props) {
    return (
        <>
            <Container className="nopadding">
                {props.showLoadingIndicator ?
                    <LoadingIndicator white />
                    :
                    <ActivityCard activities={props.activities} status={props.status} />
                }
            </Container>
        </>
    );
}
