import React from "react";
import TollFreeQueryCreate from "components/inventory/toll-free-numbers/TollFreeQueryCreate";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { FormattedMessage } from "react-intl";

export default function SearchExplorar() {
  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="tollfree.explore.title" />
      </BreadcrumbHeader>
      <TollFreeQueryCreate />
    </>
  );
}
