import React, { useState, FormEvent, useContext } from "react";
import { CardBody, Card, Col, Row, CardHeader, Button, Form, CardFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleError } from "services/util/ApiUtil";
import { AppContext } from "services/appContext/AppContext";
import TextFormInput from "components/framework/forms/TextFormInput";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { AccountApi } from "services/apis/AccountApi";
import {
  UpdateAccountDto,
  DefaultUpdateAccountDto
} from "services/apis/types/account/UpdateAccountDto";
import { useIsMounted } from "services/customHooks/useIsMounted";

export default function ProfileCard() {
  const isMounted = useIsMounted();
  const intl = useIntl();
  const { appContext, setAppContext } = useContext(AppContext);
  const { setErrors, getErrorHandler } = useErrors();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [profile, setProfile] = useState<UpdateAccountDto>(mapToUpdateUserDto(appContext));


  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateModel(profile);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      AccountApi.updateInfoProfile(appContext.localStorageInfo.user?.id, profile)
        .then(() => {
          let user = appContext.localStorageInfo.user
            ? { ...appContext.localStorageInfo.user, ...profile }
            : undefined;

          setAppContext({
            ...appContext,
            localStorageInfo: {
              ...appContext.localStorageInfo,
              user: user
            }

          });

          showInfoNotification(intl.formatMessage({ id: "myProfile.profile.successMessage" }));
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };


  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              <FormattedMessage id="profileCard.title" />
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <CardBody>
          <Row className="d-flex flex-row">
            <Col className="col-md-6 col-lg-6">
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="profileCard.email"
                  value={profile.email}
                  handleInputChange={(value: string) => setProfile({ ...profile, email: value })}
                  errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("email"))}
                />
              </Row>
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="profileCard.firstName"
                  value={profile.firstName}
                  handleInputChange={(value: string) => setProfile({ ...profile, firstName: value })}
                  errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("firstName"))}
                />
              </Row>
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="profileCard.lastName"
                  value={profile.lastName}
                  handleInputChange={(value: string) => setProfile({ ...profile, lastName: value })}
                  errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("lastName"))}
                />
              </Row>
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="profileCard.userName"
                  value={profile.username}
                  handleInputChange={(value: string) => setProfile({ ...profile, username: value })}
                  errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("username"))}
                />
              </Row>
              <Row>
                <TextFormInput
                  formGroupClassName="col-lg-12"
                  labelTranslationId="profileCard.phoneNumber"
                  value={profile.phone}
                  handleInputChange={(value: string) => setProfile({ ...profile, phone: value })}
                  errorHandler={getErrorHandler(nameOf<UpdateAccountDto>("phone"))}
                />
              </Row>
            </Col>
            <Col className="col-md-6 col-lg-6 mt-3">
              <Row>
                <CheckboxFormInput
                  className="col-lg-12 checkbox-settings-sub pl-2 ml-4 mt-3"
                  label="Send Text Notifications"
                  value={"profile.sendTextNotifications"}
                  checked={profile.sendTextNotifications}
                  handleInputChange={(value: boolean) => setProfile({ ...profile, sendTextNotifications: value })}
                >

                </CheckboxFormInput>
              </Row>
              {/* <Row>
                <CheckboxFormInput
                  className="col-lg-12 checkbox-settings-sub pl-2 ml-4 mt-3"
                  label="Lockout on Failed Logins"
                  value={"profile.lockoutEnabled"}
                  checked={profile.lockoutEnabled}
                  handleInputChange={(value: boolean) =>
                    setProfile({ ...profile, lockoutEnabled: value })
                  }             >
                </CheckboxFormInput>
              </Row> */}
              <Row>
                <CheckboxFormInput
                  className="col-lg-12 checkbox-settings-sub pl-2  ml-4 mt-3"
                  label="Multi-Factor Authentication"
                  value={"profile.multiFactorAuthEnabled"}
                  checked={profile.requireMultiFactorAuth}
                  handleInputChange={(value: boolean) =>
                    setProfile({ ...profile, requireMultiFactorAuth: value })
                  }           >
                </CheckboxFormInput>
              </Row>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-right">
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="profileCard.button" />
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
}

const validateModel = (model: UpdateAccountDto) => {
  const errors: Errors = {};

  if (!model.email) {
    AddError(errors, nameOf<UpdateAccountDto>("email"), "myProfile.profile.email.required");
  }
  if (!model.firstName) {
    AddError(errors, nameOf<UpdateAccountDto>("firstName"), "myProfile.profile.firstName.required");
  }
  if (!model.lastName) {
    AddError(errors, nameOf<UpdateAccountDto>("lastName"), "myProfile.profile.lastName.required");
  }

  return errors;
};


function mapToUpdateUserDto(appContext: AppContext): UpdateAccountDto | (() => UpdateAccountDto) {
  return appContext.localStorageInfo.user
    ? {
      email: appContext.localStorageInfo.user.email,
      firstName: appContext.localStorageInfo.user.firstName,
      lastName: appContext.localStorageInfo.user.lastName,
      phone: appContext.localStorageInfo.user.phone,
      username: appContext.localStorageInfo.user.username,
      sendTextNotifications: appContext.localStorageInfo.user.sendTextNotifications,
      requireMultiFactorAuth: appContext.localStorageInfo.user.requireMultiFactorAuth,
      enabled: true,
      // userSettings: {
      //   ui: JSON.stringify(appContext.localStorageInfo.user.settings)
      // }
    }
    : DefaultUpdateAccountDto;
}

