import React from "react";
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="mt-3" id="footer-main">
      <Container fluid>
        <Row>
          <Col xs="12" md="6">
            <div className="copyright text-muted d-flex justify-content-center justify-content-md-start">
              © {new Date().getFullYear()} <FormattedMessage id="atl.rights.reserve" />
              <Link
                className="font-weight-bold ml-1 footer-color"
                to="/"
                target="_blank"
                rel="noopener noreferrer">
                <FormattedMessage id="footer.Atlas" />
              </Link>
            </div>
          </Col>
          <Col xs="12" md="6">
            <Nav className="nav-footer justify-content-center justify-content-md-end">
              <NavItem>
                <NavLink href="https://atlc.com/about/" target="_blank">
                  <FormattedMessage id="footer.aboutus" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://atlc.com/atlas-end-user-license-agreement/" target="_blank">
                  <FormattedMessage id="footer.endUserLicense" />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
