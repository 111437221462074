import { IdentityResponse } from "./types/identity/IdentityResponse";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { RequestPasswordReset } from "services/apis/types/identity/RequestPasswordReset";
import { HttpClient } from "services/httpClient/HttpClient";
import { ResetPassword } from "services/apis/types/identity/ResetPassword";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { RequestLogin } from "./types/identity/RequestLogin";
import { ApiError } from "../apis/types/ApiError";
import { FieldError } from "./types/FieldError";
import { RequestAuthCodeVerify } from "./types/identity/RequestAuthCodeVerify";
import { UpdateAccountDto } from "./types/account/UpdateAccountDto";

export const IdentityApi = {
  login: async (requestLogin: RequestLogin): Promise<IdentityResponse> => {
    return fetch(EnvironmentUtil.identityServerUrl("authorize/verifyPassword"), getRequestInit(requestLogin)).then(handleResponse<IdentityResponse>());
  },

  userInfo: async (auth0AccessToken: string): Promise<IdentityResponse> => {
    return HttpClient.getUserInfo<IdentityResponse>("authorize/userInfo", auth0AccessToken);
  },

  verifyAuthCode: async (requestAuthCodeVerify: RequestAuthCodeVerify): Promise<IdentityResponse> => {
    return fetch(EnvironmentUtil.identityServerUrl("authorize/verifyAuthCode"), getRequestInit(requestAuthCodeVerify)).then(handleResponse<IdentityResponse>());
  },

  refreshAccessToken: async (): Promise<IdentityResponse> => {
    const response = await fetch(EnvironmentUtil.identityServerUrl("authorize/refreshAccess"), getRequestInit({ accessToken: AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.access_token }));
    const identityResponse = (await response.json()) as IdentityResponse;
    return identityResponse;
  },

  requestPasswordReset: async (requestPasswordReset: RequestPasswordReset): Promise<Response> => {
    return fetch(EnvironmentUtil.identityServerUrl("authorize/resetPassword"), getRequestInit(requestPasswordReset))
      .then(handleResponse<Response>());
  },

  resetPassword: async (resetPassword: ResetPassword): Promise<Response> => {
    return HttpClient.post<Response>("account/resetpassword", resetPassword, false);
  },

  changePassword: async (userId: string | undefined, changePasswordDto: UpdateAccountDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`users/${userId}`, changePasswordDto, true, true);
  },

  // portControlApi: async (): Promise<IdentityResponse> => {
  //   let requestParams = queryString.stringify({
  //     username: EnvironmentUtil.oAuthUsername,
  //     password: EnvironmentUtil.oAuthPassword,
  //     scope: EnvironmentUtil.oAuthScope,
  //     // client_id: EnvironmentUtil.oAuthClientId,
  //     // client_secret: EnvironmentUtil.oAuthClientSecret,
  //     client_id: 'Client.PortControl.Web',
  //     client_secret: '3AKIx0pz4Lvm4V3dYw7Afg4vw3j9bZ',
  //     grant_type: "password",
  //   });

  //   const response = await fetch(EnvironmentUtil.oAuthIdentityUrl("connect/token"), getRequestInitPortControl(requestParams));
  //   const json = await response.json();

  //   return json as IdentityResponse;
  // },
};

const getRequestInit = (bodyObject: any): RequestInit => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(bodyObject)
  };
};

// const getRequestInitPortControl = (body: string): RequestInit => {
//   return {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded"
//     },
//     body: body
//   };
// };

const handleResponse = <T>(): ((value: Response) => Promise<T>) => {
  return async (response) => {
    if (response.ok) {
      try {
        const json = await response.json();

        return json as T;
      } catch (error) {
        return (response as unknown) as T;
      }
    } else {
      return await handleErrorResponse(response);
    }
  };
};

const handleErrorResponse = async (response: Response) => {
  let json: {
    fieldErrors?: { [id: string]: FieldError[] };
    message?: string;
    error?: number;
  } = {};

  try {
    json = await response.json();
  } catch (error) {
    //ignore
  }

  const apiError: ApiError = { ...json, error: response.status };

  throw apiError;
};
