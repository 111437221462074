import Authorize from "components/framework/authorization/Authorize";
import { showErrorNotification } from "components/framework/notification/NotificationUtil";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Row, Col, Button, CardBody, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { PermissionType } from "services/authorization/PermissionType";
import TollFreeBulkUpdateModal from "./TollFreeBulkUpdateModal";
import TollFreeQueryReserveModal from "./TollFreeQueryReserveModal";
import TollFreeQueryUpsertModal from "./TollFreeQueryUpsertModal";
import TollFreeReportModal from "./TollFreeReportModal";
import { TollFreeHeaders } from "./types/TollFreeHeaders";

type Props = {
  selectedTollFreeNumbers: any;
  refreshCheckbox: any;
};

export default function TollFreeHeader(props: Props) {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showReserveModal, setShowReserveModal] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showBulkUpload, setShowBulkUploadModal] = useState(false);
  const [, setTollFreeHeaders] = useState<TollFreeHeaders>();
  const intl = useIntl();

  const dropdownToggle = () => setShowDropdown((prevState) => !prevState);

  const handleCreateDropdownItemButtonClickForTollFree = (tollFreeHeaders: TollFreeHeaders) => {
    setShowSettingsModal(true);
    setShowDropdown(false);
    setTollFreeHeaders(tollFreeHeaders);
  };

  const handleCreateDropdownItemButtonClickForReserve = (tollFreeHeaders: TollFreeHeaders) => {
    setShowReserveModal(true);
    setShowDropdown(false);
    setTollFreeHeaders(tollFreeHeaders);
  };

  const handleCreateDropdownItemButtonClickForReports = () => {
    if (props.selectedTollFreeNumbers.length === 0) {
      showErrorNotification(intl.formatMessage({ id: "tollfree.report.errorNotificationMessage" }))
    }
    if (props.selectedTollFreeNumbers.length > 500) {
      showErrorNotification(intl.formatMessage({ id: "tollfree.report.errorNotificationMessageForValidNumber" }))
    }else if (props.selectedTollFreeNumbers.length > 0){
      setShowReports(true);
    }
    setShowDropdown(false);
  };

  const handleCreateDropdownItemButtonClickForBulkUpload = (tollFreeHeaders: TollFreeHeaders)=>{
    setShowBulkUploadModal(true);
    setShowDropdown(false);
    setTollFreeHeaders(tollFreeHeaders);
  }
  return (
    <>
      <CardBody>
        <Row className="align-items-center">
          <Col>
            <span className="toll-free-title display-3">
              <FormattedMessage id="tollfree.title" />
            </span>
          </Col>
          <Authorize
            userPermissions={[
              PermissionType.InsertTollFrees,
              PermissionType.GlobalAdmin,
            ]}
          >
            <Col className="d-flex justify-content-end">
              <div className="pc-create-dropdown">
                <Dropdown isOpen={showDropdown} toggle={dropdownToggle}>
                  <DropdownToggle className="cursor-pointer pb-0 pt-0v" tag="span">
                    <Button
                      color="warning"
                      type="button"
                    >
                      <i className="fa fa-plus-square mr-2" />
                      <FormattedMessage id="tollfree.button.create.title" />
                    </Button>
                  </DropdownToggle>
                  <DropdownMenu positionFixed={true} className="dropdown-menu-md dropdown-menu-white bg-dark pc-no-shadow text-white">
                    <Row className="shortcuts px-4">
                      <Col
                        className="shortcut-item cursor-pointer col-lg-6"
                        onClick={() =>
                          handleCreateDropdownItemButtonClickForTollFree(TollFreeHeaders.TollFreeNumber)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-blue">
                          <i className="fas fa-boxes" />
                        </span>
                        <small>
                          <FormattedMessage id="tollfree.title" />
                        </small>
                      </Col>
                      <Col
                        className="shortcut-item cursor-pointer col-lg-6"
                        onClick={() =>
                          handleCreateDropdownItemButtonClickForReserve(TollFreeHeaders.Reserve)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-pc-orange">
                          <i className="fa fa-check-double" />
                        </span>
                        <small>
                          <FormattedMessage id="tollfree.table.actions.bulkQuery" />
                        </small>
                      </Col>
                      <Col
                        className="shortcut-item cursor-pointer col-lg-6"
                        onClick={() =>
                          handleCreateDropdownItemButtonClickForReports()
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                          <i className="fas fa-chart-pie" />
                        </span>
                        <small>
                          <FormattedMessage id="table.report" />
                        </small>
                      </Col>
                      <Col
                        className="shortcut-item cursor-pointer col-lg-6"
                        onClick={() =>
                          handleCreateDropdownItemButtonClickForBulkUpload(TollFreeHeaders.BulkUpload)
                        }
                        xs="4">
                        <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                          <i className="fas fa-solid fa-upload" />
                        </span>
                        <small>
                          <FormattedMessage id="table.bulkUpload" />
                        </small>
                      </Col>
                    </Row>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Authorize>
        </Row>
        {showSettingsModal && (
          <TollFreeQueryUpsertModal
            closeModal={() => setShowSettingsModal(false)}
          />
        )}
        {showReserveModal && (
          <TollFreeQueryReserveModal
            closeModal={() => setShowReserveModal(false)}
          />
        )}
        {showReports && (
          <TollFreeReportModal selectedTollFreeNumbers={props.selectedTollFreeNumbers} refreshCheckbox={props.refreshCheckbox} closeModal={() => setShowReports(false)}/>
        )}
        {showBulkUpload && (
          <TollFreeBulkUpdateModal
            closeModal={() => setShowBulkUploadModal(false)}
          />
        )}
      </CardBody>
    </>
  );
}
