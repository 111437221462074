import React, { useEffect, useMemo } from "react";
import { Modal } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import ExploreProfileCard from "./ExploreProfileCard";
import { DefaultServiceProviderResult } from "../types/ServiceProviderProfileResult";
import useServiceProviderResult from "services/customHooks/useServiceProviderResult";
import { ModalLoadingIndicator } from "components/framework/modalLoadingIndicator/ModalLoadingIndicator";

type Props = {
  closeModal: () => void;
  spId?: string;
};

export default function ServiceProviderModal(props: Props) {
  const { serviceProvider, isLoading, isError, setIsError, serviceProviderAccessToken } = useServiceProviderResult(props.spId);

  const showModal = useMemo(() => (serviceProvider || !props.spId) && !isLoading, [
    serviceProvider,
    props.spId,
    isLoading
  ]);

  useEffect(() => {
    if (isError) {
      props.closeModal();
      setIsError(false)
    }
    
  }, [isError, setIsError,props]);

  return (
    <>
      {isLoading && <ModalLoadingIndicator />}
      {showModal && (
        <Modal className="pc-modal-xlg modal-dialog-centered" isOpen={true}>
          <CloseButton close={props.closeModal} />
          <ExploreProfileCard
            serviceProviderResult={serviceProvider ? serviceProvider : DefaultServiceProviderResult}
            serviceProviderAccessToken={serviceProviderAccessToken}
          />
        </Modal>
      )}
    </>
  );
}
