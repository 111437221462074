import React, { useState } from "react";
import AdminNavbar from "./AdminNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "components/framework/footer/Footer";
import Sidebar from "components/framework/sidebar/Sidebar";
import { BodyClassUtil } from "services/util/BodyClassUtil";
import { hasAnyPermissions } from "services/authorization/AuthorizationService";

type Props = {
  children: React.ReactNode;
};

export default function AdminLayout(props: Props) {
  const [sidenavOpen, setSidenavOpen] = useState(true);

  const toggleSidenav = () => {
    if (BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.removeClass("g-sidenav-pinned");
      BodyClassUtil.addClass("g-sidenav-hidden");
    } else {
      BodyClassUtil.addClass("g-sidenav-pinned");
      BodyClassUtil.removeClass("g-sidenav-hidden");
    }

    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      <ToastContainer />
      {hasAnyPermissions() && <Sidebar sidenavOpen={sidenavOpen} toggleSidenav={toggleSidenav} />}
      <div className="main-content bg-dark">
        <AdminNavbar sidenavOpen={sidenavOpen} toggleSidenav={toggleSidenav} />
        <div className="mb-2">{props.children}</div>
        <Footer />
      </div>
    </>
  );
}
