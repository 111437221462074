import React, { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
// import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { Button, Card, CardHeader, Col, Collapse, Form, Input, Nav, Navbar, NavbarToggler, NavLink, Row } from "reactstrap";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import classnames from "classnames";
import ActivitiesSettings from "./ActivitiesSettings";
import TollFreeSettings from "./TollFreeSettings";
import { useCallback, useEffect } from "react";
import { handleError } from "services/util/ApiUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { useContext } from "react";
import { AppContext } from "services/appContext/AppContext";
import { SettingsApi } from "services/apis/SettingsApi";
import { SettingsNotificationDto } from "services/apis/types/settings/SettingsNotificationDto";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { FormEvent } from "react";
import { RequestSettingsNoficationsDto } from "services/apis/types/settings/RequestSettingsNotificationsDto";
import ToggleCheckbox from "components/framework/forms/ToggleCheckbox";
import { UserDetailsDto } from "services/apis/types/settings/UserDetailsDto";



export default function EnableNotifications() {
    const { setErrors } = useErrors();
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [enabledActivityNotifications, setEnabledActivityNotifications] = useState<Array<SettingsNotificationDto>>([]);
    const [enabledTollFreeNotifications, setEnabledTollFreeNotifications] = useState<Array<SettingsNotificationDto>>([]);
    const intl = useIntl();
    const [openedActivities, setOpenedActivities] = useState<boolean>(true);
    const [openedTollFree, setOpenedTollFree] = useState<boolean>(true);
    const [notificationType, setNotificationType] = useState<boolean>(false);
    const [tollFreeNotificationType, setTollFreeNotificationType] = useState<boolean>(false);
    const [search, setNewSearch] = useState("");
    const isMounted = useIsMounted();
    const { appContext } = useContext(AppContext);
    const [disabledButton, setDisabledButton] = useState<boolean>(true);
    const [textMessageNotification, setTextMessageNotification] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<UserDetailsDto>();

    const fetchSettings = useCallback(() => {
        let tollFreeList: SettingsNotificationDto[] = [];
        let activitiesList: SettingsNotificationDto[] = [];
        let checkAllAssignedTollFree: any[] = [];
        let checkAllAssignedActivity: any[] = [];
        setShowLoadingIndicator(true);
        SettingsApi.get(appContext.localStorageInfo.user?.id)
            .then((result) => {
                if (isMounted) {
                    setDisabledButton(true);
                    result.forEach(element => {
                        element.modifiedAssigned = element.assigned;
                        if (element.activityKindName.includes('Toll-Free')) {
                            tollFreeList.push(element);
                            checkAllAssignedTollFree.push(element.assigned);
                            let resulantCheckAllAssignedTollFree = checkAllAssignedTollFree.every((assignedTollFree) => assignedTollFree === true);
                            if (resulantCheckAllAssignedTollFree) {
                                setTollFreeNotificationType(true);
                            } else {
                                setTollFreeNotificationType(false);
                            }
                        } else {
                            activitiesList.push(element);
                            checkAllAssignedActivity.push(element.assigned);
                            let resulantCheckAllAssignedActivity = checkAllAssignedActivity.every((assignedActivity) => assignedActivity === true);
                            if (resulantCheckAllAssignedActivity) {
                                setNotificationType(true);
                            } else {
                                setNotificationType(false);
                            }
                        }
                    });
                    setEnabledActivityNotifications(activitiesList);
                    setEnabledTollFreeNotifications(tollFreeList);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, appContext.localStorageInfo.user?.id]);

    const fetchUserDetails = useCallback(() => {
        SettingsApi.getUserDetails(appContext.selectedClient?.userId)
            .then((result) => {
                if (isMounted.current) {
                    setUserDetails(result);
                    setTextMessageNotification(result.sendTextNotifications);
                }
            })
            .catch((error) => { handleError(error); })
            .finally(() => {
                if (isMounted.current) {
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, appContext.selectedClient?.userId]);

    useEffect(() => {
        fetchSettings();
        fetchUserDetails();
    }, [fetchSettings, fetchUserDetails]);


    const handleSearchChange = (e) => {
        setNewSearch(e.target.value);
        setOpenedActivities(true);
        setOpenedTollFree(true);
    };

    const mainTabNotifications = () => {
        if (openedActivities && openedTollFree) {
            setOpenedActivities(false);
            setOpenedTollFree(false);
        } else {
            setOpenedActivities(true);
            setOpenedTollFree(true);
        }
    }

    const handleClear = () => {
        setNotificationType(false);
        setTollFreeNotificationType(false);
        let newCheckedNotificationClearSettings = [...enabledActivityNotifications];
        newCheckedNotificationClearSettings.map((enabledSettings) => {
            enabledSettings.modifiedAssigned = false;
            return true;
        })
        setEnabledActivityNotifications(newCheckedNotificationClearSettings);
        let newCheckedNotificationTollFreeClearSettings = [...enabledTollFreeNotifications];
        newCheckedNotificationTollFreeClearSettings.map((enabledTollFreeSettings) => {
            enabledTollFreeSettings.modifiedAssigned = false;
            return true;
        })
        setEnabledTollFreeNotifications(newCheckedNotificationTollFreeClearSettings);
    }

    const handleCheckAllActivities = (value) => {
        setNotificationType(value);
        let newCheckedNotificationSettings = [...enabledActivityNotifications];
        newCheckedNotificationSettings.forEach((enabledSettings) => {
            enabledSettings.modifiedAssigned = value;
        })
        setEnabledActivityNotifications(newCheckedNotificationSettings);
        setDisabledButton(false);
    }

    const handleActivityChangeNotification = (settings: SettingsNotificationDto, index: number) => {
        let buttonDisable = true;
        const newCheckedSettings = [...enabledActivityNotifications];
        newCheckedSettings[index] = settings;
        setEnabledActivityNotifications(newCheckedSettings);
        newCheckedSettings.forEach((settingsElement) => {
            if ((settingsElement.modifiedAssigned !== settingsElement.assigned)) {
                buttonDisable = false;
            }
        });
        setDisabledButton(buttonDisable);
    }


    const filteredActivityNotification = !search
        ? enabledActivityNotifications
        : enabledActivityNotifications.filter((activityNotification) =>
            activityNotification.activityKindName.toLowerCase().includes(search.toLowerCase())
        );

    const filteredTollFreeNotification = !search
        ? enabledTollFreeNotifications
        : enabledTollFreeNotifications.filter((tollFreeNotification) =>
            tollFreeNotification.activityKindName.toLowerCase().includes(search.toLowerCase())
        );

    const handleCheckAllTollFree = (value) => {
        setTollFreeNotificationType(value);
        let newCheckedNotificationTollFreeSettings = [...enabledTollFreeNotifications];
        newCheckedNotificationTollFreeSettings.forEach((enabledTollFreeSettings) => {
            enabledTollFreeSettings.modifiedAssigned = value;
        })
        setEnabledTollFreeNotifications(newCheckedNotificationTollFreeSettings);
        setDisabledButton(false);
    }

    const handleTollFreeChangeNotification = (settings: SettingsNotificationDto, index: number) => {
        let submitButtonDisable = true;
        const newCheckedTollFreeSettings = [...enabledTollFreeNotifications];
        newCheckedTollFreeSettings[index] = settings;
        setEnabledTollFreeNotifications(newCheckedTollFreeSettings);
        newCheckedTollFreeSettings.forEach((settingsElements) => {
            if ((settingsElements.modifiedAssigned !== settingsElements.assigned)) {
                submitButtonDisable = false;
            }
        });
        setDisabledButton(submitButtonDisable);
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setShowLoadingIndicator(true);
        updateNotification(appContext, textMessageNotification)

        const requestEnableNotification: RequestSettingsNoficationsDto[] = [];
        enabledActivityNotifications.forEach(activity => {
            const notificationData = {
                activityKindId: activity.activityKindId,
                assigned: activity.modifiedAssigned
            }
            requestEnableNotification.push(notificationData);
        });
        enabledTollFreeNotifications.forEach(tollFree => {
            const notificationData = {
                activityKindId: tollFree.activityKindId,
                assigned: tollFree.modifiedAssigned
            }
            requestEnableNotification.push(notificationData);
        });
        if (requestEnableNotification.length > 0) {
            SettingsApi.put(appContext.localStorageInfo.user?.id, requestEnableNotification)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "settings.create.successNotificationMessage" })
                    );
                    fetchSettings();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
        if (userDetails) {
            userDetails.sendTextNotifications = textMessageNotification;
            SettingsApi.putUserDetails(appContext.selectedClient?.userId, userDetails)
                .then(() => {
                    if (isMounted.current) {
                        showInfoNotification(
                            intl.formatMessage({ id: "settings.create.successNotificationMessageForUser" })
                        );
                    }
                })
                .catch((error) => { handleError(error); })
                .finally(() => {
                    if (isMounted.current) {
                    }
                });
        }
    }

    return (
        <>

            {showLoadingIndicator ? (
                <>
                    <LoadingIndicator white />
                </>
            ) : (
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <Card className="mb-0 border-radius">
                        <CardHeader className="p-3 border-radius">
                            <div className="d-flex">
                                <div className={classnames("flex-fill d-flex align-items-center p-3")} >
                                    {intl.formatMessage({ id: "notification.table.title" }) && <h3 className={classnames("mb-0")}>{intl.formatMessage({ id: "notification.table.title" })}</h3>}
                                </div>
                                <div className="d-flex align-items-center ml-2 mr-3">
                                    <Input
                                        bsSize="sm"
                                        type="text"
                                        value={search}
                                        placeholder={"Search"}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <hr className="mt-0"></hr>
                            <div className="col-lg-12 text-border">
                                <Row className="align-items-center">
                                    <Col>
                                        <i className="fas fa-solid fa-comment comment-size" />
                                        <div className="ml-text">
                                            <div className="table-header"><FormattedMessage id="enableNotification.textmessage" /></div>
                                            <div className="table-subHeader"><FormattedMessage id="enableNotification.subTextHeader" /></div>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <ToggleCheckbox
                                            checked={textMessageNotification}
                                            size="large"
                                            handleInputChange={(value: boolean) => {
                                                setTextMessageNotification(value);
                                                setDisabledButton(userDetails?.sendTextNotifications === value)
                                            }
                                            }
                                            offstyle="btn-text-switc-off"
                                            onstyle="btn-success"
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-5">
                                <div className="table-header"><FormattedMessage id="enableNotification.header" /></div>
                                <div className="table-subHeader"><FormattedMessage id="enableNotification.subHeader" /></div>
                                <div className="nav-link-table">
                                    <Nav className="d-flex align-items-center">
                                        <NavLink href="#" id="mainToggler" onClick={mainTabNotifications} ><FormattedMessage id={openedActivities && openedTollFree ? "enableNotification.collapseAll" : "enableNotification.expandAll"} /></NavLink>
                                        <big className="text-black">|</big>
                                        <NavLink href="#" onClick={handleClear}><FormattedMessage id="enableNotification.clearAll" /></NavLink>
                                    </Nav>
                                </div>
                                <div className="mt-3">
                                    <Navbar color="faded" light className="p-0">
                                        <NavbarToggler className="mb-2" onClick={() => setOpenedActivities(!openedActivities)}>
                                            <div className="d-flex align-items-center">
                                                {openedActivities ? <i className="fa fa-angle-down sm mr-1"></i> : <i className="fa fa-angle-right sm mr-1"></i>}
                                                <small className="text-navigate fs-1"><FormattedMessage id="enableNotification.activies" /></small>
                                            </div>
                                        </NavbarToggler>
                                        <Collapse isOpen={openedActivities} navbar>
                                            <ActivitiesSettings notificationType={notificationType} handleCheckAllActivities={handleCheckAllActivities} filteredActivityNotification={filteredActivityNotification} handleChangeNotifications={handleActivityChangeNotification} />
                                        </Collapse>
                                    </Navbar>
                                </div>
                                <div>
                                    <Navbar color="faded" light className="p-0">
                                        <NavbarToggler className="mb-2" onClick={() => setOpenedTollFree(!openedTollFree)}>
                                            <div className="d-flex align-items-center">
                                                {openedTollFree ? <i className="fa fa-angle-down sm mr-1"></i> : <i className="fa fa-angle-right sm mr-1"></i>}
                                                <small className="text-navigate fs-1"><FormattedMessage id="enableNotification.tollfree" /></small>
                                            </div>
                                        </NavbarToggler>
                                        <Collapse isOpen={openedTollFree} navbar>
                                            <TollFreeSettings tollFreeNotificationType={tollFreeNotificationType} handleCheckAllTollFree={handleCheckAllTollFree} filteredTollFreeNotification={filteredTollFreeNotification} handleChangeTollFreeNotifications={handleTollFreeChangeNotification} />
                                        </Collapse>
                                    </Navbar>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-end">
                                <Button color="primary" type="submit" disabled={showLoadingIndicator || disabledButton}>
                                    {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                    <FormattedMessage id="table.settingsModal.save" />
                                </Button>
                            </div>
                        </CardHeader>
                    </Card>
                </Form>
            )
            }
        </>
    )
}

function updateNotification(appContext: AppContext, textMessageNotification) {
    if (appContext.localStorageInfo.user)
        appContext.localStorageInfo.user.sendTextNotifications = textMessageNotification
}

