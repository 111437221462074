import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { HttpClient } from "services/httpClient/HttpClient";
import { AcvitityKindDto } from "./types/client/AcvitityKindDto";
import { ClientDto } from "./types/client/ClientDto";

export const ClientApi = {
  info: (): Promise<ClientDto> => {
    return HttpClient.get<ClientDto>(`clients/${getClientId()}`);
  },
  getCnamFeesByKind: (): Promise<AcvitityKindDto> => {
    return HttpClient.get<AcvitityKindDto>(`clients/${getClientId()}/activityKinds/43`);
  },
  getFraudFeesByKind: (): Promise<AcvitityKindDto> => {
    return HttpClient.get<AcvitityKindDto>(`clients/${getClientId()}/activityKinds/47`);
  }
};

const getClientId = () => {
  const appContext = AppContextAccessor.getAppContext();

  return appContext.selectedClient
    ? appContext.selectedClient.clientId
    : "";
};
