import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { HomeContext } from "components/home/Home";
import React, { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Container, Row } from "reactstrap";
import { DisasterRecoveryApi } from "services/apis/DisasterRecoveryApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";
import { DisasterRecoveryStatusType } from "services/disasterRecovery/DisasterRecoveryStatusType";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import DisasterRecoveryUpsertModal from "./DisasterRecoveryUpsertModal";
import DisasterRecoveryDeleteModal from "./DisasterRecoveryDeleteModal";
import ActionDropdown from "components/framework/actionDropdown/ActionDropdown";
import ActionDropdownItem from "components/framework/actionDropdown/ActionDropdownItem";

type Props = {
  disasterRecovery: DisasterRecoveryDto;
};

export default function DisasterRecoveryCardActionsCell(props: Props) {
  const [showModifyModal, setShowModifyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [actionsOpen, setActionsOpen] = useState(false);
  const homeContext = useContext(HomeContext);
  const { disasterRecovery } = props;
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const isMounted = useIsMounted();
  const intl = useIntl();

  const handleInitiate = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    DisasterRecoveryApi.initiate(disasterRecovery.id)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "disastorRecovery.initiate.initiateNotification" }));
        if (isMounted.current) {
          homeContext.disasterRecoveryActionCallback();
          homeContext.activityActionCallback();
          homeContext.confirmationActionCallback(true);
        }
      })
      .catch(error => handleFieldOrApiErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  const handleRevert = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    DisasterRecoveryApi.revert(disasterRecovery.id)
      .then(() => {
        showInfoNotification(intl.formatMessage({ id: "disastorRecovery.revert.revertNotification" }));
        if (isMounted.current) {
          homeContext.disasterRecoveryActionCallback();
          homeContext.activityActionCallback();
        }
      })
      .catch(error => handleFieldOrApiErrors(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };


  return (
    <Container className="nopadding">
      <Row className="justify-content-center flex-no-wrap">
        <Col className="nopadding">
          {disasterRecovery.displayStatusType === DisasterRecoveryStatusType.initiated &&
            <Button
              className="btn-semi-danger"
              size="sm"
              type="button"
              onClick={handleRevert}
              disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="disastorRecovery.revert" />
            </Button>
          }
          {disasterRecovery.displayStatusType === DisasterRecoveryStatusType.active &&
            <Button
              color="success"
              size="sm"
              type="button"
              onClick={handleInitiate}
              disabled={showLoadingIndicator}>
              {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
              <FormattedMessage id="disastorRecovery.initiate" />
            </Button>
          }
          {disasterRecovery.displayStatusType === DisasterRecoveryStatusType.disabled &&
            <Button
              color="light"
              size="sm"
              disabled={true}
              type="button">
              <FormattedMessage id="disastorRecovery.initiate" />
            </Button>
          } {disasterRecovery.displayStatusType === DisasterRecoveryStatusType.pending &&
            <Button
              className="btn-semi-warning"
              size="sm"
              disabled={true}
              type="button">
              <FormattedMessage id="disastorRecovery.pending" />
            </Button>
          }</Col>
        <Col className="nopadding text-align-end">
          <ActionDropdown actionsOpen={actionsOpen} actionsToggle={() => setActionsOpen(prevState => !prevState)}>
            <ActionDropdownItem
              disabled={disasterRecovery.displayStatusType === DisasterRecoveryStatusType.pending}
              handleAction={() => setShowModifyModal(true)}
              iconClassName={disasterRecovery.displayStatusType === DisasterRecoveryStatusType.pending ? "fa fa-edit dr-disabled" : "fa fa-edit text-primary"}
              labelId="disasterRecoveries.table.actions.modify"
            />
            <ActionDropdownItem
              disabled={disasterRecovery.displayStatusType === DisasterRecoveryStatusType.pending}
              handleAction={() => setShowDeleteModal(true)}
              iconClassName={disasterRecovery.displayStatusType === DisasterRecoveryStatusType.pending ? "fa fa-trash-alt dr-disabled" : "fa fa-trash-alt text-primary"}
              labelId="disasterRecoveries.table.actions.delete"
            />
          </ActionDropdown>
          {showModifyModal && (
            <DisasterRecoveryUpsertModal
              disasterRecovery={props.disasterRecovery}
              closeModal={() => setShowModifyModal(false)}
            />
          )}
          {showDeleteModal && (
            <DisasterRecoveryDeleteModal
              id={disasterRecovery.id.toString()}
              name={props.disasterRecovery.name}
              closeModal={() => setShowDeleteModal(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
