import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

type Props = {
  actionsOpen: boolean;
  actionsToggle: () => void;
  children?: React.ReactNode;
  className?: string;
};

export default function ActionDropdown(props: Props) {
  return (
    <Dropdown
      nav
      isOpen={props.actionsOpen}
      toggle={props.actionsToggle}
      className={props.className ? `${props.className} text-right mr-3` : "col-4 text-right mr-3"}>
      <DropdownToggle className="nav-link pr-0 cursor-pointer" color="" tag="a">
        <i className="fa fa-ellipsis-v"></i>
      </DropdownToggle>
      <DropdownMenu right>{props.children}</DropdownMenu>
    </Dropdown>
  );
}
