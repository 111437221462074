import Chart from 'chart.js';
import DropdownCheckboxFormInput from 'components/framework/forms/DropdownCheckboxFormInput';
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { NumberStatisticsApi } from 'services/apis/NumberStatisticsApi';
import { useIsMounted } from 'services/customHooks/useIsMounted';
import { handleError } from 'services/util/ApiUtil';
import { AppContext } from 'services/appContext/AppContext';
import { ActivityApi } from "services/apis/ActivityApi";
import { getThirtyDaysPriorDate } from 'services/util/DateUtil';
import { TollFreeNumbersApi } from 'services/apis/TollFreeNumbersApi';

export default function NumberStatisticsChart() {
    const chartRef = useRef<any>();
    const intl = useIntl();
    const [, setShowCNAMLoadingIndicator] = useState(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [cnamCount, setCnamCount] = useState<number>(0);
    const [doNotOrginateCount, setDoNotOrginateCount] = useState<number>(0);
    const [unassignedCount, setUnassignedCount] = useState<number>(0);
    const [withOutCnamCount, setWithOutCnamCount] = useState<number>(0);
    const [withOutDoNotOrginateCount, setWithOutDoNotOrginateCount] = useState<number>(0);
    const [withOutUnassignedCount, setWithOutUnassignedCount] = useState<number>(0);
    const isMounted = useIsMounted();
    const [fraudCount, setFraudCount] = useState<number>(0);
    const [withOutFraudCount, setWithOutFraudCount] = useState<number>(0);
    const [enabledStatusCount, setEnabledStatusCount] = useState<number>(0);
    const [otherStatusCount, setOtherStausCount] = useState<number>(0);
    const [withRealBrand, setWithRealBrand] = useState<number>(0);
    const [withoutRealBrand, setWithoutRealBrand] = useState<number>(0);
    const { appContext } = useContext(AppContext);
    const [chartInstance, setChartInstance] = useState<any>();
    const [fraudTextApiLimitExceeded, setFraudTextApiLimitExceeded] = useState(false);
    const [tollFreeNumbers, setTollFreeNumbers] = useState<Array<string>>([]);

    const numberStatisticsOption = [{
        key: 'selectAll',
        value: 'Select All', status: false
    },
    { key: 'totalTfnCount', value: intl.formatMessage({ id: "numberstatistic.tn" }), status: true },
    { key: 'tfnsWithDNO', value: intl.formatMessage({ id: "numberstatistic.dno" }), status: true },
    { key: 'tfnsWithCNAM', value: intl.formatMessage({ id: "numberstatistic.cnam" }), status: true },
    { key: 'tfnsWithUnassignedTemplate', value: intl.formatMessage({ id: "numberstatistic.unassigned" }), status: true },
    { key: 'tfnsWithFraudScore', value: intl.formatMessage({ id: "numberstatistic.fraud" }), status: false },
    { key: 'tfnsWithTextStatus', value: intl.formatMessage({ id: "numberstatistic.textStatus" }), status: false },
    { key: 'tfnsWithRealBrand', value: intl.formatMessage({ id: "numberstatistic.realBrand" }), status: true }]

    const defaultNumberStatisticsOption = [{
        key: 'selectAll',
        value: 'Select All', status: true
    },
    { key: 'totalTfnCount', value: intl.formatMessage({ id: "numberstatistic.tn" }), status: true },
    { key: 'tfnsWithDNO', value: intl.formatMessage({ id: "numberstatistic.dno" }), status: true },
    { key: 'tfnsWithCNAM', value: intl.formatMessage({ id: "numberstatistic.cnam" }), status: true },
    { key: 'tfnsWithUnassignedTemplate', value: intl.formatMessage({ id: "numberstatistic.unassigned" }), status: true },
    { key: 'tfnsWithRealBrand', value: intl.formatMessage({ id: "numberstatistic.realBrand" }), status: true }]

    const handleFraudDataFilter = useCallback((data: any) => {
        setFraudCount(data.filter(x => x.score && !isNaN(x.score) && x.score > 0).length);
        setWithOutFraudCount(data.filter(x => x.score && (isNaN(x.score) || x.score === 0)).length);
    }, []);

    const getTollFreeFraudData = useCallback((tollFreeNumberList: any) => {
        if (tollFreeNumberList && tollFreeNumberList.length > 0) {
            TollFreeNumbersApi.getFraudQueryTollFreeNumber({ tollFreeNumbers: tollFreeNumberList })
                .then((result) => {
                    if (isMounted.current && result && result.tollFreeFrauds.length > 0) {
                        handleFraudDataFilter(result.tollFreeFrauds);
                    }
                })
                .catch((error) => {
                    handleError(error);
                })
        }
    }, [isMounted, handleFraudDataFilter]);

    const handleTextStatusFilter = useCallback((data: any) => {
        setEnabledStatusCount(data.filter(x => x.statusType && x.statusType === 'Enabled').length);
        setOtherStausCount(data.filter(x => x.statusType && x.statusType !== 'Enabled').length);
    }, []);


    const getTollFreeTextEnabledData = useCallback((data: any) => {
        if (data && data.length > 0) {
            TollFreeNumbersApi.queryTollFreeTexts({ tollFreeNumbers: data })
                .then((result) => {
                    handleTextStatusFilter(result.tollFreeTexts)
                })
                .catch((error) => {
                    if (error && error.message && !error.message.includes("Following"))
                        handleError(error);
                })
        }
    }, [handleTextStatusFilter]);

    const fetchTollFreeNumbers = useCallback((totalCount: number) => {
        if (totalCount > 0) {
            setShowCNAMLoadingIndicator(true)
            TollFreeNumbersApi.get(null, true, 1, 500)
                .then((result) => {
                    if (result && result.length > 0) {
                        const tollFrees = result.map(x => x.number);
                        setTollFreeNumbers(tollFrees);
                        //getTollFreeTextEnabledData(tollFrees);
                        //getTollFreeFraudData(tollFrees);
                    }
                }
                )
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowCNAMLoadingIndicator(false);
                    }
                });
        }
    }, [isMounted]);

    const [numberStatistics, setNumberStatistics] = useState<Array<any>>(numberStatisticsOption);
    const fetchTotalCount = useCallback((onlyCount?: boolean) => {
        setShowCNAMLoadingIndicator(true)
        NumberStatisticsApi.getCount()
            .then((result) => {
                console.log('totalCount', result)
                setTotalCount(result)
                if (result <= 500) {
                    setFraudTextApiLimitExceeded(false);
                    if (!onlyCount)
                        fetchTollFreeNumbers(result);
                } else {
                    setFraudTextApiLimitExceeded(true);
                }

            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
    }, [isMounted, fetchTollFreeNumbers]);

    const fetchCNAMStatistics = useCallback(() => {
        setShowCNAMLoadingIndicator(true)
        NumberStatisticsApi.getCnam(true)
            .then((result) => {
                console.log('cnamCount', result)
                setCnamCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
        NumberStatisticsApi.getCnam(false)
            .then((result) => {
                console.log('cnamWithOutCount', result)
                setWithOutCnamCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchDoNotOrginateStatistics = useCallback(() => {
        setShowCNAMLoadingIndicator(true)
        NumberStatisticsApi.getdoNotOrginate(true)
            .then((result) => {
                console.log('doNotOrginateCount', result)
                setDoNotOrginateCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
        NumberStatisticsApi.getdoNotOrginate(false)
            .then((result) => {
                console.log('withOutdoNotOrginateCount', result)
                setWithOutDoNotOrginateCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchUnassignedStatistics = useCallback(() => {
        setShowCNAMLoadingIndicator(true)
        NumberStatisticsApi.getUnassigned(false)
            .then((result) => {
                console.log('UnassignedCount', result)
                setUnassignedCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
        NumberStatisticsApi.getUnassigned(true)
            .then((result) => {
                console.log('withoutUnassignedCount', result)
                setWithOutUnassignedCount(result)
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchRealBrandStatistics = useCallback(() => {
        setShowCNAMLoadingIndicator(true)
        ActivityApi.getRealBrand(getThirtyDaysPriorDate(), 48)
            .then((result) => {
                let count = 0;
                result.forEach(x => {
                    if (x.tollFreeNumbers.length > 0)
                        count = count + x.tollFreeNumbers.length
                });
                setWithRealBrand(count);
                setWithoutRealBrand(totalCount - count);
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCNAMLoadingIndicator(false);
                }
            });
    }, [isMounted, totalCount]);

    useEffect(() => {
        if (fraudTextApiLimitExceeded) {
            setNumberStatistics(defaultNumberStatisticsOption)
        } else {
            setNumberStatistics(numberStatisticsOption)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fraudTextApiLimitExceeded])
    
    useEffect(() => {
        fetchTotalCount();
        fetchCNAMStatistics();
        fetchDoNotOrginateStatistics();
        fetchUnassignedStatistics();
        fetchRealBrandStatistics();
        //fetchTollFreeNumbers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.selectedClient?.clientId, fetchTotalCount, fetchCNAMStatistics, fetchDoNotOrginateStatistics, fetchUnassignedStatistics, fetchRealBrandStatistics])

    const handleSelectedApiCall = (option: string) => {
        switch(option) {
            case 'selectAll':
                fetchTotalCount();
                fetchCNAMStatistics();
                fetchDoNotOrginateStatistics();
                fetchUnassignedStatistics();
                fetchRealBrandStatistics();
                break;
            case 'totalTfnCount':
                fetchTotalCount(true);
                break;
            case 'tfnsWithDNO':
                fetchDoNotOrginateStatistics();
                break;
            case 'tfnsWithCNAM':
                fetchCNAMStatistics();
                break;
            case 'tfnsWithUnassignedTemplate':
                fetchUnassignedStatistics();
                break;
            case 'tfnsWithFraudScore':
                getTollFreeFraudData(tollFreeNumbers)
                break;
            case 'tfnsWithTextStatus':
                getTollFreeTextEnabledData(tollFreeNumbers)
                break;
            case 'tfnsWithRealBrand':
                fetchRealBrandStatistics();
                break;                                    
          }
    }

    const handleSelectedStatisticsChanges = (key: string, checked: boolean) => {
        if (key && checked)
            handleSelectedApiCall(key)
        let numberStatisticsArray = [...numberStatistics]
        const selectedIndex = numberStatisticsArray.findIndex(x => x.key === key)
        numberStatisticsArray[selectedIndex].status = checked;
        if (key === 'selectAll' && numberStatisticsArray[selectedIndex].status === true) {
            numberStatisticsArray.map(option => option.status = true)
        } else if (key === 'selectAll' && numberStatisticsArray[selectedIndex].status === false) {
            numberStatisticsArray.map(option => option.status = false)
        }
        else {
            if (numberStatisticsArray.filter(option => option.value !== 'Select All' && option.status === true).length === numberStatisticsArray.length - 1) {
                numberStatisticsArray[0].status = true
            } else if (numberStatisticsArray[0].status === true)
                numberStatisticsArray[0].status = false
        }
        setNumberStatistics([...numberStatisticsArray])
    }


    const drawChart = useCallback((labels, chartInstance, statisticsWithOutResult, statisticsWithResult, totalCount) => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext("2d");
            if (chartInstance != null) chartInstance.destroy();
            const check = new Chart(ctx, getChartOptions(labels, statisticsWithResult, statisticsWithOutResult, totalCount));
            setChartInstance(check);
        }
    }, []);

    useEffect(() => {
        const statisticsWithResult: any = [];
        const statisticsWithOutResult: any = [];
        const labels: any = [];
        if (numberStatistics.some(x => x.key === 'selectAll' && x.status === true)) {
            statisticsWithResult.push(totalCount, doNotOrginateCount, cnamCount, unassignedCount, fraudCount, enabledStatusCount, withRealBrand);
            statisticsWithOutResult.push(0, withOutDoNotOrginateCount, withOutCnamCount, withOutUnassignedCount, withOutFraudCount, otherStatusCount, withoutRealBrand);
            if (fraudTextApiLimitExceeded) {
                labels.push(intl.formatMessage({ id: "numberstatistic.tn" }), intl.formatMessage({ id: "numberstatistic.dno" }), intl.formatMessage({ id: "numberstatistic.cnam" }), intl.formatMessage({ id: "numberstatistic.unassigned" }),  intl.formatMessage({ id: "numberstatistic.realBrand" }));
            } else {
                labels.push(intl.formatMessage({ id: "numberstatistic.tn" }), intl.formatMessage({ id: "numberstatistic.dno" }), intl.formatMessage({ id: "numberstatistic.cnam" }), intl.formatMessage({ id: "numberstatistic.unassigned" }), intl.formatMessage({ id: "numberstatistic.fraud" }), intl.formatMessage({ id: "numberstatistic.textStatus" }), intl.formatMessage({ id: "numberstatistic.realBrand" }));
            }
            drawChart(labels, chartInstance, statisticsWithOutResult, statisticsWithResult, totalCount);
            return;
        }
        if (numberStatistics.some(x => x.key === 'totalTfnCount' && x.status === true)) {
            statisticsWithResult.push(totalCount);
            statisticsWithOutResult.push(0);
            labels.push(intl.formatMessage({ id: "numberstatistic.tn" }));
        }
        if (numberStatistics.some(x => x.key === 'tfnsWithDNO' && x.status === true)) {
            statisticsWithResult.push(doNotOrginateCount);
            statisticsWithOutResult.push(withOutDoNotOrginateCount);
            labels.push(intl.formatMessage({ id: "numberstatistic.dno" }));
        }
        if (numberStatistics.some(x => x.key === 'tfnsWithCNAM' && x.status === true)) {
            statisticsWithResult.push(cnamCount);
            statisticsWithOutResult.push(withOutCnamCount)
            labels.push(intl.formatMessage({ id: "numberstatistic.cnam" }));
        }
        if (numberStatistics.some(x => x.key === 'tfnsWithUnassignedTemplate' && x.status === true)) {
            statisticsWithResult.push(unassignedCount);
            statisticsWithOutResult.push(withOutUnassignedCount);
            labels.push(intl.formatMessage({ id: "numberstatistic.unassigned" }));
        }
        if (!fraudTextApiLimitExceeded && numberStatistics.some(x => x.key === 'tfnsWithFraudScore' && x.status === true)) {
            statisticsWithResult.push(fraudCount);
            statisticsWithOutResult.push(withOutFraudCount);
            labels.push(intl.formatMessage({ id: "numberstatistic.fraud" }));
        }
        if (!fraudTextApiLimitExceeded && numberStatistics.some(x => x.key === 'tfnsWithTextStatus' && x.status === true)) {
            statisticsWithResult.push(enabledStatusCount);
            statisticsWithOutResult.push(otherStatusCount);
            labels.push(intl.formatMessage({ id: "numberstatistic.textStatus" }));
        }
        if (numberStatistics.some(x => x.key === 'tfnsWithRealBrand' && x.status === true)) {
            statisticsWithResult.push(withRealBrand);
            statisticsWithOutResult.push(withoutRealBrand);
            labels.push(intl.formatMessage({ id: "numberstatistic.realBrand" }));
        }
        if (fraudTextApiLimitExceeded) {
            statisticsWithResult.push(0);
            statisticsWithOutResult.push(0);
            statisticsWithResult.push(0);
            statisticsWithOutResult.push(0);
        }
        drawChart(labels, chartInstance, statisticsWithOutResult, statisticsWithResult, totalCount)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, fraudTextApiLimitExceeded, drawChart, numberStatistics, totalCount, cnamCount, withOutCnamCount, doNotOrginateCount, withOutDoNotOrginateCount, unassignedCount, withOutUnassignedCount, fraudCount, withOutFraudCount, enabledStatusCount, otherStatusCount, withRealBrand, withoutRealBrand]);

    return (
        <Container className="nopadding">
            <Row className="justify-content-center">
                <Col className="nopadding">
                    <Card className=" bg-secondary border-0 mb-0 p-2">
                        <Row className="align-items-center flex-fill border-bottom-1 p-2 mb-1">
                            <Col>
                                {<h3 className="mb-0"><FormattedMessage id="numberStatistics.table.title" /></h3>}
                            </Col>
                            <Col>
                                {(numberStatistics.length > 0) ? (
                                    <DropdownCheckboxFormInput
                                        className="col-lg-12"
                                        handleInputChange={(key: string, checked: boolean) =>
                                            handleSelectedStatisticsChanges(key, checked)
                                        }
                                        options={numberStatistics}
                                    />
                                ) : ''}
                            </Col>
                        </Row>
                        {fraudTextApiLimitExceeded && <Row className="pl-4 border-bottom-1 p-2 mb-1 text-danger">
                            <FormattedMessage id="numberStatistics.table.fraudTextLimitExceeded" />
                        </Row>}
                        <CardBody className="p-0">
                            {/* {statisticsResult && (<Bar data={statisticsResult} options={statisticsOptions} />)} */}

                            <div className="chart">
                                <canvas ref={chartRef}></canvas>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const getChartOptions = (
    labels: string[],
    statisticsResult: any,
    statisticsWithOutResult: any,
    totalCount: any
): Chart.ChartConfiguration => {
    return {
        type: "horizontalBar",
        data: {
            labels: labels,
            datasets: [
                {
                    data: statisticsResult,
                    backgroundColor: "rgb(255, 99, 132, 0.5)",
                    borderColor: 'rgb(255, 99, 132, 1)',
                    borderWidth: 2,
                    label: "With"
                },
                {
                    data: statisticsWithOutResult,
                    backgroundColor: "rgb(54, 162, 235, 0.5)",
                    borderColor: 'rgb(54, 162, 235)',
                    borderWidth: 2,
                    label: "Without"
                }
            ]
        },
        options: {
            tooltips: {
                mode: "label",
                position: "nearest",
                enabled: true,
                titleAlign: "center",
            },
            hover: {
                animationDuration: 0,
                mode: undefined,
            },
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            fontFamily: "Open Sans, sans-serif",
                            fontSize: 13,
                            max: totalCount ? totalCount + 40 : 200
                        },
                        scaleLabel: {
                            display: false
                        },
                        gridLines: {},
                        stacked: true
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                            color: "#fff",
                            zeroLineColor: "#fff",
                            zeroLineWidth: 0
                        },
                        ticks: {
                            fontFamily: "Open Sans, sans-serif",
                            fontSize: 13
                        },
                        stacked: true
                    }
                ]
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            animation: {
                onComplete: function (e) {
                    const chartInstance = e.chart;
                    const ctx = chartInstance.ctx;
                    ctx.font = "11px Open Sans, sans-serif";
                    ctx.fillStyle = "black";

                    Chart.helpers.each(
                        e.chart.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            Chart.helpers.each(
                                meta.data.forEach(function (bar, index) {
                                    ctx.fillStyle = "black";
                                    if (dataset.data[index] !== 0) {
                                        if (i === 0) {
                                            ctx.textAlign = "left";
                                            ctx.fillText(dataset.data[index], bar._model.x > 151 ? 158 : bar._model.x - 5, bar._model.y + 4);
                                        } else {
                                            ctx.textAlign = "right";
                                            ctx.fillText(dataset.data[index], bar._model.x - 9, bar._model.y + 4);
                                        }
                                    }
                                }),
                                e.chart
                            );
                        }),
                        e.chart
                    );

                    // // draw total count
                    // e.chart.data.datasets[0].data.forEach(function (data, index) {
                    //     var total = data + e.chart.data.datasets[1].data[index];
                    //     var meta = chartInstance.controller.getDatasetMeta(1);

                    //     var posX = meta.data[index]._model.x;
                    //     var posY = meta.data[index]._model.y;

                    //     ctx.fillStyle = "black";
                    //     if (total !== 0) {
                    //         ctx.fillText(total, posX + 4, posY + 4);
                    //     }
                    // }, e.chart);
                }
            },
        }
    };
};
