import { HttpClient } from "services/httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { RequestTollFreeNumber } from "./types/toll-free-numbers/RequestTollFreeNumber";
import { RequestTollFreeQuery } from "./types/toll-free-numbers/RequestTollFreeQuery";
import { RequestTollFreeReserve } from "./types/toll-free-numbers/RequestTollFreeReserve";
import { RequestTollFreeTextStatusQuery } from "./types/toll-free-numbers/RequestTollFreeTextStatusQuery";
import { TollFreeHistoryDto } from "./types/toll-free-numbers/TollFreeHistoryDto";
import { TollFreeNumbersDto } from "./types/toll-free-numbers/TollFreeNumbersDto";
import { TollFreeProvisionDto } from "./types/toll-free-numbers/TollFreeProvisionDto";
import { TollFreeQueryDto } from "./types/toll-free-numbers/TollFreeQueryDto";
// import { TollFreeTextDto } from "./types/toll-free-numbers/TollFreeTextDto";
import { TollFreeTextQueryDto } from "./types/toll-free-numbers/TollFreeTextQueryDto";
import { TollFreeSearchNumberDto } from "./types/toll-free-numbers/TollFreeSearchNumberDto";
import { TollFreeInquireDto } from "./types/toll-free-numbers/TollFreeInquireDto";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";


export const TollFreeNumbersApi = {
  get: (numbers: (number | null), includeTemplate: boolean, pageNumber: number, pageSize: number): Promise<TollFreeNumbersDto[]> => {
    if (numbers)
      return HttpClient.get<TollFreeNumbersDto[]>(`tollFrees?numbers=${numbers}&includeTemplate=${includeTemplate}&pageNumber=${pageNumber}&pageSize=${pageSize}`, true, true);
    else
      return HttpClient.get<TollFreeNumbersDto[]>(`tollFrees?includeTemplate=${includeTemplate}&pageNumber=${pageNumber}&pageSize=${pageSize}`, true, true);
  },
  add: (tollFreeNumber: Array<RequestTollFreeNumber>): Promise<ApiError> => {
    return HttpClient.post<ApiError>("tollFrees", tollFreeNumber, true, true);
  },
  edit: (updateTollFreeNumber: Array<RequestTollFreeNumber>): Promise<ApiError> => {
    return HttpClient.put<ApiError>("tollFrees", updateTollFreeNumber, true, true);
  },

  getTollFreeNumber: (tollFreeNumber: string): Promise<TollFreeNumbersDto> => {
    return HttpClient.get<TollFreeNumbersDto>(`tollFrees/${tollFreeNumber}`, true, true);

  },
  getHistory: (number: string): Promise<TollFreeHistoryDto[]> => {
    return HttpClient.get<TollFreeHistoryDto[]>(
      `tollFrees/${number}/history`,
      true,
      true
    );
  },

  queryTollFrees: (query: RequestTollFreeQuery): Promise<TollFreeQueryDto> => {
    return HttpClient.post<TollFreeQueryDto>(`tollFrees/bulkQuery`, query, true, true);
  },

  queryTollFreeTexts: (query: RequestTollFreeTextStatusQuery): Promise<TollFreeTextQueryDto> => {
    return HttpClient.post<TollFreeTextQueryDto>(`tollFrees/textQuery`, query, true, true, getClientId());
  },

  reserve: (reserve: RequestTollFreeReserve): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`tollFrees/reserve`, reserve, true, true);
  },

  provision: (clientId: number | undefined): Promise<TollFreeProvisionDto[]> => {
    return HttpClient.get<TollFreeProvisionDto[]>(`clients/${clientId}/eBondProfiles`, true, true);
  },

  searchReserve: (searchTollFreeNumbers: any): Promise<TollFreeSearchNumberDto[]> => {
    return HttpClient.post<TollFreeSearchNumberDto[]>(`tollFrees/search`, searchTollFreeNumbers, true, true);
  },

  inquire: (inquireNumbers: any): Promise<TollFreeInquireDto> => {
    return HttpClient.post<TollFreeInquireDto>(`tollFrees/inquire`, inquireNumbers, true, true);
  },

  updateRealBrand : (numbers: any): Promise<ApiError> => {
    return HttpClient.post<any>(`tollFrees/realBrand`, numbers, true, true);
  },

  getcount: (): Promise<number> => {
    return HttpClient.get<number>(`tollFrees/count`, true, true);
  },

  getCNAMTollFreeNumber: (cnamTollFreeNumbers: any): Promise<any> => {
    return HttpClient.post<any>(`tollFrees/cnamQuery`, cnamTollFreeNumbers, true, true);
  },
  getFraudQueryTollFreeNumber: (fraudQueryTollFreeNumbers: any): Promise<any> => {
    return HttpClient.post<any>(`tollFrees/fraudQuery`, fraudQueryTollFreeNumbers, true, true, getClientId());
  },
  getTextQueryTollFreeNumber: (textQueryTollFreeNumbers: any): Promise<any> => {
    return HttpClient.post<any>(`tollFrees/textQuery`, textQueryTollFreeNumbers, true, true);
  },

  bulkUpdate: (fileObjBytes: any): Promise<any> => {
    return HttpClient.post<any>(`tollFrees/bulkUpdate`, fileObjBytes, true, true);
  },

};

const getClientId = () => {
  const appContext = AppContextAccessor.getAppContext();

  return appContext.selectedClient
    ? appContext.selectedClient.clientId.toString()
    : "";
};
