import React, { useRef, useState, useEffect } from "react";
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter, useExpanded } from "react-table";
import { lowerCaseIncludes } from "services/util/StringUtil";
import {
  Card,
  CardFooter,
  CardHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Table,
  CardBody,
  Input,
} from "reactstrap";
import DefaultColumnFilter from "./DefaultColumnFilter";
import { FormattedMessage, useIntl } from "react-intl";
import ExportButtons from "./ExportButtons";
import { useUiTableSettings } from "./useUiTableSettings";
import { getTranslation } from "translations/TranslationService";
import { usePrint } from "./usePrint";
import IconWithTooltip from "components/common/IconWithTooltip";
import { useCallback } from "react";
import DisasterRecoveryUpsertModal from '../../disasterrecovery/DisasterRecoveryUpsertModal';
import classnames from "classnames";
import Download from "./Download";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";

const emptyData = [];

export default function ReactTable({
  showViewSettings = false,
  identifier = "",
  children = undefined,
  title = "",
  columns,
  data,
  hasExpandedData = false,
  showExport = false,
  exportColumns = undefined,
  hideSearch = false,
  hideHeaders = false,
  filterMapper = undefined,
  useColumnFilters = false,
  initialPageSize = 0,
  onRefresh = undefined,
  maxHeight = undefined,
  showAdd = false,
  hiddenColumns = undefined,
  headerClassName = "",
  titleClassName = "",
  showDownload = false,
  showDownloadQuery = false,
  autoResetPage = true,
  areSelectedAll = undefined,
  handleSelectAll = undefined,
  queryPageSize = 10,
  queryPageIndex = 0,
  queryCount = 0,
  handlePageChange = undefined,
  handlePageSizeChange = undefined,
  dataRefreshed = false,
}) {
  columns = columns ? columns : {}
  const isLoading = data === undefined;
  const printRef = useRef(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  let tableHooks = [useGlobalFilter, useSortBy];
  const intl = useIntl();

  const manualPagination = handlePageChange !== undefined && handlePageSizeChange !== undefined;

  console.log('reactTable', manualPagination)


  if (hasExpandedData) {
    tableHooks.push(useExpanded)
  }

  tableHooks.push(usePagination);

  if (useColumnFilters) {
    tableHooks = [useFilters, ...tableHooks];
  }



  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  );

  const resetFilters = () => {
    setAllFilters([]);
    setGlobalFilter("");
  };


  const filteredResults = useRef([]);

  const globalFilter = useCallback((rows, _accessors, value) => {
    return rows.filter((x) => {

      const filterable = filterMapper ? filterMapper(x.original) : x.original;
      const matchedByColumnAccessors = _accessors.some((accessor) => lowerCaseIncludes(value, filterable[accessor]));

      if (matchedByColumnAccessors) {
        filteredResults.current.push({ parentId: x.original.id, childIds: x?.originalSubRows?.map(r => r.id) });
      }

      // in the case when rows can be expanded(rows have subRows)
      // preserving child subRows(even if not matching) - if the parent row was matched
      var isPartOfAMatchedParentRow =
        filteredResults.current.find(p =>
          p.childIds?.find(childId => childId.indexOf(x.original.id) > -1))
        !== undefined;

      return matchedByColumnAccessors || isPartOfAMatchedParentRow;
    });
  }, [filterMapper]);



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    setAllFilters,
    setHiddenColumns,
    state: { pageIndex }
  } = useTable(
    {
      columns: columns ?? {},
      globalFilter: globalFilter,
      data: data ?? emptyData,
      defaultColumn,
      manualPagination: manualPagination,
      ...(manualPagination && { pageCount: Math.ceil(queryCount / queryPageSize) }),
      autoResetPage,
      initialState: {
        pageIndex: manualPagination ? queryPageIndex : 0,
        pageSize: manualPagination ? queryPageSize : initialPageSize > 0 ? initialPageSize : 10
      }
    },
    ...tableHooks
  );

  const { userHiddenColumnsAccessors } = useUiTableSettings(showViewSettings, identifier, columns);
  const { onAfterPrint, onBeforePrint } = usePrint(setHiddenColumns, userHiddenColumnsAccessors);
  const [nonUserHiddenColumns] = useState(hiddenColumns);

  useEffect(() => {
    if (userHiddenColumnsAccessors?.length > 0)
      setHiddenColumns(userHiddenColumnsAccessors);

    if (nonUserHiddenColumns?.length > 0)
      setHiddenColumns(nonUserHiddenColumns);

  }, [setHiddenColumns, userHiddenColumnsAccessors, nonUserHiddenColumns]);

  const onPageChange = ((page) => {
    if (manualPagination) {
      handlePageChange(page);
    }
  });

  const onPageSizeChange = ((pageSize) => {
    if (manualPagination) {
      handlePageSizeChange(pageSize); gotoPage(0);
    }
    else {
      setPageSize(pageSize)
    }
  });

  const anyFilters =
    headerGroups.filter((x) => x.headers.filter((column) => column.filter).length).length > 0;

  const hasPagination = (manualPagination && (queryCount > queryPageSize || queryCount > Math.min(...pageSizes)))
    || (!manualPagination && (rows.length > state.pageSize || rows.length > Math.min(...pageSizes)))

  const [selectAllPool, setSelectAllPool] = useState(false);
  const [prePageIndex, setPrePageIndex] = useState(0);
  const [preDataRefreshed, setPreDataRefreshed] = useState(false);

  useEffect(() => {
    if (dataRefreshed) setPreDataRefreshed(true);
  }, [dataRefreshed, page]);

  useEffect(() => {
    if ((preDataRefreshed || prePageIndex !== pageIndex) && handleSelectAll !== undefined) {
      if (preDataRefreshed) setPreDataRefreshed(false);
      if (prePageIndex !== pageIndex) setPrePageIndex(pageIndex);
      const val = areSelectedAll(page)
      setSelectAllPool(val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, prePageIndex, preDataRefreshed, areSelectedAll, handleSelectAll]);

  return (
    <Card className="mb-0" style={{ maxHeight: maxHeight }}>
      {children ? (
        <CardHeader className="p-3">
          <Row className="align-items-center">
            <Col xs="6">{title && <h3 className="mb-0">{title}</h3>}</Col>
            <Col className="text-right" xs="6">
              {children}
            </Col>
          </Row>
        </CardHeader>
      ) : ('')}
      {((title && !children) || !hideSearch) && (
        <div className="d-flex">
          <div className={classnames("flex-fill d-flex align-items-center p-3", headerClassName)} >
            {title && !children && <h3 className={classnames("mb-0", titleClassName)}>{title}</h3>}
          </div>

          <div className="d-flex align-items-center">
            {onRefresh && (
              <IconWithTooltip
                className="fas fa-sync-alt cursor-pointer mx-2"
                onClick={onRefresh}
                tooltip={getTranslation(intl, "table.refresh")}
              />
            )}
            {anyFilters && (
              <IconWithTooltip
                className="fas fa-times-circle cursor-pointer mx-2"
                onClick={resetFilters}
                tooltip={getTranslation(intl, "table.clearFilters")}
              />
            )}
            {showAdd && (
              <IconWithTooltip
                className="fas fa-plus cursor-pointer p-3 m-2 border-1"
                onClick={() => setShowSettingsModal(true)}
                tooltip={getTranslation(intl, "disastorRecovery.add")}
              />
            )}
            {showSettingsModal && (
              <DisasterRecoveryUpsertModal closeModal={() => setShowSettingsModal(false)} />
            )}
            {/* {showViewSettings && identifier && (
              <>
                <IconWithTooltip
                  className="fas fa-cog cursor-pointer mx-2"
                  onClick={() => setShowSettingsModal(true)}
                  tooltip={getTranslation(intl, "table.viewSettings")}
                />

                {showSettingsModal && (
                  <TableSettingsModal
                    tableIdentifier={identifier}
                    columns={columns}
                    closeModal={() => setShowSettingsModal(false)}
                    children = {customViewSettingsComponents}
                  >
                  </TableSettingsModal>
                )}
              </>
            )} */}
          </div>

          {showExport && page.length > 0 && (
            <div className="d-flex align-items-center ml-2 mr-3">
              <ExportButtons
                data={data}
                columns={exportColumns ?? columns}
                printRef={printRef}
                onAfterPrint={onAfterPrint}
                onBeforePrint={onBeforePrint}
              />
            </div>
          )}

          {showDownload && page.length > 0 && (
            <div className="d-flex align-items-center ml-2 mr-3">
              <Download
                data={data}
                columns={exportColumns ?? columns}
                printRef={printRef}
                onAfterPrint={onAfterPrint}
                onBeforePrint={onBeforePrint}
              />
            </div>
          )}

          {showDownloadQuery && page.length > 0 && (
            <div className="d-flex align-items-center ml-2 mr-3">
              <Download
                data={data}
                queryDownload={true}
                columns={exportColumns ?? columns}
                printRef={printRef}
                onAfterPrint={onAfterPrint}
                onBeforePrint={onBeforePrint}
              />
            </div>
          )}

          {!hideSearch && (
            <div className="d-flex align-items-center ml-2 mr-3">
              <Input
                bsSize="sm"
                type="text"
                value={state.globalFilter || ""}
                placeholder={"Search"}
                onChange={(e) => {
                  setGlobalFilter(e.target.value || undefined);
                  gotoPage(0);
                }}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`table-responsive ${classnames({
          "pc-overflow-x-auto": !anyFilters
        })}`}
        ref={printRef}>
        <Table responsive {...getTableProps()} className={rows.length > 0 ? "table min-height-120" : "table"}>
          {!hideHeaders &&
            <thead className="thead-light">
              {headerGroups.map((headerGroup, index) => (
                <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <>
                      {index === 0 && handleSelectAll !== undefined &&
                        <th key={index}
                          className={classnames("p-3 pt-2", column?.className, {
                            "print-only": userHiddenColumnsAccessors.includes(column.id)
                          })}>
                          <CheckboxFormInput
                            label={'Number'}
                            value={("Number")}
                            checked={selectAllPool}
                            handleInputChange={(value) => {
                              handleSelectAll(value,page)
                            }
                            }
                          >
                          </CheckboxFormInput>
                        </th>}
                      {((handleSelectAll !== undefined && index > 0) || handleSelectAll === undefined) &&
                        <th
                          key={index}
                          className={classnames("p-3", column?.className, {
                            "print-only": userHiddenColumnsAccessors.includes(column.id)
                          })}>
                          <div className={classnames({ "pc-table-header": anyFilters })}>
                            <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fas fa-sort-down fa-lg" />
                                  ) : (
                                    <i className="fas fa-sort-up fa-lg" />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                            <div>{column.canFilter && column.filter && column.render("Filter")}</div>
                          </div>
                        </th>}
                    </>
                  ))}
                </tr>
              ))}
            </thead>}
          <tbody {...getTableBodyProps()}>
            {page.map(
              (row) =>
                prepareRow(row) || (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps()}
                          className={classnames("p-3", cell.column.className, {
                            "print-only": userHiddenColumnsAccessors.includes(cell.column.id)
                          })}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                )
            )}
          </tbody>
        </Table>
      </div>
      {
        isLoading ? (
          <CardBody className="py-4 text-center">
            <FormattedMessage id="table.isLoading" />
          </CardBody>
        ) : (hasPagination) ? (
          <CardFooter className="py-4">
            <Row className="align-items-center">
              <Col>
                <UncontrolledDropdown size="sm">
                  <DropdownToggle caret color="secondary">
                    <FormattedMessage id="table.showItems" values={{ pageSize: manualPagination ? queryPageSize : state.pageSize }} />
                  </DropdownToggle>
                  <DropdownMenu>
                    {/* {pageSizes.map((pageSize) => (
                      <DropdownItem key={pageSize} onClick={() => setPageSize(pageSize)}>
                        <FormattedMessage id="table.showItems" values={{ pageSize: pageSize }} />
                      </DropdownItem>
                    ))} */}
                    {pageSizes.map((pageSize) => (
                      <DropdownItem key={pageSize} onClick={() => onPageSizeChange(pageSize)}>
                        <FormattedMessage id="table.showItems" values={{ pageSize: pageSize }} />
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
              <Col className="text-center">
                <FormattedMessage
                  id="table.pagination"
                  values={{
                    page: <b>{manualPagination ? queryPageIndex + 1 : state.pageIndex + 1}</b>,
                    numberOfPages: <b>{pageOptions.length}</b>
                  }}
                />
              </Col>
              <Col>
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0">
                  <PaginationItem className={!canPreviousPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => { gotoPage(0); onPageChange(0) }} tabIndex="-1">
                      <i className="fas fa-angle-double-left" />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={!canPreviousPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => { previousPage(); onPageChange(queryPageIndex - 1) }} tabIndex="-1">
                      <i className="fas fa-angle-left" />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={!canNextPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => { nextPage(); onPageChange(queryPageIndex + 1) }}>
                      <i className="fas fa-angle-right" />
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={!canNextPage ? "disabled" : ""}>
                    <PaginationLink onClick={() => { gotoPage(pageCount - 1); onPageChange(pageCount - 1) }}>
                      <i className="fas fa-angle-double-right" />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Col>
            </Row>
          </CardFooter>
        ) : (
          rows.length <= 0 && (
            <CardBody className="py-4 text-center">
              <FormattedMessage id="table.noData" />
            </CardBody>
          )
        )
      }
    </Card >
  );
}

const pageSizes = [5, 10, 20, 50, 100];
