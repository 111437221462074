import { ApiError } from "components/common/types/ApiError";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import { HttpClient } from "services/httpClient/HttpClient";

export const MapApi = {
    get: (postalCode: any): Promise<any> => {
        return HttpClient.getAtlas<any>(`search/address/json?api-version=1.0&query=${postalCode}&view=Auto`, true);
    },

    getPolygon: (geomentoryIds: any): Promise<any> => {
        return HttpClient.getAtlas<any>(`search/polygon/json?api-version=1.0&geometries=${geomentoryIds}`, true);
    },

    getCarrier: (): Promise<CarrierDto[]> => {
        return HttpClient.get<CarrierDto[]>(`carriers`, true, true);
    },

    addCarrier: (requestOutage: any): Promise<ApiError> => {
        return HttpClient.post<ApiError>(`carriers/outages`, requestOutage, true, true);
    },
    getZipcodes: (startInsertDate: any): Promise<CarrierDto[]> => {
        return HttpClient.get<CarrierDto[]>(`carriers/outages?startInsertDate=${startInsertDate}`, true, true);
    },

    getZipcodeCarrier: (carrierId: any): Promise<CarrierDto> => {
        return HttpClient.get<CarrierDto>(`carriers/${carrierId}`, true, true);
    },
};
