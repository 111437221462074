import React from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import { KeyValuePair } from "services/types/KeyValuePair";
import DropdownInput from "./DropdownInput";
import IconWithTooltip from "components/common/IconWithTooltip";
import { getTranslation } from "translations/TranslationService";

export type DropdownFormInputProps = {
  id: number;
  canDelte?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelTranslationId?: string;
  labelClassName?: string;
  value?: string;
  errorHandler?: ErrorHandler;
  className?: string;
  handleInputChange: (value: string) => void;
  handleDelete?: (value: number) => void;
  options: KeyValuePair<string, string>[];
  lg?: boolean;
};

export default function DropdownFormInput(props: DropdownFormInputProps) {
  const errors = props.errorHandler?.getErrors();
  const intl = useIntl();

  return (
    <FormGroup className={classNames("mb-2", props.className)}>
      {props.labelTranslationId && (
        <Row>
          <Col>
            <Label className={classNames("form-control-label", props.labelClassName, { "pc-required-label": props.required })}>
              <FormattedMessage id={props.labelTranslationId} />
            </Label>
          </Col>
          {props.canDelte && props.handleDelete && props.id &&
            <Col>
              <IconWithTooltip
                className="fal fa-trash-alt cursor-pointer justify-content-end pc-search-container"
                onClick={() => props.handleDelete ? props.handleDelete(props.id) : {}}
                tooltip={getTranslation(intl, "disasterRecovery.create.deleteTemplateGroup")}
              />
            </Col>
          }
        </Row>
      )}
      <DropdownInput
        disabled={props.disabled}
        className={classNames({
          "pc-invalid-input": errors && errors.length > 0
        })}
        sm={!props.lg}
        onChange={(e) => {
          if (props.errorHandler) {
            props.errorHandler.clear();
          }
          props.handleInputChange(e.currentTarget.value);
        }}
        value={props.value}
        options={props.options}
      />
      {errors && <InputError errors={errors} />}
    </FormGroup>
  );
}
