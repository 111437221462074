import { ActivityDto } from "services/activities/ActivityDto";
import { KindsDto } from "services/activities/KindsDto";
import { HttpClient } from "../httpClient/HttpClient";

export const ActivityApi = {
    list: (startDate: string, kind: number, pageNumber: number, pageSize: number): Promise<ActivityDto[]> => {
        return HttpClient.get<ActivityDto[]>(`activities?startDate=${startDate}&kind=${kind}&pageNumber=${pageNumber}&pageSize=${pageSize}`, true, true);
    },
    getCnamFees: (): Promise<KindsDto> => {
        return HttpClient.get<KindsDto>('activities/kinds/43');
    },
    getFraudFees: (): Promise<KindsDto> => {
        return HttpClient.get<KindsDto>('activities/kinds/47');
    },
    getRealBrand: (startDate: string, kind: number): Promise<any> => {
        return HttpClient.get<KindsDto>(`activities?startDate=${startDate}&kindId=${kind}`);
    }
};
