import React, { useState } from "react";
import { Button } from "reactstrap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
//import { PermissionType } from "services/authorization/PermissionType";
//import Authorize from "components/framework/authorization/Authorize";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleFieldOrApiErrors } from "services/util/ApiUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
//import { isUrl } from "services/util/StringUtil";
import { openMailTo, openNewBackgroundTab } from "services/util/LinkUtil";
import { isUrl } from "services/util/StringUtil";

type Props = {
  spid: string;
  requestedTradeDate?: Date;
  serviceProvider: ServiceProviderProfile;
  requestTradingPartnerCallback: () => void;
};

export default function TradingPartnerProfileCardFooterButtons(props: Props) {
  const [showAddLoadingIndicator, setShowAddLoadingIndicator] = useState(false);
  const intl = useIntl();

  const requestTradingPartner = () => {
    setShowAddLoadingIndicator(true);

    TradingPartnerApi.add(props.spid)
      .then(() => {
        props.requestTradingPartnerCallback();
        showInfoNotification(
          intl.formatMessage({ id: "tppModal.buttons.requestToTrade.successMessage" })
        );
        if (isUrl(props.serviceProvider.lnpTppLocation))
          openNewBackgroundTab(props.serviceProvider.lnpTppLocation);
      })
      .catch((errors) => handleFieldOrApiErrors(errors))
      .finally(() => setShowAddLoadingIndicator(false));
  };

  return (
    <>
      {props.requestedTradeDate ? (
        <div className="d-inline-block align-left">
          <h6 className="mb-1">
            <FormattedMessage id="tppModal.requestedTradeDate" />
          </h6>
          <h5 className="mb-0">
            <FormatDateTime utcDate={props.requestedTradeDate} />
          </h5>
        </div>
      ) : (
        props.serviceProvider.spId.length === 4 && (
          <Button
            color="success"
            type="button"
            className="btn-sm"
            disabled={showAddLoadingIndicator}
            onClick={requestTradingPartner}>
            {showAddLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="tppModal.buttons.requestToTrade" />
          </Button>
        )
      )}

      <Button
        color="secondary"
        onClick={() => {
          openMailTo(getCustomMailTo(intl, props.serviceProvider, props.spid));
        }}
        className="btn-sm"
      >
        <FormattedMessage id="tppModal.buttons.report" />
      </Button>
    </>
  );
}

const getCustomMailTo = (
  intl: IntlShape,
  serviceProvider: ServiceProviderProfile,
  spId: string
) => {
  const newLine = "%0D%0A";
  const subject = intl.formatMessage(
    { id: "tppModal.reportIncorrectData.subject" },
    { spId }
  );
  const body = `${serviceProvider.lsrLocation
    ? `${intl.formatMessage({ id: "tppModal.lsrLocation" })}: ${serviceProvider.lsrLocation
    }${newLine}`
    : ""
    }${serviceProvider.csrLocation
      ? `${intl.formatMessage({ id: "tppModal.csrLocation" })}: ${serviceProvider.csrLocation
      }${newLine}`
      : ""
    }${serviceProvider.lnpTppLocation
      ? `${intl.formatMessage({ id: "tppModal.lnpTppLocation" })}: ${serviceProvider.lnpTppLocation
      }${newLine}`
      : ""
    }${serviceProvider.lnpSpeed
      ? `${intl.formatMessage({ id: "tppModal.processingTime" })}: ${serviceProvider.lnpSpeed
      }${newLine}`
      : ""
    }${serviceProvider.lnpPhoneNumber
      ? `${intl.formatMessage({ id: "tppModal.lnpPhoneNumber" })}: ${serviceProvider.lnpPhoneNumber
      }${newLine}`
      : ""
    }${serviceProvider.lnpEmail
      ? `${intl.formatMessage({ id: "tppModal.lnpEmail" })}: ${serviceProvider.lnpEmail
      }${newLine}`
      : ""
    }${serviceProvider.businessHours
      ? `${intl.formatMessage({ id: "tppModal.businessHours" })}: ${serviceProvider.businessHours
      }${newLine}`
      : ""
    }${serviceProvider.residentialRules
      ? `${intl.formatMessage({ id: "tppModal.residentialRules" })}: ${serviceProvider.residentialRules
      }${newLine}`
      : ""
    }${serviceProvider.businessRules
      ? `${intl.formatMessage({ id: "tppModal.businessRules" })}: ${serviceProvider.businessRules
      }${newLine}`
      : ""
    }${serviceProvider.firstLevelSupport
      ? `${intl.formatMessage({ id: "tppModal.firstLevelSupport" })}: ${serviceProvider.firstLevelSupport
      }${newLine}`
      : ""
    }${serviceProvider.firstLevelSupportEmail
      ? `${intl.formatMessage({
        id: "tppModal.firstLevelSupport",
      })} ${intl.formatMessage({
        id: "tppModal.firstLevelSupport.email.placeholder",
      })}: ${serviceProvider.firstLevelSupportEmail}${newLine}`
      : ""
    }${serviceProvider.firstLevelSupportPhoneNumber
      ? `${intl.formatMessage({
        id: "tppModal.firstLevelSupport",
      })} ${intl.formatMessage({
        id: "tppModal.firstLevelSupport.phoneNumber.placeholder",
      })}: ${serviceProvider.firstLevelSupportPhoneNumber}${newLine}`
      : ""
    }${serviceProvider.secondLevelSupport
      ? `${intl.formatMessage({ id: "tppModal.secondLevelSupport" })}: ${serviceProvider.secondLevelSupport
      }${newLine}`
      : ""
    }${serviceProvider.secondLevelSupportEmail
      ? `${intl.formatMessage({
        id: "tppModal.secondLevelSupport",
      })} ${intl.formatMessage({
        id: "tppModal.secondLevelSupport.email.placeholder",
      })}: ${serviceProvider.secondLevelSupportEmail}${newLine}`
      : ""
    }${serviceProvider.secondLevelSupportPhoneNumber
      ? `${intl.formatMessage({
        id: "tppModal.secondLevelSupport",
      })} ${intl.formatMessage({
        id: "tppModal.secondLevelSupport.phoneNumber.placeholder",
      })}: ${serviceProvider.secondLevelSupportPhoneNumber}${newLine}`
      : ""
    }${serviceProvider.repairCenter
      ? `${intl.formatMessage({ id: "tppModal.repairCenter" })}: ${serviceProvider.repairCenter
      }${newLine}`
      : ""
    }${serviceProvider.repairCenterEmail
      ? `${intl.formatMessage({
        id: "tppModal.repairCenter",
      })} ${intl.formatMessage({
        id: "tppModal.repairCenter.email.placeholder",
      })}: ${serviceProvider.repairCenterEmail}${newLine}`
      : ""
    }${serviceProvider.notes
      ? `${intl.formatMessage({ id: "tppModal.notes" })}: ${serviceProvider.notes
      }${newLine}`
      : ""
    }`;
  return `mailto:lnp@atlc.com?subject=${subject}&body=${body}`;
};
