import React, { useContext, useState, ChangeEvent, useEffect } from "react";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Navbar,
  Nav,
  Container,
  NavbarBrand,
  Dropdown,

  NavItem
} from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { AppContext } from "services/appContext/AppContext";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import classnames from "classnames";
import Select2Dropdown from "./Select2Dropdown";
import { UserClient } from "services/apis/types/account/UserClient";
import { useAuth0 } from "@auth0/auth0-react";


type Props = {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
};

export default function AdminNavbar(props: Props) {
  const { appContext, setAppContext } = useContext(AppContext);
  const [clientId, setClientId] = useState<number>(
    appContext.selectedClient
      ? appContext.selectedClient.clientId
      : 0
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const intl = useIntl();
  let history = useHistory();

  const { logout } = useAuth0();


  useEffect(() => {
    if (appContext.selectedClient?.clientId) {
      setClientId(appContext.selectedClient.clientId);
    }
  }, [appContext.selectedClient]);

  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const logoutApp = async () => {
    logout({ returnTo: `${window.location.origin}`} )
   // await new Promise(r => setTimeout(r, 2000));
    //IdentityService.clearAuthenticationInfo();
  };

  const selectSpidHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const userClient = appContext.localStorageInfo.userClients
      ? appContext.localStorageInfo.userClients.find((x) => x.clientId === parseInt(event.target.value))
      : undefined;

    if (userClient) {
      setClientId(parseInt(event.target.value));
      setAppContext({
        ...appContext,
        selectedClient: userClient
      });
      showInfoNotification(
        `${intl.formatMessage(
          { id: "adminNavbar.newUserClientSelected" },
          { clientId: profileText(userClient) }
        )}`
      );
      setDropdownOpen(false);
    }
  };

  const profileText = (userClient: UserClient) => `${userClient.clientName} (${userClient.respOrgId})`;

  const spIdText = appContext.selectedClient ? ` (${appContext.selectedClient &&
    appContext.selectedClient.respOrgId
    })`
    : "";

  return (
    <Navbar className="navbar navbar-expand navbar-top navbar-dark pc-nav-border-bottom pc-bg-gradient-primary">
      <Container fluid={true}>
        <NavbarBrand to="/" tag={Link}>
          <img
            alt="PortControl"
            src={require("assets/img/logo.png")}
            className="pc-navbar-img"
          />
        </NavbarBrand>

        {/* <HubSpotCallToAction 
          portalId={hubspotParameter("portalId")} 
          ctaToken={hubspotParameter("ctaToken")} 
        /> */}

        <Nav className="align-items-center ml-auto ml-md-0" navbar>
          <NavItem className="d-xl-none">
            <div
              className={classnames("pr-3 sidenav-toggler sidenav-toggler-dark", {
                active: props.sidenavOpen
              })}
              onClick={props.toggleSidenav}>
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </NavItem>
          <Dropdown nav isOpen={dropdownOpen} toggle={dropdownToggle}>
            <DropdownToggle className="nav-link pr-0 cursor-pointer" color="" tag="a">
              <Media className="align-items-center">
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {appContext.localStorageInfo.user &&
                      `${appContext.localStorageInfo.user.firstName} ${appContext.localStorageInfo.user.lastName} - ${appContext.selectedClient?.clientName} ${spIdText}`}
                  </span>
                </Media>
                <i className="ml-2 fa fa-user fa-lg" />
              </Media>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">
                  <FormattedMessage id="adminNavbar.switchClient" />
                </h6>
              </DropdownItem>
              {appContext.localStorageInfo.userClients &&
                appContext.localStorageInfo.userClients.length > 0 && (
                  <div className="dropdown-item">
                    <Select2Dropdown
                      data={appContext.localStorageInfo.userClients.map((x) => {
                        return { id: x.clientId, text: profileText(x) };
                      })}
                      handleInputChange={selectSpidHandler}
                      value={clientId.toString()}
                      autofocus={true}
                    />
                  </div>
                )}
              <DropdownItem onClick={() => history.push("/myprofile")} className="cursor-pointer">
                <i className="ni ni-single-02" />
                <span>
                  <FormattedMessage id="adminNavbar.myProfile" />
                </span>
              </DropdownItem>
              {/* <DropdownItem
                className="cursor-pointer"
                onClick={() => window.open("https://trello.com/b/JJCAGv8i/portcontrol", "_blank")}>
                <i className="fab fa-trello" />
                <span>
                  <FormattedMessage id="adminNavbar.trello" />
                </span>
              </DropdownItem> */}
              {/* <DropdownItem className="cursor-pointer" onClick={() => history.push("/Support")}>
                <i className="ni ni-support-16" />
                <span>
                  <FormattedMessage id="adminNavbar.support" />
                </span>
              </DropdownItem> */}
              <DropdownItem divider></DropdownItem>
              <DropdownItem onClick={() => logoutApp()} className="cursor-pointer">
                <i className="ni ni-user-run" />
                <span>
                  <FormattedMessage id="adminNavbar.logout" />
                </span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* {/* <NotificationsDropdown /> */}
        </Nav>
      </Container>
    </Navbar>
  );
}
