
import React, { useState, useContext } from "react";
import { Col, Container, Row } from "reactstrap";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import ActionDropdown from "components/framework/actionDropdown/ActionDropdown";
import ActionDropdownItem from "components/framework/actionDropdown/ActionDropdownItem";
import TollFreeQueryUpsertModal from "./TollFreeQueryUpsertModal";
import TollFreeHistoryModal from "./TollFreeHistoryModal";
import TollFreeQueryEditReserveModal from "./TollFreeQueryEditReserveModal";
import { AppContext } from "services/appContext/AppContext";

type Props = {
    tollFreeNumber: TollFreeNumbersDto;
    type: string;
};

export default function TollFreeNumbersActionsCell(props: Props) {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [actionsOpen, setActionsOpen] = useState(false);
    // const [showcard, setShowcard] = useState(false);
    const { tollFreeNumber } = props;
    const [showBulkReserve, setShowBulkReserve] = useState(false);
    const { appContext } = useContext(AppContext);

    // const [profile, setProfile] = useState<UserClient>;

    return (
        <Container className="nopadding">
            <Row className="justify-content-center flex-no-wrap">
                <Col className="nopadding">
                    <ActionDropdown actionsOpen={actionsOpen} actionsToggle={() => setActionsOpen(prevState => !prevState)}>
                        <ActionDropdownItem
                            handleAction={() => setShowEditModal(true)}
                            iconClassName="fa fa-edit text-primary"
                            labelId="tollfree.table.actions.edit"
                            disabled={props.type === "explore" && tollFreeNumber.respOrgId !== appContext.selectedClient?.respOrgId}
                        />
                        {props.type === "numbers" &&
                            <ActionDropdownItem
                                handleAction={() => setShowHistoryModal(true)}
                                iconClassName="fa fa-clock text-primary"
                                labelId="tollfree.table.actions.history"
                            />}
                        {/* <ActionDropdownItem
                            handleAction={() => setShowDeleteModal(true)}
                            iconClassName="fa fa-trash-alt text-primary"
                            labelId="tollfree.table.actions.delete"
                        /> */}
                        {/* <ActionDropdownItem
                            handleAction={() => setShowcard(true)}
                            iconClassName="fa fa-clock text-primary"
                            labelId="tollfree.table.actions.resporg"
                        /> */}
                        {props.type === "explore" &&
                            <ActionDropdownItem
                                handleAction={() => setShowBulkReserve(true)}
                                iconClassName="fa fa-check-double text-primary"
                                labelId="tollfree.table.actions.bulkQuery"
                                disabled={!tollFreeNumber.Status || tollFreeNumber.Status.toLowerCase() !== "spare"}
                            />}
                    </ActionDropdown>
                    {showHistoryModal && (
                        <TollFreeHistoryModal
                            tollFreeNumber={tollFreeNumber}
                            closeModal={() => setShowHistoryModal(false)}
                        />
                    )}
                    {showEditModal && (
                        <TollFreeQueryUpsertModal
                            tollFreeNumber={tollFreeNumber}
                            closeModal={() => setShowEditModal(false)}
                        />
                    )}
                    {showBulkReserve && (
                        <TollFreeQueryEditReserveModal
                            closeModal={() => setShowBulkReserve(false)}
                            tollFreeNumber={tollFreeNumber}
                        />
                    )}
                    {showDeleteModal && <></>}
                </Col>
            </Row>
        </Container>
    );
}
