import React from "react";
import { Link } from "react-router-dom";
import { NavbarBrand, Navbar, Container, Nav, DropdownToggle, UncontrolledDropdown } from "reactstrap";

export default function LoginNavbar() {
  return (
    <Navbar className="navbar-horizontal navbar-main navbar-dark navbar-transparent" expand="lg" id="navbar-main">
      <Container>
        <NavbarBrand to="/" tag={Link}>
          <img alt="Atlas" src={require("assets/img/logo.png")} />
        </NavbarBrand>
        <Nav className="align-items-center ml-md-auto" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="nav-link" color="" tag="a">
              <i className="hidden" />
            </DropdownToggle>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>
  );
}
