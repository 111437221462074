import React, { FormEvent } from "react";
import { Modal, Form, Button, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";

type Props = {
    closeRealBrandModal: () => void;
    onSubmit: () => void;
    showLoadingIndicator:boolean;
};


function TollFreeRealBrandModal(props: Props) {

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        props.onSubmit();
    }

    return (
        <Modal size="lg" className={"modal-dialog-centered"} isOpen={true} zIndex={2147483649}>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div className={"realbrand-modal text-center p-3"}>
                    <h2 className="font-weight-bold">CNAM Update Request Form</h2>
                    <div className="d-flex justify-content-center">
                        <img src={require("assets/img/realbrand_logo.png")} alt="RealBrand" className="img-fluid col-3" />
                    </div>
                    <h2 className="font-weight-bold mt-4">Letter of Agency (LOA)</h2>
                    <Row className="d-flex justify-content-center p-4 font-size-base">
                        <p className="text-justify font-weight-normal">
                            <FormattedMessage id="bulkUpdate.realBrandUpdate.loa1" />
                        </p>
                    </Row>
                    <Row className="d-flex justify-content-center pl-4 pb-4 pr-4 font-size-base">
                        <p className="text-justify font-weight-normal">
                            <span className="font-weight-bolder">
                                <FormattedMessage id="bulkUpdate.realBrandUpdate.electronicExecution" />
                            </span> <FormattedMessage id="bulkUpdate.realBrandUpdate.loa2" />
                        </p>
                    </Row>
                    <Row className="w-100 p-3 d-flex justify-content-center">
                        <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeRealBrandModal()}>
                            <FormattedMessage id="bulkUpdate.realBrandUpdate.cancelButton" />
                        </Button>
                        <Button color="primary" type="submit" disabled={props.showLoadingIndicator}>
                            {props.showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2"/>}
                            <FormattedMessage id="bulkUpdate.realBrandUpdate.acceptButton" />
                        </Button>
                    </Row>
                </div>
            </Form>
        </Modal>
    )
}

export default TollFreeRealBrandModal