import React from "react";
import { Col, Row, Container } from "reactstrap";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import ChangePasswordCard from "./ChangePasswordCard";
import ProfileCard from "./ProfileCard";

export default function MyProfile() {
  return (
    <>
      <BreadcrumbHeader>
        <FormattedMessage id="adminNavbar.myProfile" />
      </BreadcrumbHeader>
      <Container fluid>
        <Row>
          <Col className="offset-lg-1 col-md-12 col-lg-6">
            <ProfileCard />
          </Col>
          <Col className="col-md-12 col-lg-4">
            <ChangePasswordCard />
          </Col>
        </Row>
      </Container>
    </>
  );
}
