import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, Button, Row, NavLink } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { WorkingTemplateInfo } from "services/apis/types/template/WorkingTemplateInfo";
import { TemplateApi } from "services/apis/TemplateApi";
import CloseButton from "components/framework/modals/CloseButton";
import { DefaultTemplateGroup, TemplateGroup } from "services/disasterRecovery/TemplateGroup";
import { DisasterRecoveryApi } from "services/apis/DisasterRecoveryApi";
import { RequestAddDisasterRecovery } from "services/disasterRecovery/RequestAddDisasterRecovery";
import { DisasterRecoveryStatusType } from "services/disasterRecovery/DisasterRecoveryStatusType";
import { HomeContext } from 'components/home/Home';
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";
import TemplateCarriers from "./TemplateCarriers";
import { ModalLoadingIndicator } from "components/framework/modalLoadingIndicator/ModalLoadingIndicator";
import { TemplateDto } from "services/apis/types/template/TemplateDto";

type Props = {
    closeModal: () => void;
    disasterRecovery?: DisasterRecoveryDto;
};



export default function DisasterRecoveryUpsertModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [scenarioName, setScenarioName] = useState<string>("");
    const [currentType, setCurrentType] = useState<string>("");
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const [currentTemplateId, setCurrentTemplateId] = useState<number>(0);
    const [workingTemplates, setWorkingTemplates] = useState<Array<WorkingTemplateInfo>>([]);
    const [drTemplates, setDrTemplates] = useState<Array<WorkingTemplateInfo>>([]);
    const [selectedTemplates, setSelectedTemplates] = useState<Array<TemplateGroup>>([DefaultTemplateGroup]);
    const [showCarrierLoadingIndicator, setShowCarrierLoadingIndicator] = useState(false);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [showWorkingTemplateLoadingIndicator, setShowWorkingTemplateLoadingIndicator] = useState(false);
    const [showDRTemplateLoadingIndicator, setShowDRTemplateLoadingIndicator] = useState(false);
    const [isCurrent, setIsCurrent] = useState(false);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const homeContext = useContext(HomeContext);

    const closeModal = useCallback(props.closeModal, []);

    const { disasterRecovery } = props;

    const fetchWorkingTemplates = useCallback(() => {
        setShowWorkingTemplateLoadingIndicator(true)
        TemplateApi.list(false, true, true, 1, 10000)
            .then((result) => {
                if (isMounted.current) {
                    setWorkingTemplates(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowWorkingTemplateLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchDRTemplates = useCallback(() => {
        setShowDRTemplateLoadingIndicator(true);
        TemplateApi.list(true, true, true, 1, 10000)
            .then((result) => {
                if (isMounted.current) {
                    setDrTemplates(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowDRTemplateLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchDisasterRecovery = useCallback(() => {
        if (disasterRecovery) {
            setSelectedTemplates([DefaultTemplateGroup]);
            setShowDRTemplateLoadingIndicator(true);
            DisasterRecoveryApi.get(disasterRecovery, true)
                .then((result) => {
                    if (isMounted.current) {
                        setScenarioName(result.name);
                        setSelectedTemplates(result.templateGroups)
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowDRTemplateLoadingIndicator(false);
                    }
                });
        }
    }, [isMounted, disasterRecovery]);

    const fetchTemplateCarriers = (index: number, type: string, template: TemplateDto) => {
        const carrierIds: any[] = [];
        let finalCarrierIds: any[] = [];

        if (template?.carrier1Id) {
            carrierIds.push(template.carrier1Id);
        } if (template?.carrier2Id) {
            carrierIds.push(template.carrier2Id);
        } if (template?.carrier3Id) {
            carrierIds.push(template.carrier3Id);
        } if (template?.carrier4Id) {
            carrierIds.push(template.carrier4Id);
        }
        const existingTemplates = [...selectedTemplates]
        let existingTemplate = existingTemplates[index];
        if (type === "working") {
            existingTemplate.workingCarriers = []
        }
        else if (type === "initiate") {
            existingTemplate.initiateCarriers = []
        }
        else if (type === "revert") {
            existingTemplate.revertCarriers = []
        }
        existingTemplates[index] = existingTemplate;
        setSelectedTemplates(existingTemplates);
        finalCarrierIds = carrierIds.filter((item, index) => carrierIds.indexOf(item) === index);
        finalCarrierIds.forEach((carrierId) => {
            DisasterRecoveryApi.getCarrier(carrierId)
                .then((result) => {
                    if (isMounted.current && result) {
                        if (type === "working") {
                            existingTemplate.workingCarriers?.push(result);
                        }
                        else if (type === "initiate") {
                            existingTemplate.initiateCarriers?.push(result);
                        }
                        else if (type === "revert") {
                            existingTemplate.revertCarriers?.push(result);
                        }
                        existingTemplates[index] = existingTemplate;
                        setSelectedTemplates(existingTemplates);
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    // if (isMounted.current) {
                    //     //setShowCarrierLoadingIndicator(false);
                    // }
                });
        });

    }

    useEffect(() => {
        fetchWorkingTemplates();
        fetchDRTemplates();
        if (disasterRecovery) {
            fetchDisasterRecovery();
        }
    }, [fetchWorkingTemplates, fetchDRTemplates, fetchDisasterRecovery, disasterRecovery]);

    useEffect(() => {
        const carrierIds: any[] = [];
        let finalCarrierIds: any[] = [];
        if (currentIndex > -1 && currentTemplateId > 0 && currentType) {
            setShowCarrierLoadingIndicator(true);
            TemplateApi.getInstructions(currentTemplateId, true)
                .then((result) => {
                    if (isMounted.current) {
                        if (result.length > 0) {
                            result.forEach((carrierObj) => {
                                if (carrierObj.carrier1Id) {
                                    carrierIds.push(carrierObj.carrier1Id);
                                } if (carrierObj.carrier2Id) {
                                    carrierIds.push(carrierObj.carrier2Id);
                                } if (carrierObj.carrier3Id) {
                                    carrierIds.push(carrierObj.carrier3Id);
                                } if (carrierObj.carrier4Id) {
                                    carrierIds.push(carrierObj.carrier4Id);
                                }
                            });
                        }
                        finalCarrierIds = carrierIds.filter((item, index) => carrierIds.indexOf(item) === index);
                        finalCarrierIds.forEach((carrierId) => {
                            DisasterRecoveryApi.getCarrier(carrierId)
                                .then((result) => {
                                    if (isMounted.current && result) {
                                        const existingTemplates = [...selectedTemplates]
                                        let existingTemplate = existingTemplates[currentIndex];
                                        if (currentType === "working") {
                                            if (existingTemplate.workingCarriers)
                                                existingTemplate.workingCarriers.push(result);
                                        }
                                        else if (currentType === "initiate") {
                                            if (existingTemplate.initiateCarriers)
                                                existingTemplate.initiateCarriers.push(result);
                                        }
                                        else if (currentType === "revert") {
                                            if (existingTemplate.revertCarriers)
                                                existingTemplate.revertCarriers.push(result);
                                        }
                                        existingTemplates[currentIndex] = existingTemplate;
                                        setSelectedTemplates(existingTemplates);
                                    }
                                })
                                .catch((error) => handleError(error))
                                .finally(() => {
                                    if (isMounted.current) {
                                        setShowCarrierLoadingIndicator(false);
                                    }
                                });
                        });
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowCarrierLoadingIndicator(false);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, currentTemplateId, currentType]);

    useEffect(() => {
        if (!isCurrent && selectedTemplates && selectedTemplates[0].workingTemplateId > 0 && disasterRecovery) {
            setIsCurrent(true);
            selectedTemplates.forEach((template, index) => {
                if (template.initiateTemplate) fetchTemplateCarriers(index, "initiate", template.initiateTemplate);
                if (template.workingTemplate) fetchTemplateCarriers(index, "working", template.workingTemplate)
                if (template.revertTemplate) fetchTemplateCarriers(index, "revert", template.revertTemplate)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTemplates, disasterRecovery, isCurrent]);

    const handleSelectedTemplateChanges = (template: TemplateGroup, type: string, index: number) => {
        const newSelectedTemplates = [...selectedTemplates];
        let templateId = 0;
        if (type === 'working') {
            template.workingCarriers = [];
            templateId = template.workingTemplateId;
        }
        else if (type === 'initiate') {
            template.initiateCarriers = [];
            templateId = template.initiateTemplateId;
        }
        else if (type === 'revert') {
            template.revertCarriers = [];
            templateId = template.revertTemplateId;
        }
        newSelectedTemplates[index] = template;
        setCurrentIndex(index);
        setCurrentType(type);
        setCurrentTemplateId(templateId);
        setSelectedTemplates(newSelectedTemplates);
    }

    const handleDelete = (index: number) => {
        const newSelectedTemplates = [...selectedTemplates];
        newSelectedTemplates.splice(index, 1);
        setSelectedTemplates(newSelectedTemplates);

    }
    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setShowLoadingIndicator(true);
        const errors = validateDisasterRecovery(scenarioName.trim(), selectedTemplates);

        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
            }
        } else if (disasterRecovery) {
            const updateTemplate: RequestAddDisasterRecovery = {
                name: scenarioName,
                statusType: DisasterRecoveryStatusType.active,
                templateGroups: selectedTemplates,
            }
            DisasterRecoveryApi.edit(disasterRecovery, updateTemplate)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "disasterRecovery.update.successNotificationMessage" })
                    );
                    closeModal();
                    homeContext.disasterRecoveryActionCallback();
                    homeContext.activityActionCallback();

                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        } else {
            const createTemplate: RequestAddDisasterRecovery = {
                name: scenarioName,
                statusType: DisasterRecoveryStatusType.active,
                templateGroups: selectedTemplates,
            }
            DisasterRecoveryApi.add(createTemplate)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "disasterRecovery.create.successNotificationMessage" })
                    );
                    closeModal();
                    homeContext.disasterRecoveryActionCallback();
                    homeContext.activityActionCallback();

                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
    };

    return (
        <Modal className="modal-dialog-centered modal-sm" isOpen={true} zIndex={2147483648}>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id={disasterRecovery ? "disasterRecovery.create.updateModalTitle" : "disasterRecovery.create.createModalTitle"} />
                </h5>
                <CloseButton close={props.closeModal} />
            </div>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="modal-body">
                    <Row>
                        <TextFormInput
                            formGroupClassName="col-lg-12"
                            labelTranslationId="disasterRecovery.create.scenarioName"
                            value={scenarioName}
                            handleInputChange={(value: string) =>
                                setScenarioName(value)
                            }
                            errorHandler={getErrorHandler(nameOf<RequestAddDisasterRecovery>("name"))}
                        />
                    </Row>
                    {selectedTemplates.map((template, index) => (
                        <>
                            {index > 0 && <hr className="divider"></hr>}
                            <Row>
                                {showWorkingTemplateLoadingIndicator &&
                                    <LoadingIndicator />}

                                {workingTemplates ? (
                                    <DropdownFormInput
                                        id={index}
                                        canDelte={index > 0}
                                        handleDelete={(value: number) => handleDelete(value)}
                                        className="col-lg-12"
                                        labelTranslationId={(index > 0) ? (`${intl.formatMessage({ id: "disasterRecovery.create.workingTemplateTitle" })} ${index + 1}`) : "disasterRecovery.create.workingTemplateTitle"}
                                        value={template.workingTemplateId.toString()}
                                        handleInputChange={(value: string) =>
                                            handleSelectedTemplateChanges({ ...template, workingTemplateId: parseInt(value) }, "working", index)
                                        }
                                        errorHandler={getErrorHandler(`${nameOf<TemplateGroup>("workingTemplateId")}${index}`)}
                                        options={[
                                            { key: intl.formatMessage({ id: "disasterRecovery.create.selectTemplate" }), value: "" },
                                            ...workingTemplates.map(x => {
                                                return { key: x.name, value: x.id };
                                            })
                                        ]}

                                    />
                                ) : ''}
                            </Row>
                            {template.workingCarriers && template.workingCarriers.length > 0 && (
                                <Row className="pl-3 pr-3">
                                    <TemplateCarriers carriers={template.workingCarriers} />
                                </Row>
                            )}
                            <Row>
                                {showDRTemplateLoadingIndicator &&
                                    <LoadingIndicator />}
                                {drTemplates ? (
                                    <DropdownFormInput
                                        id={index}
                                        className="col-lg-12"
                                        labelTranslationId={(index > 0) ? (`${intl.formatMessage({ id: "disasterRecovery.create.DRTemplateTitle" })} ${index + 1}`) : "disasterRecovery.create.DRTemplateTitle"}
                                        value={template.initiateTemplateId.toString()}
                                        handleInputChange={(value: string) =>
                                            handleSelectedTemplateChanges({ ...template, initiateTemplateId: parseInt(value) }, "initiate", index)
                                        }
                                        errorHandler={getErrorHandler(`${nameOf<TemplateGroup>("initiateTemplateId")}${index}`)}
                                        options={[
                                            { key: intl.formatMessage({ id: "disasterRecovery.create.selectTemplate" }), value: "" },
                                            ...drTemplates.map(x => {
                                                return { key: x.name, value: x.id };
                                            })
                                        ]}

                                    />
                                ) : ''}

                            </Row>
                            {template.initiateCarriers && template.initiateCarriers.length > 0 && (
                                <Row className="pl-3 pr-3">
                                    <TemplateCarriers carriers={template.initiateCarriers} />
                                </Row>
                            )}
                            <Row>
                                {showDRTemplateLoadingIndicator &&
                                    <LoadingIndicator />}
                                {drTemplates ? (
                                    <DropdownFormInput
                                        id={index}
                                        className="col-lg-12"
                                        labelTranslationId={(index > 0) ? (`${intl.formatMessage({ id: "disasterRecovery.create.revertTemplateTitle" })} ${index + 1}`) : "disasterRecovery.create.revertTemplateTitle"}
                                        value={template.revertTemplateId.toString()}
                                        handleInputChange={(value: string) =>
                                            handleSelectedTemplateChanges({ ...template, revertTemplateId: parseInt(value) }, "revert", index)
                                        }
                                        errorHandler={getErrorHandler(`${nameOf<TemplateGroup>("revertTemplateId")}${index}`)}
                                        options={[
                                            { key: intl.formatMessage({ id: "disasterRecovery.create.selectTemplate" }), value: "" },
                                            ...drTemplates.map(x => {
                                                return { key: x.name, value: x.id };
                                            })
                                        ]}

                                    />
                                ) : ''}

                            </Row>
                            {template.revertCarriers && template.revertCarriers.length > 0 && (
                                <Row className="pl-3 pr-3">
                                    <TemplateCarriers carriers={template.revertCarriers} />
                                </Row>
                            )}
                        </>
                    ))
                    }
                    <Row className="justify-content-between pl-half">
                        <NavLink className="color-blue font-size-14 cursor-pointer" onClick={() => setSelectedTemplates([...selectedTemplates, DefaultTemplateGroup])}>
                            <FormattedMessage id="disasterRecovery.create.addMoreTemplate" />
                        </NavLink>
                    </Row>
                </div>
                <div className="modal-footer justify-content-end">
                    <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                        <FormattedMessage id="disasterRecovery.create.cancelButton" />
                    </Button>
                    <Button color="primary" type="submit" disabled={showLoadingIndicator || showDRTemplateLoadingIndicator || showWorkingTemplateLoadingIndicator}>
                        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                        <FormattedMessage id="disasterRecovery.create.submitButton" />
                    </Button>
                </div>
                {showCarrierLoadingIndicator &&
                    <ModalLoadingIndicator />}

            </Form>
        </Modal>
    );
}

const validateDisasterRecovery = (scenarioName: string, templateGroups: TemplateGroup[]) => {
    const errors: Errors = {};

    if (!scenarioName) {
        AddError(errors, nameOf<RequestAddDisasterRecovery>("name"), "disasterRecovery.create.invalidScenarioName");
    }

    templateGroups.forEach((template: TemplateGroup, index: number) => {
        if (!template.initiateTemplateId || template.initiateTemplateId <= 0) {
            AddError(errors, `${nameOf<TemplateGroup>("initiateTemplateId")}${index}`, "disasterRecovery.create.initiateTemplate");
        }
        if (!template.workingTemplateId || template.workingTemplateId <= 0) {
            AddError(errors, `${nameOf<TemplateGroup>("workingTemplateId")}${index}`, "disasterRecovery.create.workingTemplate");
        }
        if (!template.revertTemplateId || template.revertTemplateId <= 0) {
            AddError(errors, `${nameOf<TemplateGroup>("revertTemplateId")}${index}`, "disasterRecovery.create.revertTemplate");
        }
        if (template.revertTemplateId && template.revertTemplateId > 0 && template.initiateTemplateId && template.initiateTemplateId > 0
            && template.initiateTemplateId === template.revertTemplateId) {
            AddError(errors, `${nameOf<TemplateGroup>("revertTemplateId")}${index}`, "disasterRecovery.create.duplicateTemplate");
        }
    })

    return errors;
};

