import { TableIdentifiers } from "./TableIdentifiers";
import { TableSettings } from "./TableSettings";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { DefaultTableSettings } from "./DefaultTableSettings";
import { ColumnSettings } from "./ColumnSettings";
import {
  UpdateAccountDto,
} from "services/apis/types/account/UpdateAccountDto";

import { UiSettings } from "services/uiSettings/UiSettings";
import { DefaultUserDto, UserDto } from "services/apis/types/account/UserDto";

export const getTableSettings = (tableIdentifier: TableIdentifiers): TableSettings => {
  const userSettings = AppContextAccessor.getAppContext().localStorageInfo.user?.settings?.ui?.tableSettings?.find(
    (x) => x.identifier === tableIdentifier
  );

  return userSettings ?? DefaultTableSettings[tableIdentifier];
};

export const getColumnSettings = (
  tableIdentifier: TableIdentifiers,
  columnIdentifier: string
): ColumnSettings => {
  const tableSettings = getTableSettings(tableIdentifier);
  const defaultColumnSetting = DefaultTableSettings && DefaultTableSettings[tableIdentifier]?.columnSettings?.find(
    (x) => x.identifier === columnIdentifier
  ) as ColumnSettings;

  return (
    tableSettings?.columnSettings?.find((x) => x.identifier === columnIdentifier) ??
    defaultColumnSetting
  );
};

enum UserSettingsStatus {
  NoSettings,
  NoTableSettingsStored,
  TableSettingsNotFound,
  TableSettingsFound
}

export const populateAccountDtoWithUiSettings = (user: UserDto | undefined, tableIdentifier: string, settings: [string, any[]][]): UpdateAccountDto => {

  var userStatus = determineUserSettingsStatus(user, tableIdentifier);
  if (userStatus === UserSettingsStatus.NoSettings) {
    user = DefaultUserDto;
  }

  var updatedUiSettings: UiSettings | undefined = user?.settings?.ui;;

  for (var setting of settings) {
    var settingName = setting[0];
    var settingValues = setting[1];
    updatedUiSettings = createOrUpdateUiSettings(updatedUiSettings, tableIdentifier, settingName, settingValues);
  }

  return prepareAccountDtoObject(user as UserDto, updatedUiSettings as UiSettings);
}

export const createOrUpdateUiSettings = (
  uiSettings: UiSettings | undefined,
  tableIdentifier: string,
  settingName: string,
  settingValue: any
): UiSettings => {

  const uiSettingFound = uiSettings?.tableSettings?.find((x) => x.identifier === tableIdentifier) !== undefined;
  var updatedUiSettings: UiSettings | undefined;

  switch (true) {
    case uiSettings === undefined:
      updatedUiSettings = createUiSettings(tableIdentifier, settingName, settingValue);
      break;

    case !uiSettingFound:
      const newUiSettings: UiSettings = { tableSettings: [] };
      const newTableSetting = { identifier: tableIdentifier };
      newTableSetting[settingName] = settingValue;

      newUiSettings.tableSettings?.push(newTableSetting);

      updatedUiSettings = newUiSettings as UiSettings;
      break;

    case uiSettingFound:
      const updateUiSetting = { ...uiSettings };

      const found = updateUiSetting.tableSettings?.find((x) => x.identifier === tableIdentifier);
      if (found)
        found[settingName] = settingValue;

      updatedUiSettings = updateUiSetting as UiSettings;
      break;
  }

  return updatedUiSettings as UiSettings;
};

const determineUserSettingsStatus = (user?: UserDto, tableIdentifier?: string): UserSettingsStatus => {

  var status: UserSettingsStatus = UserSettingsStatus.NoSettings;

  switch (true) {
    case (user === undefined):
      status = UserSettingsStatus.NoSettings;
      break;

    case (user && !user?.settings?.ui?.tableSettings):
      status = UserSettingsStatus.NoTableSettingsStored;
      break;

    case (user && user.settings?.ui?.tableSettings
      && user?.settings.ui?.tableSettings.find((x) => x.identifier === tableIdentifier) === undefined):
      status = UserSettingsStatus.TableSettingsNotFound;
      break;

    case (user && user.settings?.ui?.tableSettings
      && user.settings.ui?.tableSettings.find((x) => x.identifier === tableIdentifier) !== undefined):
      status = UserSettingsStatus.TableSettingsFound;
      break;
    default:
      throw new Error("Unable to determine table settings status")
  }

  return status;
}

const createUiSettings = (tableIdentifier: string, settingPropertyName: string, settingValue: any): UiSettings => {
  var obj: UiSettings = { tableSettings: [{ identifier: tableIdentifier }] };
  obj.tableSettings[0][settingPropertyName] = settingValue;
  return obj;
}

const prepareAccountDtoObject = (localStorageUser: UserDto, newUiSettings: UiSettings): UpdateAccountDto => {
  return {
    email: localStorageUser.email,
    firstName: localStorageUser.firstName,
    lastName: localStorageUser.lastName,
    phone: localStorageUser.phone,
    username: localStorageUser.username,
    enabled: localStorageUser.enabled,
    requireMultiFactorAuth: localStorageUser.requireMultiFactorAuth,
    sendTextNotifications: localStorageUser.sendTextNotifications,
    // userSettings: {
    //   ui: JSON.stringify(newUiSettings)
    // }
  };
}
