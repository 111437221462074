import queryString from "query-string";
import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { IdentityResponse } from "./types/identity/IdentityResponse";

export const PortControlApi = {
    getToken: async (): Promise<IdentityResponse> => {
        let requestParams = queryString.stringify({
            grant_type: "password",
            username: EnvironmentUtil.oAuthUsername,
            password: EnvironmentUtil.oAuthPassword,
            scope: EnvironmentUtil.oAuthScope,
            // client_id: 'Client.PortControl.Web',
            // client_secret: '3AKIx0pz4Lvm4V3dYw7Afg4vw3j9bZ',
            client_id: EnvironmentUtil.oAuthClientId,
            client_secret: EnvironmentUtil.oAuthClientSecret,
        });

        const response = await fetch(EnvironmentUtil.oAuthIdentityUrl("connect/token"), getRequestInit(requestParams));
        const json = await response.json();

        return json as IdentityResponse;
    }
};

const getRequestInit = (body: string): RequestInit => {
    return {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        },
        body: body
    };
};


