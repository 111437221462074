import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, Button, Row, FormGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CloseButton from "components/framework/modals/CloseButton";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { RequestTollFreeNumber } from "services/apis/types/toll-free-numbers/RequestTollFreeNumber";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { TollFreeNumberContext } from "./TollFreeNumbers";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { ActivityApi } from "services/apis/ActivityApi";
import TollFreeRealBrandModal from "./TollFreeRealBrandModal";
import { getThirtyDaysPriorDate } from "services/util/DateUtil";

type Props = {
    closeModal: () => void;
    tollFreeNumber?: TollFreeNumbersDto;
};



export default function TollFreeQueryUpsertModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [numberList, setNumberList] = useState<string>('');
    const [notes, setNotes] = useState<string>("");
    const [cnam, setCnam] = useState<string>("");
    const [doNotOriginate, setDoNotOriginate] = useState<boolean>(false);
    const [realBrandUpdate, setRealBrandUpdate] = useState<boolean>(false);
    const [dbRealBrandUpdate, setDbRealBrandUpdate] = useState<boolean>(false);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [showTollFreeNumberLoadingIndicator, setShowTollFreeNumberLoadingIndicator] = useState(false);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const tollfreeNumberContext = useContext(TollFreeNumberContext);
    const [addressLine1, setAddressLine1] = useState<string>('');
    const [addressLine2, setAddressLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [zipcode, setZipcode] = useState<string>('');
    const [siteUrl, setSiteUrl] = useState<string>('');
    const [logoUrl, setLogoUrl] = useState<string>('');
    const [showRealBrandLoadingIndicator, setShowRealBrandLoadingIndicator] = useState(false);
    const [showRealBrandModal, setShowRealBrandModal] = useState<boolean>(false);
    const closeModal = useCallback(props.closeModal, []);

    const { tollFreeNumber } = props;

    const fetchTollFreeNumbers = useCallback(() => {
        if (tollFreeNumber) {
            setShowLoadingIndicator(true);
            setShowTollFreeNumberLoadingIndicator(true);

            TollFreeNumbersApi.getTollFreeNumber(tollFreeNumber.number ? tollFreeNumber.number : tollFreeNumber.Number)
                .then((result) => {
                    if (isMounted.current) {
                        // const newGetNumberList: any = result.number;
                        // const resultantGetNumbers = newGetNumberList.replaceAll('-', '$').replaceAll('(', '$').replaceAll(')', '$').replaceAll(' ', '$').replaceAll(',', '$').replaceAll('\t', '$').replaceAll('\n', '$').replaceAll('\r', '$').split('$');

                        //resultantGetNumbers.map((numbers) => {
                        setNumberList(result.number);
                        //});

                        setNotes(result.notes ? result.notes : "");
                        setCnam(result.cnam ? result.cnam.name : "");
                        setAddressLine1(result.cnam && result.cnam.addressLine1 ? result.cnam.addressLine1 : '');
                        setAddressLine2(result.cnam && result.cnam.addressLine2 ? result.cnam.addressLine2 : '');
                        setCity(result.cnam && result.cnam.city ? result.cnam.city : '');
                        setCountry(result.cnam && result.cnam.country ? result.cnam.country : '');
                        setState(result.cnam && result.cnam.state ? result.cnam.state : '');
                        setZipcode(result.cnam && result.cnam.zipcode ? result.cnam.zipcode : '');
                        setSiteUrl(result.cnam && result.cnam.siteUrl ? result.cnam.siteUrl : '');
                        setLogoUrl(result.cnam && result.cnam.logoUrl ? result.cnam.logoUrl : '');
                        setDoNotOriginate(result.cnam && result.doNotOriginate ? result.doNotOriginate : false);
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                        setShowTollFreeNumberLoadingIndicator(false);
                    }
                });
        }
    }, [isMounted, tollFreeNumber]);

    const fetchRealBrand = useCallback(() => {
        if (tollFreeNumber) {
            ActivityApi.getRealBrand(getThirtyDaysPriorDate(), 48)
                .then(results => {
                    if (results.length > 0) {
                        if (results.findIndex(result => result.tollFreeNumbers.includes(tollFreeNumber.number ? tollFreeNumber.number : tollFreeNumber.Number)) > -1) {
                            //setRealBrandUpdate(true);
                            setDbRealBrandUpdate(true);
                        }
                    }
                })
                .catch((error) => handleError(error))
        }
    }, [tollFreeNumber]);

    useEffect(() => {
        if (tollFreeNumber) {
            fetchTollFreeNumbers();
            fetchRealBrand();
        }
    }, [fetchTollFreeNumbers, fetchRealBrand, tollFreeNumber]);

    const getUniqueTollFreeNumbers = () => {
        const newNumberList: any = numberList.trim();
        let resultOfTenDigitsArray: any[] = [];
        let resultantNumberList = newNumberList.replaceAll('\t', '$').replaceAll('\n', '$').replaceAll('\r', '$').split('$');
        if (newNumberList.includes("-") || newNumberList.includes(",") || newNumberList.includes("(") || newNumberList.includes(")") || newNumberList.includes(" ")) {
            // eslint-disable-next-line
            resultOfTenDigitsArray = newNumberList.replace(/\-/g, "").replace(/\,/g, "").replace(/\(/g, "").replace(/\)/g, "").replace(/\ /g, "").match(/.{0,10}/g);
            resultantNumberList = resultOfTenDigitsArray;
        }

        let resultOfCommaRemovalNumberList = resultantNumberList.filter(e => e !== ',');
        let resultOfEmptyRemovalNumberList = resultOfCommaRemovalNumberList.filter(e => e);
        let uniqueResultantNumberList = resultOfEmptyRemovalNumberList.filter((uniqueNumber, index) => {
            return (
                uniqueNumber && resultOfEmptyRemovalNumberList.indexOf(uniqueNumber) === index
            );
        });
        return uniqueResultantNumberList;
    }

    const handleEditTollFree = () => {
        const tollFreeNumberUpdate: any[] = [];
        let cnamRequest: any = {};
        if ((cnam + addressLine1 + addressLine2 + city + state + country + zipcode + siteUrl + logoUrl) === '') {
            cnamRequest = null
        } else {
            cnamRequest = {
                name: cnam.trim(),
                addressLine1: addressLine1.trim(),
                addressLine2: addressLine2.trim(),
                city: city.trim(),
                state: state.trim(),
                country: country.trim(),
                zipcode: zipcode.trim(),
                siteUrl: siteUrl.trim(),
                logoUrl: logoUrl.trim()
            }
        }

        getUniqueTollFreeNumbers().forEach((numbers: any) => {
            tollFreeNumberUpdate.push({
                number: numbers,
                notes,
                cnam: cnamRequest,
                // cnam: cnam ? { name: cnam } : null,
                doNotOriginate,
            })
        })
        TollFreeNumbersApi.edit(tollFreeNumberUpdate)
            .then(() => {
                showInfoNotification(
                    intl.formatMessage({ id: "tollFreeNumber.update.successNotificationMessage" })
                );
                if (realBrandUpdate) 
                    handleRealBrand();
                else 
                    closeModal();
                tollfreeNumberContext.tollFreeNumberActionCallback();
            })
            .catch((error) => {
                handleError(error);
                closeModal();
                if (isMounted.current) {
                    const errorsResult = getFieldErrors(error.fieldErrors);
                    setErrors(errorsResult);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
    }

    const handleRealBrand = () => {
        if (tollFreeNumber) {
            TollFreeNumbersApi.updateRealBrand({ "numbers": [tollFreeNumber.number ? tollFreeNumber.number : tollFreeNumber.Number] })
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "tollFreeNumber.realBrandUpdate.successNotificationMessage" })
                    );
                    closeModal();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                    closeModal();
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
    }

    const handleAddTollFree = () => {
        const tollFreeNumberRequest: any[] = [];
        getUniqueTollFreeNumbers().forEach((numbers) => {
            tollFreeNumberRequest.push({
                number: numbers,
                notes,
                cnam: null,
                doNotOriginate: false,
            })
        })
        TollFreeNumbersApi.add(tollFreeNumberRequest)
            .then(() => {
                showInfoNotification(
                    intl.formatMessage({ id: "tollFreeNumber.create.successNotificationMessage" })
                );
                closeModal();
                tollfreeNumberContext.tollFreeNumberActionCallback();
            })
            .catch((error) => {
                handleError(error);
                if (isMounted.current) {
                    const errorsResult = getFieldErrors(error.fieldErrors);
                    setErrors(errorsResult);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
    }


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const errors = validateTollFreeNumber(numberList.trim(), notes.trim());
        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
                setShowTollFreeNumberLoadingIndicator(false);
            }
            return;
        }
        if (tollFreeNumber) {
            if (realBrandUpdate) {
                setShowRealBrandModal(true);
                return;
            }
            setShowLoadingIndicator(true);
            handleEditTollFree();
            return;
        }
        setShowLoadingIndicator(true);
        handleAddTollFree();
    };

    const updateRealBrand = () => {
        setShowRealBrandLoadingIndicator(true);
        handleEditTollFree();
    }


    return (
        <>
            <Modal className={(tollFreeNumber) ? "modal-dialog-centered modal-lg" : "modal-dialog-centered modal-sm"} isOpen={true} zIndex={2147483648}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id={tollFreeNumber ? "tollFreeNumber.create.updateModalTitle" : "tollFreeNumber.create.createModalTitle"} />
                    </h5>
                    <CloseButton close={props.closeModal} />
                </div>
                {showTollFreeNumberLoadingIndicator ? (
                    <LoadingIndicator />
                ) : (
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className={(tollFreeNumber) ? "modal-body row" : "modal-body"}>
                            <div className={(tollFreeNumber) ? "col-lg-6" : ""}>
                                <Row>
                                    <TextAreaFormInput
                                        labelTranslationId="tollFreeNumber.create.numberList"
                                        rows={4}
                                        value={numberList}
                                        handleInputChange={(value: string) =>
                                            setNumberList(value)
                                        }
                                        className="col-lg-12"
                                        errorHandler={getErrorHandler(nameOf<RequestTollFreeNumber>("number"))}
                                    />
                                </Row>
                                <Row>
                                    <TextAreaFormInput
                                        labelTranslationId="tollFreeNumber.create.notes"
                                        rows={4}
                                        value={notes}
                                        handleInputChange={(value: string) =>
                                            setNotes(value)
                                        }
                                        className="col-lg-12"
                                        errorHandler={getErrorHandler(nameOf<RequestTollFreeNumber>("notes"))}
                                    />
                                </Row>
                                {tollFreeNumber && (
                                    <>
                                        <Row>
                                            <FormGroup className="col-lg-12 mb-0">
                                                <CheckboxFormInput
                                                    className="col-lg-6"
                                                    label={intl.formatMessage({ id: "tollFreeNumber.create.doNotOriginate" })}
                                                    value={"doNotOriginate"}
                                                    checked={doNotOriginate}
                                                    handleInputChange={(value: boolean) =>
                                                        setDoNotOriginate(value)
                                                    }
                                                />
                                            </FormGroup>
                                        </Row>
                                        <Row>
                                            <FormGroup className="col-lg-12">
                                                <CheckboxFormInput
                                                    className="col-lg-6"
                                                    label={intl.formatMessage({ id: "tollFreeNumber.create.realBrandUpdate" })}
                                                    value={"realBrandUpdate"}
                                                    checked={realBrandUpdate || dbRealBrandUpdate}
                                                    disabled={dbRealBrandUpdate}
                                                    handleInputChange={(value: boolean) =>
                                                        setRealBrandUpdate(value)
                                                    }
                                                />
                                            </FormGroup>
                                        </Row>
                                    </>
                                )}
                            </div>
                            <div className={(tollFreeNumber) ? "col-lg-6" : ""}>
                                {tollFreeNumber ? (<>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.cnam"
                                            value={cnam}
                                            handleInputChange={(value: string) =>
                                                setCnam(value)
                                            }
                                            errorHandler={getErrorHandler(nameOf<RequestTollFreeNumber>("cnam"))}
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.addressLine1"
                                            value={addressLine1}
                                            handleInputChange={(value: string) =>
                                                setAddressLine1(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.addressLine2"
                                            value={addressLine2}
                                            handleInputChange={(value: string) =>
                                                setAddressLine2(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.city"
                                            value={city}
                                            handleInputChange={(value: string) =>
                                                setCity(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.state"
                                            value={state}
                                            handleInputChange={(value: string) =>
                                                setState(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.zipcode"
                                            value={zipcode}
                                            handleInputChange={(value: string) =>
                                                setZipcode(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.country"
                                            value={country}
                                            handleInputChange={(value: string) =>
                                                setCountry(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.siteURL"
                                            value={siteUrl}
                                            handleInputChange={(value: string) =>
                                                setSiteUrl(value)
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        <TextFormInput
                                            formGroupClassName="col-lg-12"
                                            labelTranslationId="tollFreeNumber.create.logoURL"
                                            value={logoUrl}
                                            handleInputChange={(value: string) =>
                                                setLogoUrl(value)
                                            }
                                        />
                                    </Row>

                                </>) : ('')}
                            </div>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                                <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                            </Button>
                            <Button color="primary" type="submit" disabled={showLoadingIndicator || showTollFreeNumberLoadingIndicator}>
                                {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                <FormattedMessage id="disasterRecovery.create.submitButton" />
                            </Button>
                        </div>
                    </Form>
                )
                }

            </Modal>
            {realBrandUpdate && showRealBrandModal && <TollFreeRealBrandModal closeRealBrandModal={() => setShowRealBrandModal(false)} onSubmit={() => updateRealBrand()} showLoadingIndicator={showRealBrandLoadingIndicator} />}
        </>
    );
}

const validateTollFreeNumber = (numberList: string, notes: string) => {
    const errors: Errors = {};

    if (!numberList) {
        AddError(errors, nameOf<RequestTollFreeNumber>("number"), "tollFreeNumber.create.invalidNumberList");
    }
    // if (!notes) {
    //     AddError(errors, nameOf<RequestTollFreeNumber>("notes"), "tollFreeNumber.create.invalidNotes");
    // }
    return errors;
};
