import { ClientApi } from "services/apis/ClientApi";
import { ClientDto } from "services/apis/types/client/ClientDto";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";

export const ClientService = {
    getSelectedClientInfo: async (): Promise<boolean> => {
        if (AppContextAccessor.getAppContext().selectedClient) {

            const client: ClientDto = await ClientApi.info();

            if (!client || !client.id) {
                return false;
            }

            AppContextAccessor.setAppContext((appContext) => {
                return {
                    ...appContext,
                    client: client
                };
            });


            return true;
        }

        return false;
    },
};