import React, { useState, useCallback, useContext, useEffect } from "react";
import { Modal, Button, Row, Col, ListGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CloseButton from "components/framework/modals/CloseButton";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { TemplateApi } from "services/apis/TemplateApi";
import { WorkingTemplateInfo } from "services/apis/types/template/WorkingTemplateInfo";
import { PoolType, RequestTollFreeReserve, ReserveType } from "services/apis/types/toll-free-numbers/RequestTollFreeReserve";
import { AppContext } from "services/appContext/AppContext";
import { TollFreeProvisionDto } from "services/apis/types/toll-free-numbers/TollFreeProvisionDto";
import TextFormError from "components/framework/forms/TextFormError";
import { TollFreeSearchNumberDto } from "services/apis/types/toll-free-numbers/TollFreeSearchNumberDto";

type Props = {
    closeModal: () => void;
};

const tollFreePatternNPA = [
    { "id": "***", "name": "Any NPA" },
    { "id": "800", "name": "800" },
    { "id": "888", "name": "888" },
    { "id": "877", "name": "877" },
    { "id": "866", "name": "866" },
    { "id": "855", "name": "855" },
    { "id": "844", "name": "844" },
    { "id": "833", "name": "833" },
];

export default function TollFreeQueryReserveModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [reserveNotes, setReserveNotes] = useState<string>("");
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [showLoadingInquiryIndicator, setShowLoadingInquiryIndicator] = useState(false);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const [selectedWorkingTemplateId, setSelectedWorkingTemplateId] = useState<number>();
    const [selectedTollFreePatternNPAId, setSelectedTollFreePatternNPAId] = useState<string>("***");
    const [selectedTollFreePattern1, setSelectedTollFreePattern1] = useState<string>("***");
    const [selectedTollFreePattern2, setSelectedTollFreePattern2] = useState<string>("****");
    const [showWorkingTemplateLoadingIndicator, setShowWorkingTemplateLoadingIndicator] = useState(false);
    const [workingTemplates, setWorkingTemplates] = useState<Array<WorkingTemplateInfo>>([]);
    const [provisionIds, setProvisionIds] = useState<Array<TollFreeProvisionDto>>([]);
    const [showProvisionLoadingIndicator, setShowProvisionLoadingIndicator] = useState(false);
    const [showTollFreeNumberListLoadingIndicator, setShowTollFreeNumberListLoadingIndicator] = useState(false);
    const [showTollFreeNumberInquiryListLoadingIndicator, setShowTollFreeNumberInquiryListLoadingIndicator] = useState(false);
    const [searchTollFreeNumberList, setSearchTollFreeNumberList] = useState<Array<TollFreeSearchNumberDto>>([]);
    const [searchTollFreeNumberInquireList, setSearchTollFreeNumberInquireList] = useState<Array<TollFreeSearchNumberDto>>([]);
    const { appContext } = useContext(AppContext);
    const [tollFreeErrorMessage, setTollFreeErrorMessage] = useState<boolean>(false);
    const [tollFreeInquiryErrorMessage, setTollFreeInquiryErrorMessage] = useState<boolean>(false);
    const [errorLimitMessage, setErrorLimitMessage] = useState<boolean>(false);
    const [inquireNotes, setInquireNotes] = useState<string>("");
    const [selectedAllPool, setSelectedAllPool] = useState<boolean>(false);
    const [selectedAllPrimePool, setSelectedAllPrimePool] = useState<boolean>(false);

    const handleChangeSelectedTollFrees = useCallback((selectedNumber: any, index: any) => {
        if (selectedNumber.status && searchTollFreeNumberList.filter(x => x.status).length >= 10) {
            setErrorLimitMessage(true);
            return;
        }
        setSelectedAllPool(false);
        const newCheckedSearchNumbers = [...searchTollFreeNumberList];
        newCheckedSearchNumbers[index] = selectedNumber;
        if (selectedNumber.status === true) {
            getErrorHandler(nameOf<RequestTollFreeReserve>("numbers")).clear();
        }
        setSearchTollFreeNumberList(newCheckedSearchNumbers);
    }, [searchTollFreeNumberList, getErrorHandler]);

    useEffect(() => {
        if (errorLimitMessage) {
            const timer = setTimeout(() => setErrorLimitMessage(false), 4000);
            return () => clearTimeout(timer);
        }
    }, [errorLimitMessage]);

    const handleChangeSelectedPrimeTollFrees = useCallback((selectedPrimeNumber: any, index: any) => {
        if (selectedPrimeNumber.status && searchTollFreeNumberInquireList.filter(x => x.status).length >= 10) {
            setErrorLimitMessage(true);
            return;
        }
        setSelectedAllPrimePool(false);
        const newCheckedSearchNumbers = [...searchTollFreeNumberInquireList];
        newCheckedSearchNumbers[index] = selectedPrimeNumber;
        if (selectedPrimeNumber.status === true) {
            getErrorHandler("inquireNumbers").clear();
        }
        setSearchTollFreeNumberInquireList(newCheckedSearchNumbers);
    }, [searchTollFreeNumberInquireList, getErrorHandler]);

    const handleCheckAllSOMOSPool = (somosPool: boolean) => {
        if (somosPool && searchTollFreeNumberList.length > 10) {
            setErrorLimitMessage(true);
            return;
        }
        setSelectedAllPool(somosPool);
        if (searchTollFreeNumberList.length > 0) {
            searchTollFreeNumberList.forEach((numbers) => {
                numbers.status = somosPool
            })
        }
    }

    const handleCheckAllPrimePool = (primePool: boolean) => {
        if (primePool && searchTollFreeNumberInquireList.length > 10) {
            setErrorLimitMessage(true);
            return;
        }
        setSelectedAllPrimePool(primePool);
        if (searchTollFreeNumberInquireList.length > 0) {
            searchTollFreeNumberInquireList.forEach((numbers) => {
                numbers.status = primePool
            })
        }
    }

    const closeModal = useCallback(props.closeModal, []);

    const fetchWorkingTemplates = useCallback(() => {
        setShowWorkingTemplateLoadingIndicator(true)
        TemplateApi.list(false, true, true, 1, 10000)
            .then((result) => {
                if (isMounted.current) {
                    setWorkingTemplates(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowWorkingTemplateLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchProvision = useCallback(() => {
        setShowProvisionLoadingIndicator(true)
        TollFreeNumbersApi.provision(appContext.selectedClient?.clientId)
            .then((result) => {
                if (isMounted.current) {
                    setProvisionIds(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowProvisionLoadingIndicator(false);
                }
            });

    }, [isMounted, appContext.selectedClient]);
    useEffect(() => {
        fetchWorkingTemplates();
        fetchProvision();
    }, [fetchWorkingTemplates, fetchProvision]);

    const handleSubmitReserve = () => {
        setShowLoadingIndicator(true);
        const errorsReserve = validateTollFreeNumberPatternReserve(searchTollFreeNumberList)
        if (HasErrors(errorsReserve)) {
            setErrors(errorsReserve);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
                setShowWorkingTemplateLoadingIndicator(false);
                setShowProvisionLoadingIndicator(false);
            }
        } else {
            let provisionIdArray: any[] = [];
            provisionIds.forEach((provisionObj) => {
                if (provisionObj.status) {
                    provisionIdArray.push(provisionObj.id);
                }
            });
            let tollFreeNumberBulkQueryRequest;
            let resultantSearchNumbers: any[] = [];
            searchTollFreeNumberList.forEach((bulkNumbers) => {
                if (bulkNumbers.status === true) {
                    resultantSearchNumbers.push(bulkNumbers.number);

                }
            });

            tollFreeNumberBulkQueryRequest = {
                numbers: resultantSearchNumbers,
                templateId: selectedWorkingTemplateId,
                provisionIds: provisionIdArray,
                notes: reserveNotes,
                reserveType: ReserveType.Pattern,
                //quantity: parseInt(quantity),
            };
            TollFreeNumbersApi.reserve(tollFreeNumberBulkQueryRequest)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "tollFreeNumber.query.successNotificationMessage" })
                    );
                    closeModal();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                    closeModal();
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
    };

    const handleSelectedNPAChanges = (value: string) => {
        setSelectedTollFreePatternNPAId(value);
        const finalPattern = value + selectedTollFreePattern1 + selectedTollFreePattern2;
        queryTollFrees(finalPattern);
        queryTollFreesInquire(finalPattern);
    }

    const handleSelectedTollFreePattern1Change = (value: string) => {
        setSelectedTollFreePattern1(value);
        const finalPattern = selectedTollFreePatternNPAId + value + selectedTollFreePattern2;
        queryTollFrees(finalPattern);
        queryTollFreesInquire(finalPattern);
    }

    const handleSelectedTollFreePattern2Change = (value: string) => {
        setSelectedTollFreePattern2(value);
        const finalPattern = selectedTollFreePatternNPAId + selectedTollFreePattern1 + value;
        queryTollFrees(finalPattern);
        queryTollFreesInquire(finalPattern);
    }

    const handleSearchMoreResultForSomos = () => {
        const finalPattern = selectedTollFreePatternNPAId + selectedTollFreePattern1 + selectedTollFreePattern2;
        queryTollFrees(finalPattern);
    };

    const handleSearchMoreResultPrime = () => {
        const finalPattern = selectedTollFreePatternNPAId + selectedTollFreePattern1 + selectedTollFreePattern2;
        queryTollFreesInquire(finalPattern);
    }

    const queryTollFrees = (finalPattern: string) => {
        if (finalPattern.length === 10 && finalPattern !== "**********") {
            setSelectedAllPool(false);
            let searchTollFreeNumbers = {
                pattern: finalPattern,
                poolType: PoolType.Somos
            }
            setShowTollFreeNumberListLoadingIndicator(true);
            TollFreeNumbersApi.searchReserve(searchTollFreeNumbers)
                .then((result) => {
                    if (isMounted.current) {
                        if (!result.length) {
                            setTollFreeErrorMessage(true);
                        }
                        else {
                            setTollFreeErrorMessage(false);
                            result.forEach((resultObj) => {
                                resultObj.status = false;
                            });
                            setSearchTollFreeNumberList((prevState) => prevState ? [...(prevState.filter(x => x.status)), result].flat(1) : result);
                        }
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowTollFreeNumberListLoadingIndicator(false);
                    }
                });
        }
    }

    const queryTollFreesInquire = (finalPattern: string) => {
        if (finalPattern.length === 10 && finalPattern !== "**********") {
            var searchTollFreeNumbersInquire = {
                pattern: finalPattern,
                poolType: PoolType.Prime
            }
            setShowTollFreeNumberInquiryListLoadingIndicator(true);
            TollFreeNumbersApi.searchReserve(searchTollFreeNumbersInquire)
                .then((result) => {
                    if (isMounted.current) {
                        if (result.length > 0) {
                            setTollFreeInquiryErrorMessage(false);
                            result.forEach((resultObj) => {
                                resultObj.status = false;
                            });
                            setSearchTollFreeNumberInquireList((prevState) => prevState ? [...(prevState.filter(x => x.status)), result].flat(1) : result);;
                        }
                        if (!result.length) {
                            setTollFreeInquiryErrorMessage(true);
                        }
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowTollFreeNumberInquiryListLoadingIndicator(false);
                    }
                });
        }
    }

    const handleChangeProvision = (provisionList: TollFreeProvisionDto, index: number) => {
        const newCheckedProvision = [...provisionIds];
        newCheckedProvision[index] = provisionList;
        setProvisionIds(newCheckedProvision);
    }

    const handleSubmitInquire = () => {
        setShowLoadingInquiryIndicator(true);
        const errorsInquire = validateTollFreeNumberInquirePattern(searchTollFreeNumberInquireList);

        if (HasErrors(errorsInquire)) {
            setErrors(errorsInquire);
            if (isMounted.current) {
                setShowLoadingInquiryIndicator(false);
            }
        } else {
            let tollFreeNumberBulkQueryRequestInquire;

            const resultantSearchNumbers: any[] = [];
            searchTollFreeNumberInquireList.forEach((bulkNumbers) => {
                if (bulkNumbers.status === true) {
                    resultantSearchNumbers.push(bulkNumbers.number);

                }
            })

            tollFreeNumberBulkQueryRequestInquire = {
                numbers: resultantSearchNumbers,
                notes: inquireNotes,
            };

            TollFreeNumbersApi.inquire(tollFreeNumberBulkQueryRequestInquire)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "tollFreeNumber.inquire.successNotificationMessage" })
                    );
                    closeModal();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                    closeModal();
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingInquiryIndicator(false);
                    }
                });
        }
    };

    return (
        <Modal className={((searchTollFreeNumberList.length > 0 || searchTollFreeNumberInquireList.length) > 0) ? "modal-dialog-centered modal-lg" : "modal-dialog-centered modal-sm"} isOpen={true} zIndex={2147483648}>
            <div className="modal-header">
                <h5 className="modal-title">
                    <FormattedMessage id={"tollFreeNumber.bulkQuery.createTitle"} />
                </h5>
                <CloseButton close={props.closeModal} />
            </div>
            <div>
                {tollFreeErrorMessage ? (
                    <div className="d-flex alert align-items-center alert-info mb-2" role="alert">
                        <span className="alert-icon"><i className="fa fa-exclamation-triangle fa-2x"></i></span>
                        <span className="alert-text"><FormattedMessage id="tollFree.bulkQuery.message1" /></span>
                    </div>) : (<></>)}
                {tollFreeInquiryErrorMessage ? (
                    <div className="d-flex align-items-center alert alert-info mb-1" role="alert">
                        <span className="alert-icon"><i className="fa fa-exclamation-triangle fa-2x"></i></span>
                        <span className="alert-text"><FormattedMessage id="tollFree.bulkQuery.message2" /></span>
                    </div>) : (<></>)}
                {(errorLimitMessage) ? (
                    <div className="d-flex align-items-center alert alert-danger mb-1" role="alert">
                        <span className="alert-icon"><i className="fa fa-exclamation-triangle fa-2x"></i></span>
                        <span className="alert-text"><FormattedMessage id="tollfree.report.errorNotificationMessageForValidSOMOSPool" /></span>
                    </div>) : (<></>)}
                <div className={(!tollFreeErrorMessage && searchTollFreeNumberList.length > 0) || (!tollFreeInquiryErrorMessage && searchTollFreeNumberInquireList.length > 0) ? "modal-body row" : "modal-body"}>
                    <div className={(!tollFreeErrorMessage && searchTollFreeNumberList.length > 0) || (!tollFreeInquiryErrorMessage && searchTollFreeNumberInquireList.length > 0) ? "col-lg-4" : ""}>
                        <Row>
                            {(tollFreePatternNPA.length > 0) ? (
                                <DropdownFormInput
                                    id={0}
                                    className="col-lg-12"
                                    labelTranslationId={"tollFree.create.pattern"}
                                    value={selectedTollFreePatternNPAId}
                                    handleInputChange={(value: string) =>
                                        handleSelectedNPAChanges(value)
                                    }
                                    options={[
                                        ...tollFreePatternNPA.map(x => {
                                            return { key: x.name, value: x.id };
                                        })
                                    ]}
                                />
                            ) : ''}
                        </Row>
                        <Row className="sub-row">
                            <Col className="pr-0">
                                <TextFormInput
                                    value={selectedTollFreePattern1}
                                    maxLength={3}
                                    handleInputChange={(value: string) =>
                                        handleSelectedTollFreePattern1Change(value)
                                    }
                                />
                            </Col>
                            <Col>
                                <TextFormInput
                                    value={selectedTollFreePattern2}
                                    maxLength={4}
                                    handleInputChange={(value: string) =>
                                        handleSelectedTollFreePattern2Change(value)
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            {showWorkingTemplateLoadingIndicator &&
                                <LoadingIndicator />}

                            {workingTemplates.length > 0 ? (
                                <DropdownFormInput
                                    id={0}
                                    className="col-lg-12"
                                    labelTranslationId={"disasterRecovery.create.workingTemplateTitle"}
                                    value={selectedWorkingTemplateId ? selectedWorkingTemplateId.toString() : ""}
                                    handleInputChange={(value: string) =>
                                        setSelectedWorkingTemplateId(parseInt(value))
                                    }
                                    options={[
                                        { key: intl.formatMessage({ id: "disasterRecovery.create.selectTemplate" }), value: "" },
                                        ...workingTemplates.map(x => {
                                            return { key: x.name, value: x.id };
                                        })
                                    ]}

                                />
                            ) : ''}
                        </Row>
                        <div>
                            {showProvisionLoadingIndicator &&
                                <LoadingIndicator />}
                            {provisionIds && provisionIds.map((element, index) => (
                                <>
                                    <label className="form-control-label"><FormattedMessage id={"tollFreeNumber.bulkQuery.provision"} /></label>
                                    <div className="col-lg-12">
                                        <CheckboxFormInput
                                            className="col-lg-10 checkbox-settings-sub pl-3"
                                            label={`${element.carrierType} - ${element.name}`}
                                            value={(element.id).toString()}
                                            checked={element.status}
                                            handleInputChange={(value: boolean) =>
                                                handleChangeProvision({ ...element, status: value }, index)
                                            }
                                        >
                                        </CheckboxFormInput>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    <div className={(!tollFreeErrorMessage && searchTollFreeNumberList.length > 0) || (!tollFreeInquiryErrorMessage && searchTollFreeNumberInquireList.length > 0) ? "col-lg-4" : ""}>
                        {showTollFreeNumberListLoadingIndicator &&
                            <LoadingIndicator />}
                        {!showTollFreeNumberListLoadingIndicator && !tollFreeErrorMessage && searchTollFreeNumberList.length > 0 ? (
                            <>
                                <ListGroup as="ul">
                                    <CheckboxFormInput
                                        className="col-lg-12 checkbox-header"
                                        label={intl.formatMessage({ id: "tollFreeNumber.bulkQuery.searchList" })}
                                        value={"tollFreeNumber.bulkQuery.searchList"}
                                        checked={selectedAllPool}
                                        handleInputChange={(value) => {
                                            handleCheckAllSOMOSPool(value)
                                        }
                                        }
                                    />
                                    {searchTollFreeNumberList.length > 0 && searchTollFreeNumberList.map((numbers, index) => (
                                        <CheckboxFormInput
                                            className="col-lg-12 checkbox-settings-sub"
                                            label={numbers.number.toString()}
                                            value={numbers.number.toString()}
                                            checked={numbers.status}
                                            handleInputChange={(value: boolean) =>
                                                handleChangeSelectedTollFrees({ ...numbers, status: value }, index)
                                            }
                                        />
                                    ))}
                                </ListGroup>
                                {searchTollFreeNumberList.length >= 10 &&
                                    <Button color="warning" size="sm" onClick={handleSearchMoreResultForSomos} disabled={showLoadingIndicator}>
                                        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                        <FormattedMessage id="tollfree.create.moreResultButton" />
                                    </Button>
                                }
                                <ListGroup as="ul">
                                    <TextAreaFormInput
                                        labelTranslationId="tollFreeNumber.create.reserveNotes"
                                        rows={4}
                                        value={reserveNotes}
                                        handleInputChange={(value: string) =>
                                            setReserveNotes(value)
                                        }
                                        className="col-lg-12 padding-card-col mt-2"
                                    />
                                </ListGroup>
                                <Button color="primary" onClick={handleSubmitReserve} disabled={showLoadingIndicator || showLoadingInquiryIndicator}>
                                    {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                    <FormattedMessage id="disasterRecovery.create.submitButton" />
                                </Button>
                            </>
                        ) : ('')}


                        <TextFormError errorHandler={getErrorHandler(nameOf<RequestTollFreeReserve>("numbers"))}></TextFormError>
                    </div>
                    <div className={((searchTollFreeNumberList.length > 0 || searchTollFreeNumberInquireList.length) > 0) ? "col-lg-4" : ""}>
                        {showTollFreeNumberInquiryListLoadingIndicator &&
                            <LoadingIndicator />}
                        {!showTollFreeNumberInquiryListLoadingIndicator && !tollFreeInquiryErrorMessage && searchTollFreeNumberInquireList.length > 0 ? (
                            <>
                                <ListGroup as="ul">
                                    {/* <TollFreePoolReactTable
                                        data={searchTollFreeNumberInquireList}
                                        columns={inquiryColumns}
                                        handleCheckAllPool={InquiryhandleCheckAllPool}
                                        isCheckAllPool={isCheckAllPoolInquiry}
                                        header='tollFreeNumber.inquiry.searchList'
                                    /> */}
                                    <CheckboxFormInput
                                        className="col-lg-12 checkbox-header"
                                        label={intl.formatMessage({ id: "tollFreeNumber.inquiry.searchList" })}
                                        value={"tollFreeNumber.inquiry.searchList"}
                                        checked={selectedAllPrimePool}
                                        handleInputChange={(value) => {
                                            handleCheckAllPrimePool(value)
                                        }
                                        }
                                    />
                                    {searchTollFreeNumberInquireList.length > 0 && searchTollFreeNumberInquireList.map((numbers, index) => (
                                        <CheckboxFormInput
                                            className="col-lg-12 checkbox-settings-sub"
                                            label={numbers.number.toString()}
                                            value={numbers.number.toString()}
                                            checked={numbers.status}
                                            handleInputChange={(value: boolean) =>
                                                handleChangeSelectedPrimeTollFrees({ ...numbers, status: value }, index)
                                            }
                                        />
                                    ))}
                                </ListGroup>
                                {searchTollFreeNumberInquireList.length >= 10 &&
                                    <Button color="warning" size="sm" onClick={handleSearchMoreResultPrime} disabled={showLoadingInquiryIndicator}>
                                        {showLoadingInquiryIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                        <FormattedMessage id="tollfree.create.moreResultButton" />
                                    </Button>}
                                <ListGroup as="ul">
                                    <TextAreaFormInput
                                        labelTranslationId="tollFreeNumber.create.inquireNotes"
                                        rows={4}
                                        value={inquireNotes}
                                        handleInputChange={(value: string) =>
                                            setInquireNotes(value)
                                        }
                                        className="col-lg-12 padding-card-col mt-2"
                                    />
                                </ListGroup>
                                <Button color="primary" onClick={handleSubmitInquire} disabled={showLoadingIndicator || showLoadingInquiryIndicator}>
                                    {showLoadingInquiryIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                    <FormattedMessage id="tollFreeNumber.inquire.inquireButton" />
                                </Button>
                            </>
                        ) : ('')}
                        <TextFormError errorHandler={getErrorHandler(("inquireNumbers"))}></TextFormError>
                    </div>
                </div>

                <div className="modal-footer justify-content-end">
                    <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                        <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                    </Button>
                </div>
            </div>
        </Modal>
    );
}


const validateTollFreeNumberPatternReserve = (searchTollFreeNumberList) => {
    const errors: Errors = {};
    const isFound = searchTollFreeNumberList.some(element => {
        if (element.status === true) {
            return true;
        }
        return false;
    });

    if (!isFound) {
        AddError(errors, nameOf<RequestTollFreeReserve>("numbers"), "tollFreeNumber.create.invalidNumberCheckbox");
    }
    // if (!quantity) {
    //     AddError(errors, nameOf<RequestTollFreeReserve>("quantity"), "tollFreeNumber.create.invalidQuantity");
    // }
    return errors;
};




const validateTollFreeNumberInquirePattern = (searchTollFreeNumberInquireList) => {
    const errors: Errors = {};
    const isFound = searchTollFreeNumberInquireList.some(element => {
        if (element.status === true) {
            return true;
        }
        return false;
    });
    if (!isFound) {
        AddError(errors, ("inquireNumbers"), "tollFreeNumber.create.invalidNumberCheckbox");
    }

    return errors;
};

/*const getColumns = (intl: IntlShape, handleChangeSearchNumber, searchTollFreeNumberList) => [
    {
        accessor: "number",
        className: "text-break",
        Cell: (cell: any) => (
            <TollFreeNumbersPool
                tollFreeNumber={cell.cell.row.original}
                handleInputChange={handleChangeSearchNumber}
            />
        ),
    }
];*/