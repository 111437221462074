import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useIntl, IntlShape, FormattedMessage } from "react-intl";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { handleError } from "services/util/ApiUtil";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import { getValueOrEmpty } from "services/util/StringUtil";
import CloseButton from "components/framework/modals/CloseButton";
import { Modal } from "reactstrap";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { TollFreeHistoryDto } from "services/apis/types/toll-free-numbers/TollFreeHistoryDto";
import HistoryReactTable from "./HistoryReactTable";

type Props = {
  tollFreeNumber: TollFreeNumbersDto;
  //props.phoneNumber
  closeModal: () => void;
};

export default function TollFreeHistoryModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [dataTableData, setDataTableData] = useState<Array<TollFreeHistoryDto>>([]);
  const columns = useMemo(() => getColumns(intl), [intl]);
  const isMounted = useIsMounted();
  //const closeModal = useCallback(props.closeModal, []);

  const fetchTollFreeNumbersHistory = useCallback(() => {
    TollFreeNumbersApi.getHistory(((props.tollFreeNumber && props.tollFreeNumber.number) ? props.tollFreeNumber.number : props.tollFreeNumber.Number))
      .then((result) => {
        if (isMounted) {
          setDataTableData(result);
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
          setShowLoadingIndicator(false);
        }
      });
  }, [isMounted, props.tollFreeNumber]);

  useEffect(() => {
    setShowLoadingIndicator(true);
    fetchTollFreeNumbersHistory();
  }, [fetchTollFreeNumbersHistory]);



  return (
    <Modal className="modal-dialog-centered modal-history" isOpen={true} zIndex={2147483648}>
      <div className="modal-header">
        <h5 className="modal-title">
          <FormattedMessage id={"tollfree.table.actions.history"} />
        </h5>
        <CloseButton close={props.closeModal} />
      </div>

      {showLoadingIndicator ? (
        <LoadingIndicator />
      ) : (
        <HistoryReactTable
          title={props.tollFreeNumber.number ? props.tollFreeNumber.number : props.tollFreeNumber.Number}
          data={dataTableData}
          columns={columns}
          showExport={false}
          showDownload={true}
        />
      )}
    </Modal>
  );
}

const getColumns = (intl: IntlShape) => [
  {
    Header: intl.formatMessage({ id: "tollfree.table.description" }),
    accessor: "description",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value),
  },
  {
    Header: intl.formatMessage({ id: "activities.table.modifyDate" }),
    accessor: "activityDate",
    Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />,
  },
  {
    Header: intl.formatMessage({ id: "activities.table.modifyName" }),
    accessor: "insertName",
    Cell: (cell: any) => getValueOrEmpty(cell.cell.value),
  },
];

// function GetNumber()
// {
//     var number = txtNumber.Text.ReplaceMany(("-", ""), ("(", ""), (")", ""), (" ", ""), ("\t", ""));
//     if (number == "")
//         //throw new ApplicationException("Toll-Free number is required.");

//     return number;
// }
