import React, { useState, useContext, FormEvent, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { HomeContext } from "components/home/Home";
import TextFormInput from "components/framework/forms/TextFormInput";
import { Button, Form, Modal, Row } from "reactstrap";
import CloseButton from "components/framework/modals/CloseButton";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { handleError } from "services/util/ApiUtil";
import { MapApi } from "services/apis/MapApi";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { useErrors } from "services/customHooks/useErrors";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";

type Props = {
    closeModal: () => void;
};

export default function OutageConfirmationModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [zipcode, setZipcode] = useState<string>('');
    const homeContext = useContext(HomeContext);
    const isMounted = useIsMounted();
    const intl = useIntl();
    const [showCarrierLoadingIndicator, setShowCarrierLoadingIndicator] = useState<boolean>(false);
    const [carriers, setCarriers] = useState<Array<CarrierDto>>([]);
    const [selectedCarrierId, setSelectedCarrierId] = useState<number>(0);
    const closeModal = useCallback(props.closeModal, []);
    const [notes, setNotes] = useState<string>('');

    const fetchCarriers = useCallback(() => {
        setShowCarrierLoadingIndicator(true)
        MapApi.getCarrier()
            .then((result) => {
                if (isMounted.current) {
                    setCarriers(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowCarrierLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    useEffect(() => {
        fetchCarriers();
    }, [fetchCarriers]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setShowCarrierLoadingIndicator(true);
        const errors = validateOutageDetails(zipcode, selectedCarrierId);
        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowCarrierLoadingIndicator(false);
            }
        } else {
            const requestOutage = {
                carrierId: selectedCarrierId,
                zipCode: zipcode,
                notes
            }
            MapApi.addCarrier(requestOutage)
                .then(() => {
                    if (isMounted.current) {
                        showInfoNotification(
                            intl.formatMessage({ id: "map.details.successNotificationMessage" })
                        );
                        closeModal();
                        homeContext.disasterRecoveryActionCallback();
                        homeContext.activityActionCallback();
                        homeContext.confirmationZipcodeActionCallback();
                    }
                })
                .catch((error) => handleError(error))
                .finally(() => {
                    if (isMounted.current) {
                        setShowCarrierLoadingIndicator(false);
                    }
                });
        }
    };


    return (
        <>
            <Modal className="modal-dialog-centered modal-sm" isOpen={true} zIndex={2147483648}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id={"map.modalTitle"} />
                    </h5>
                    <CloseButton close={props.closeModal} />
                </div>
                <Form onSubmit={(e) => handleSubmit(e)}>
                    <div className="modal-body">
                        <div className="form-control-label modal-title mb-12">
                            <FormattedMessage
                                id="map.details"
                            />
                        </div>
                        <Row>
                            {showCarrierLoadingIndicator &&
                                <LoadingIndicator />}

                            {carriers.length > 0 ? (
                                <DropdownFormInput
                                    id={0}
                                    className="col-lg-12"
                                    labelTranslationId={"map.carrierTitle"}
                                    value={selectedCarrierId.toString()}
                                    handleInputChange={(value: string) =>
                                        setSelectedCarrierId(parseInt(value))
                                    }
                                    errorHandler={getErrorHandler(`carrierId`)}
                                    options={[
                                        { key: intl.formatMessage({ id: "map.selectCarrier" }), value: "" },
                                        ...carriers.map(x => {
                                            return { key: `${x.cic} - ${x.name}`, value: (x.id).toString() };
                                        })
                                    ]}

                                />
                            ) : ''}
                        </Row>
                        <Row>
                            <TextFormInput
                                formGroupClassName="col-lg-12"
                                labelTranslationId="map.zipCode"
                                value={zipcode}
                                errorHandler={getErrorHandler(`zipcode`)}
                                handleInputChange={(value: string) =>
                                    setZipcode(value)
                                }
                            />
                        </Row>
                        <Row>
                            <TextAreaFormInput
                                labelTranslationId="tollFreeNumber.create.notes"
                                rows={4}
                                value={notes}
                                handleInputChange={(value: string) =>
                                    setNotes(value)
                                }
                                className="col-lg-12"
                                errorHandler={getErrorHandler(`notes`)}
                            />
                        </Row>
                    </div>
                    <div className="modal-footer justify-content-end">
                        <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                            <FormattedMessage id="disasterRecovery.create.cancelButton" />
                        </Button>
                        <Button color="primary" type="submit" disabled={showCarrierLoadingIndicator}>
                            {showCarrierLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                            <FormattedMessage id="map.submit" />
                        </Button>
                    </div>

                </Form>
            </Modal>
        </>
    );
}

const validateOutageDetails = (zipcode: string, selectedCarrierId: number) => {
    const errors: Errors = {};
    if (selectedCarrierId <= 0) {
        AddError(errors, `carrierId`, "map.error.carrierId");
    }
    if (!zipcode) {
        AddError(errors, `zipcode`, "map.error.zipcode");
    }
    return errors;
};