import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/fontawesome/css/all.min.css";
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";

import App from "App";
import { Auth0Provider } from "@auth0/auth0-react";

// ReactDOM.render(<App />, document.getElementById("root"));
ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN_ID || ""}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
        redirectUri={`${window.location.origin}/#/callback`}
        audience={process.env.REACT_APP_API_URL}
    >
        <App />
    </Auth0Provider>,
    document.getElementById("root")
);