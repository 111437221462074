import React from "react";
import {
    Container,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import SettingsHeader from "./SettingsHeader";
import EnableNotifications from "./EnableNotifications";


export default function Settings() {
    return (
        <>
            <BreadcrumbHeader>
                <FormattedMessage id="sidebar.settings" />
            </BreadcrumbHeader>
            <SettingsHeader />
            <Container fluid className="mb-5">
                <EnableNotifications />
            </Container>
        </>
    );
}
