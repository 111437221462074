export type ContactInformation = {
  lnpContact: string;
  lnpEmail: string;
  lnpPhoneNumber: string;
  lnpMethod: string;
  lnpSpeed: string;
  lnpTppLocation: string;
  csrLocation: string;
  lsrLocation: string;
  name: string;
  businessHours: string;
  residentialRules: string;
  businessRules: string;
  notes: string;
  firstLevelSupport: string;
  firstLevelSupportEmail: string;
  firstLevelSupportPhoneNumber: string;
  secondLevelSupport: string;
  secondLevelSupportEmail: string;
  secondLevelSupportPhoneNumber: string;
  repairCenter: string;
  repairCenterEmail: string;
  verifiedDate: Date | null;
};

export const DefaultContactInformation: ContactInformation = {
  lnpContact: "",
  lnpEmail: "",
  lnpPhoneNumber: "",
  lnpMethod: "",
  lnpSpeed: "",
  lnpTppLocation: "",
  csrLocation: "",
  lsrLocation: "",
  name: "",
  businessHours: "",
  residentialRules: "",
  businessRules: "",
  notes: "",
  firstLevelSupport: "",
  firstLevelSupportEmail: "",
  firstLevelSupportPhoneNumber: "",
  secondLevelSupport: "",
  secondLevelSupportEmail: "",
  secondLevelSupportPhoneNumber: "",
  repairCenter: "",
  repairCenterEmail: "",
  verifiedDate: null,
};
