import React, { useState, useMemo, useEffect } from "react";
import { Switch, Redirect, HashRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Login from "./components/account/Login";
import ForgotPassword from "./components/account/ForgotPassword";
import translations from "./translations";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { IdentityService } from "services/identity/IdentityService";
import { AppContextUtil } from "services/appContext/AppContextUtil";
import { GetAppContext, AppContext } from "services/appContext/AppContext";
import PublicRoute from "services/routing/PublicRoute";
import { PermissionType } from "services/authorization/PermissionType";
import Unauthorized from "components/account/Unauthorized";
import Index from "components/Index";
import { AdminRoute } from "services/routing/AdminRoute";
import { ClientService } from "services/client/ClientService";
import Home from "components/home/Home";
import NavigateAtlas from "components/navigate-atlas/NavigateAtlas";
import NavigatePortControl from "components/navigate-port/NavigatePortControl";
import TollFreeNumbers from "components/inventory/toll-free-numbers/TollFreeNumbers";
import Settings from "components/settings/Settings";
import SearchExplorar from "components/search/SearchExplorar";
import MyProfile from "components/myProfile/MyProfile";
import LoginSuccess from "components/account/LoginSuccess";
import { useAuth0 } from "@auth0/auth0-react";
import { IdentityResponse } from "services/apis/types/identity/IdentityResponse";

export default function App() {
  const [appContext, setAppContext] = useState(GetAppContext());
  AppContextAccessor.setAppContextSetter(setAppContext);
  AppContextAccessor.setAppContextGetter(() => appContext);
  const { user, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const appContextWrapper = useMemo(() => ({ appContext, setAppContext }), [
    appContext,
    setAppContext
  ]);

  useEffect(() => {
    AppContextUtil.saveToLocalStorage(appContext.localStorageInfo);
    AppContextUtil.saveClientToLocalStorage(appContext.selectedClient);
  }, [appContext]);

  useEffect(() => {
    const getUserMetadata = async () => {
      getAccessTokenSilently({
        audience: process.env.REACT_APP_API_URL,
        scope: "read:current_user",
      }).then(e => {
        console.log("accesstoken ", e);
        if (e) { setAccessToken(e) } else IdentityService.clearAuthenticationInfo();
      }
      ).catch(ex => {
        console.error(ex);
        IdentityService.clearAuthenticationInfo();
      });
    };

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    if (accessToken) {
      IdentityService.userInfo(accessToken)
        .then((response: IdentityResponse) => {
          console.log("response ", response);
        })
        .catch((ex) => {
          console.error(ex);

        })
        .finally(() => { });
    }
  }, [accessToken]);

  useEffect(() => {
    (async () => {
      await IdentityService.checkAndRenewAccessToken();

      setAppContext((appContext) => {
        return { ...appContext, initialized: true };
      });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (appContext.initialized && appContext.selectedClient && appContext.selectedClient.clientId > 0 && appContext.localStorageInfo.authenticationInfo.authenticated) {
        await ClientService.getSelectedClientInfo();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext.initialized, appContext.selectedClient?.clientId, appContext.localStorageInfo.authenticationInfo.authenticated]);

  useEffect(() => {
    if (appContext.localStorageInfo.authenticationInfo.authenticated && appContext.localStorageInfo && appContext.localStorageInfo.user && appContext.localStorageInfo.user.id !== '') {
      const userId = appContext.localStorageInfo?.user?.id;
      const userEmail = appContext.localStorageInfo?.user?.email;
      const firstName = appContext.localStorageInfo?.user?.firstName;
      const lastName = appContext.localStorageInfo?.user?.lastName;
      (window as any).initializeParativeIo(userId.toString(), userEmail, firstName, lastName, process.env.REACT_APP_PARATIVE_IO_APP_TOKEN);
    }
    else {
      (window as any).destroyParativeIo();
    }
  }, [appContext.localStorageInfo, appContext.localStorageInfo.authenticationInfo.authenticated, appContext.localStorageInfo.user])

  return (
    <AppContext.Provider value={appContextWrapper}>
      <AppContext.Consumer>
        {({ appContext }) => (
          <IntlProvider
            locale={appContext.localStorageInfo.language}
            messages={translations[appContext.localStorageInfo.language]}>
            <HashRouter>
              <Switch>
                <PublicRoute path="/login" exact>
                  <Login />
                </PublicRoute>
                <PublicRoute path="/callback">
                  <LoginSuccess />
                </PublicRoute>
                <PublicRoute path="/forgotPassword">
                  <ForgotPassword />
                </PublicRoute>
                <AdminRoute path="/port-control">
                  <NavigatePortControl />
                </AdminRoute>
                <AdminRoute path="/atlas-portal">
                  <NavigateAtlas />
                </AdminRoute>
                <AdminRoute path="/inventory">
                  <TollFreeNumbers />
                </AdminRoute>
                <AdminRoute path="/search">
                  <SearchExplorar />
                </AdminRoute>
                <AdminRoute path="/settings">
                  <Settings />
                </AdminRoute>
                <AdminRoute path="/home" userPermissions={[PermissionType.DisasterRecovery, PermissionType.GlobalAdmin]}>
                  <Home />
                </AdminRoute>
                <AdminRoute path="/" exact>
                  <Index />
                </AdminRoute>
                <AdminRoute path="/unauthorized">
                  <Unauthorized />
                </AdminRoute>
                <AdminRoute path="/myprofile">
                  <MyProfile />
                </AdminRoute>
                <Redirect from="*" to="/" />
              </Switch>
            </HashRouter>
          </IntlProvider>
        )}
      </AppContext.Consumer>
    </AppContext.Provider>
  );
}
