import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import React from "react";
import { TollFreeSearchNumberDto } from "services/apis/types/toll-free-numbers/TollFreeSearchNumberDto";

type Props = {
  tollFreeNumber: TollFreeSearchNumberDto;
  handleInputChange: any
};

export default function TollFreeNumbersCell(props: Props) {
  const { tollFreeNumber } = props;

  return (
    <>
      <CheckboxFormInput
        label={(tollFreeNumber.number).toString()}
        value={(tollFreeNumber.number).toString()}
        checked={tollFreeNumber.modifiedStatus}
        handleInputChange={(value: any) => { props.handleInputChange({ ...props.tollFreeNumber, modifiedStatus: value }) }
        }
      >
      </CheckboxFormInput>
    </>
  );
}
