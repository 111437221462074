import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { ErrorHandler } from "services/customHooks/useErrors";
import InputError from "../errorHandling/InputError";

type Props = {
  disabled?: boolean;
  handleInputChange: any;
  errorHandler?: ErrorHandler;
  fixedSize?: boolean;
};

export default function Documents(props: Props) {
  const errors = props.errorHandler ? props.errorHandler.getErrors() : [];
  return (
    <>      
      <div
        className={classNames("form-control form-control-sm", {
          "pc-invalid-input": errors && errors.length > 0,
          "pc-no-resize": props.fixedSize
        })}
        >
        <input
          type="file"
          className="custom-file-input"
          lang="en"
          onChange={(e) => {
            if (props.errorHandler) props.errorHandler.clear();
            if (props.handleInputChange) props.handleInputChange(e.target.files?.[0]);
          }}
          disabled={props.disabled}
        />
        <label className={errors && errors.length > 0 ? "custom-file-label border-red" : "custom-file-label"}>
          <FormattedMessage id="file.documents.placeholder" />
        </label>
      </div>
      {props.errorHandler && <div className="mt-3"><InputError errors={props.errorHandler.getErrors()} /></div>}
    </>
  );
}
