import React from "react";
import MenuItem from "./MenuItem";
import classnames from "classnames";
import { Nav, Navbar } from "reactstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { BodyClassUtil } from "services/util/BodyClassUtil";
import Authorize from "../authorization/Authorize";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  toggleSidenav: () => void;
  sidenavOpen: boolean;
};

export default function Sidebar(props: Props) {
  const intl = useIntl();

  const onMouseEnterSidenav = () => {
    if (!BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.addClass("g-sidenav-show");
    }
  };

  const onMouseLeaveSidenav = () => {
    if (!BodyClassUtil.hasClass("g-sidenav-pinned")) {
      BodyClassUtil.removeClass("g-sidenav-show");
    }
  };

  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      props.toggleSidenav();
    }
  };

  return (
    <Navbar
      className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white overflow-x-hidden"
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}>
      <div className="scrollbar-inner">
        <div className="sidenav-header d-flex align-items-center">
          <div className="navbar-brand">
            <h2>
              <FormattedMessage id="sidebar.menu" />
            </h2>
          </div>
          <div className="ml-auto">
            <div
              className={classnames("sidenav-toggler d-none d-xl-block", {
                active: props.sidenavOpen
              })}
              onClick={props.toggleSidenav}>
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Nav navbar>
            {/* <Authorize userPermissions={[PermissionType.Explore]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.explore" })}
                linkTo="/NumberExplorer"
                iconClassName="fas fa-search text-info"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              />
            </Authorize>
            <Authorize userPermissions={[PermissionType.Network]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.network" })}
                linkTo="/NetworkExplorer"
                iconClassName="fas fa-signal text-orange"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              />
            </Authorize> */}
            <Authorize userPermissions={[PermissionType.DisasterRecovery, PermissionType.GlobalAdmin]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.home" })}
                linkTo="/home"
                iconClassName="fas fa-home text-sidebar"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
                {/* <Authorize spidPermissions={[PermissionType.SOARead]}
                  vpopDisabled={true}>
                  {!isNonSpidProfileSelected && (
                    <>
                      <MenuItem
                        title={intl.formatMessage({ id: "sidebar.orders.querySV" })}
                        linkTo="/QuerySV"
                        toggleSidenav={props.toggleSidenav}
                        closeSidenav={closeSidenav}
                      />
                      <MenuItem
                        title={intl.formatMessage({ id: "sidebar.orders.audit" })}
                        linkTo="/Audits"
                        toggleSidenav={props.toggleSidenav}
                        closeSidenav={closeSidenav}
                      />
                    </>
                  )}
                </Authorize> */}
              </MenuItem>
            </Authorize>
            <Authorize>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.inventory" })}
                linkTo="/inventory"
                iconClassName="fas fa-boxes text-sidebar"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
              </MenuItem>
            </Authorize>
            <Authorize>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.search" })}
                linkTo="/search"
                iconClassName="fas fa-search text-sidebar"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              ></MenuItem>
            </Authorize>
            <MenuItem
              title={intl.formatMessage({ id: "sidebar.port" })}
              linkTo="/port-control"
              iconClassName="fas fa-solid fa-hashtag text-sidebar"
              toggleSidenav={props.toggleSidenav}
              closeSidenav={closeSidenav}
            >
            </MenuItem>
            <MenuItem
              title={intl.formatMessage({ id: "sidebar.atl" })}
              linkTo="/atlas-portal"
              imgClassName="side-nav-img"
              imgSrc={require("assets/img/atl_quick_jump_logo.png")}
              imgAlt={intl.formatMessage({ id: "sidebar.atl" })}
              toggleSidenav={props.toggleSidenav}
              closeSidenav={closeSidenav}
            >
            </MenuItem>
            <Authorize>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.settings" })}
                linkTo="/settings"
                iconClassName="fas fa-cog text-sidebar"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
              </MenuItem>
            </Authorize>
            {/* <img
              alt="Atlas"
              src={require("assets/img/quickJump.png")}
              onClick={() => window.open("https://test.tollfreeportal.net/Activities/ActivityList.aspx")}
              width="20" style={{ marginLeft: 24, marginTop: 25 }}
            />
            <label style={{ fontSize: "0.875rem", fontWeight: 500, marginLeft: 60, marginTop: -20 }}>{intl.formatMessage({ id: "sidebar.atl" })}</label> */}

            {/* <Authorize
              userPermissions={[PermissionType.Reports, PermissionType.CompanyAdmin]}
              vpopDisabled={true}
              nonSpidDisabled={true}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.reports" })}
                linkTo="/Reports"
                iconClassName="fas fa-chart-pie"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
                >
              </MenuItem>
            </Authorize>
            <Authorize userPermissions={[PermissionType.WorkQueue]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.workQueue" })}
                linkTo="/WorkQueue"
                iconClassName="fas fa-tasks-alt"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}
              />
            </Authorize>
            <Authorize
              userPermissions={[
                PermissionType.Admin,
                PermissionType.CompanyAdmin,
                PermissionType.Webhooks
              ]}>
              <MenuItem
                title={intl.formatMessage({ id: "sidebar.manage" })}
                linkTo="/Manage"
                highlightUrls={["/Manage", "/Webhooks"]}
                iconClassName="fas fa-cog"
                toggleSidenav={props.toggleSidenav}
                closeSidenav={closeSidenav}>
                <Authorize userPermissions={[PermissionType.Admin, PermissionType.CompanyAdmin]}>
                  <MenuItem
                    title={intl.formatMessage({ id: "sidebar.usersAndOrganizations" })}
                    linkTo="/Manage"
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                </Authorize>
                <Authorize userPermissions={[PermissionType.Webhooks]}>
                  <MenuItem
                    title={intl.formatMessage({ id: "sidebar.notifications" })}
                    linkTo="/Notifications"
                    toggleSidenav={props.toggleSidenav}
                    closeSidenav={closeSidenav}
                  />
                </Authorize>
                <Authorize userPermissions={[PermissionType.Admin]}>
                  <MenuItem
                  title={intl.formatMessage({ id: "sidebar.reports.billingTransactions" })}
                  linkTo="/BillingTransactions"
                  toggleSidenav={props.toggleSidenav}
                  closeSidenav={closeSidenav}
                  />
                </Authorize> */}
            {/* </MenuItem>
            </Authorize> */}
          </Nav>
        </div>
      </div>
    </Navbar>
  );
}
