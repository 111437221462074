import React, { useState } from "react";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import TollFreeHistoryModal from "./TollFreeHistoryModal";

type Props = {
  tollFreeNumber: TollFreeNumbersDto;
  handleInputChange: any
};

export default function ExploreSearchNamecell(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const { tollFreeNumber } = props;
  // const { appContext } = useContext(AppContext);

  return (
    <>
      {/* <CheckboxFormInput
        value={(props.tollFreeNumber.Number).toString()}
        checked={tollFreeNumber.modifiedStatus}
        disabled={tollFreeNumber.respOrgId !== appContext.selectedClient?.respOrgId}
        handleInputChange={(value: any) => { props.handleInputChange({ ...props.tollFreeNumber, modifiedStatus: value }) }
        }
      > */}
      <span
        className="cursor-pointer text-primary text-underline"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
      >
        {props.tollFreeNumber.Number}
      </span>
      {/* </CheckboxFormInput> */}
      {showModal && (
        <TollFreeHistoryModal
          tollFreeNumber={tollFreeNumber}
          closeModal={() => setShowModal(false)}
        />
      )}
    </>
  );
}

