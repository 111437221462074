import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import { ApiError } from "../apis/types/ApiError";
import { IdentityService } from "../identity/IdentityService";
import { FieldError } from "../apis/types/FieldError";
import { EnvironmentUtil } from "../util/EnvironmentUtil";
import { getApiPortControlUrl, getApiUrl, getApiUrlAtlas } from "./HttpClientUtil";
import { ApiVersion } from "./ApiVersion";

export const HttpClient = {
  get: async <T>(
    url: string,
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId = "",
    version: ApiVersion = ApiVersion.V1,
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(getApiUrl(url, version), {
      method: "GET",
      headers: headersContent
    }).then(handleResponse<T>(requestClient));
  },

  getUserInfo: async <T>(
    url: string,
    auth0AccessToken: string,
    requestClient: string = "",
    version: ApiVersion = ApiVersion.V1,
  ) => {

    const headersContent = getUserAuth0Headers(auth0AccessToken);

    return fetch(getApiUrl(url, version), {
      method: "GET",
      headers: headersContent
    }).then(handleResponse<T>(requestClient));
  },

  getAtlas: async <T>(
    url: string,
    subscriptionKey: any,
    requestClient: string = "",
    // version: ApiVersion = ApiVersion.V1
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeadersMap(true, subscriptionKey);

    return fetch(getApiUrlAtlas(url), {
      method: "GET",
      headers: headersContent
    }).then(handleResponse<T>(requestClient));
  },

  getResource: async (
    url: string,
    addAuthorizationToken: string,
    addSelectedClient: boolean = false,
    customClientId = "",
    version: ApiVersion = ApiVersion.V1
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeadersPortControl(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(getApiPortControlUrl(url), {
      method: "GET",
      headers: headersContent
    }).then(handleResourceResponse());
  },

  getPortControl: async<T>(
    url: string,
    addAuthorizationToken: string,
    addSelectedClient: boolean = false,
    customClientId = "",
    portControlTenant = "",
    requestClient: string = "",
    version: ApiVersion = ApiVersion.V1
  ) => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeadersPortControl(addAuthorizationToken, addSelectedClient, customClientId, portControlTenant);

    return fetch(getApiPortControlUrl(url), {
      method: "GET",
      headers: headersContent
    }).then(handleResponse<T>(requestClient));
  },

  putPortControl: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: string,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = ""
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeadersPortControl(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(getApiPortControlUrl(url), {
      method: "PUT",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>(requestClient));
  },

  post: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(getApiUrl(url, version), {
      method: "POST",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>(requestClient));
  },

  postResource: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId, true);

    return fetch(getApiUrl(url, version), {
      method: "POST",
      headers: headersContent,
      body: bodyObject
    }).then(handleResponse<T>(requestClient));
  },

  delete: async <T>(
    url: string,
    bodyObject: any,
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = ""
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(EnvironmentUtil.apiV1Url(url), {
      method: "DELETE",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>(requestClient));
  },

  put: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = ""
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(EnvironmentUtil.apiV1Url(url), {
      method: "PUT",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>(requestClient));
  },

  putResource: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = ""
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId, true);

    return fetch(EnvironmentUtil.apiV1Url(url), {
      method: "PUT",
      headers: headersContent,
      body: bodyObject
    }).then(handleResponse<T>(requestClient));
  },

  patch: async <T>(
    url: string,
    bodyObject: any = {},
    addAuthorizationToken: boolean = true,
    addSelectedClient: boolean = true,
    requestClient: string = "",
    customClientId: string = "",
    version: ApiVersion = ApiVersion.V1
  ): Promise<T> => {
    await IdentityService.checkAndRenewAccessToken();

    const headersContent = getHeaders(addAuthorizationToken, addSelectedClient, customClientId);

    return fetch(getApiUrl(url, version), {
      method: "PATCH",
      headers: headersContent,
      body: JSON.stringify(bodyObject)
    }).then(handleResponse<T>(requestClient));
  }
};

const handleResponse = <T>(requestClient): ((value: Response) => Promise<T>) => {
  return async (response) => {
    if (response.ok) {
      try {
        const json = await response.json();
        if (requestClient) {
          json["requestClientId"] = requestClient;
        }
        return json as T;
      } catch (error) {
        return (response as unknown) as T;
      }
    } else {
      return await handleErrorResponse(response);
    }
  };
};

const handleResourceResponse = (): ((value: Response) => Promise<Blob | ApiError>) => {
  return async (response) => {
    if (response.ok) {
      try {
        return await response.blob();
      } catch (error) {
        throw response;
      }
    } else {
      return await handleErrorResponse(response);
    }
  };
};

const handleErrorResponse = async (response: Response) => {
  let json: {
    fieldErrors?: { [id: string]: FieldError[] };
    message?: string;
    error?: number;
  } = {};

  try {
    json = await response.json();
  } catch (error) {
    //ignore
  }

  const apiError: ApiError = { ...json, error: response.status };

  throw apiError;
};

const getHeaders = (
  addAuthorizationToken: boolean,
  addSelectedClient: boolean,
  customClientId?: string,
  hasFile?: boolean,
  subscriptionKey?: boolean,
) => {
  let headersContent: Record<string, string> = {
    Accept: "text/plain",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };


  if (!hasFile) {
    headersContent["Content-Type"] = "application/json-patch+json";
  }

  if (customClientId || addSelectedClient) {
    const clientId = customClientId || getClientId();

    if (clientId) {
      headersContent["client-id"] = clientId.toString();
    }
  }

  if (addAuthorizationToken) {
    headersContent["Authorization"] = `Bearer ${AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.access_token
      }`;
  }

  if (subscriptionKey) {
    headersContent["subscription-Id"] = "34a5e365-c4bc-4910-aa77-d87d280cfa41";
    headersContent["client-id"] = "ae93ce1f-8845-4dc4-a20f-d66bed7805b8";
    headersContent['primary-key'] = 'xYOap6dbkYTw5cQDTVSGDt-mSREIQycccMMMmKk3fi8';
    headersContent['secondary-key'] = 'spymut4oAh2C1hM3IHfTTNRK2UPrHuBHiGR4MlnJjhQ';
  }

  return headersContent;
};

const getUserAuth0Headers = (
  accessToken: string
) => {
  let headersContent: Record<string, string> = {
    Accept: "text/plain",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };

  headersContent["Content-Type"] = "application/json-patch+json";

  headersContent["Authorization"] = `Bearer ${accessToken}`;

  return headersContent;
};


const getHeadersMap = (
  hasFile?: boolean,
  subscriptionKey?: boolean,
) => {
  let headersContent: Record<string, string> = {
    Accept: "text/plain",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };


  if (!hasFile) {
    headersContent["Content-Type"] = "image/pbf,application/vnd.mapbox-vector-tile";
  }

  if (subscriptionKey) {
    headersContent["subscription-id"] = "34a5e365-c4bc-4910-aa77-d87d280cfa41";
    headersContent["subscription-key"] = "H1O9DxVcL7PqbtVYmkYsVwGlzeU-lFw3a8lTc4MvpS0";
    headersContent["client-id"] = "ae93ce1f-8845-4dc4-a20f-d66bed7805b8";
    headersContent['primary-key'] = 'xYOap6dbkYTw5cQDTVSGDt-mSREIQycccMMMmKk3fi8';
    headersContent['secondary-key'] = 'spymut4oAh2C1hM3IHfTTNRK2UPrHuBHiGR4MlnJjhQ';
  }

  return headersContent;
};
const getHeadersPortControl = (
  addAuthorizationToken: string,
  addSelectedClient: boolean,
  customClientId: string,
  portControlTenant?: string,
  hasFile?: boolean
) => {
  let headersContent: Record<string, string> = {
    Accept: "text/plain",
    "Cache-Control": "no-cache",
    Pragma: "no-cache"
  };

  if (!hasFile) {
    headersContent["Content-Type"] = "application/json-patch+json";
  }

  if (customClientId || addSelectedClient) {
    const clientId = customClientId || getClientId();

    if (clientId) {
      headersContent["client-id"] = clientId.toString();
    }
  }

  if (portControlTenant) {
    headersContent["x-portcontrol-tenant"] = portControlTenant;
  }

  if (addAuthorizationToken) {
    headersContent["Authorization"] = `Bearer ${addAuthorizationToken}`;
  }

  return headersContent;
};

const getClientId = () => {
  const appContext = AppContextAccessor.getAppContext();

  return appContext.selectedClient
    ? appContext.selectedClient.clientId
    : "";
};
