import React from "react";
import { AzureMapLayerProvider, AzureMapFeature } from "react-azure-maps";

type Props = {
    data?: any
};

export default function OutageZipcode(props: Props) {
    return (<>
        <AzureMapLayerProvider
            options={{
                textOptions: {
                    textField: ['get', "label"]
                },
                iconOptions: {
                    image: 'none'
                },
            }}
            type={"SymbolLayer"}
        />
        {props.data.map((poly: any) => (
            <>
                <AzureMapFeature
                    key={"Zipcode"}
                    variant={"feature"}
                    id={poly.id}
                    type="Point"
                    coordinate={poly.polygonLayout}
                    properties={{
                        label: poly.label,
                    }}
                    setCoords={poly.polygonLayout}
                />
            </>
        ))}
    </>);
}