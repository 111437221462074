export type RequestTollFreeQuery = {
    numbers: string[];
    queryType: QueryType;
};

export enum QueryType {
    Status = "Status",
    Routing = "Routing",
    Child = "Child",
    Cnam = "Toll-Free CNAM",
    RespOrg = "RespOrg Contact"
}


