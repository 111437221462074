import React from "react";
import { getUniqueElements } from "services/util/ArrayUtil";
import DropdownInput from "../forms/DropdownInput";

type Props = {
    column: {
        filterValue: string;
        preFilteredRows: Array<any>;
        setFilter: (value: string | undefined) => void;
        id: string;
    };
};


export default function DefaultTemplateColumnFilter(props: Props) {
    const options = React.useMemo(() => {
        const options = props.column.preFilteredRows.map((x) => (
            x.values[props.column.id]
        ));
        return [
            { key: "Any", value: "" },
            { key: "Unassigned", value: "Unassigned" },
            ...getUniqueElements(options)
                .filter((x) => x)
                .sort((a, b) => (a > b ? 1 : -1))
                .map((x, i) => {
                    return { value: x, key: x };
                })
        ];


    }, [props.column.id, props.column.preFilteredRows]);

    return (
        <DropdownInput
            sm
            onChange={(e) => {
                props.column.setFilter(e.target.value || undefined);
            }}
            value={props.column.filterValue}
            options={options}
        />
    );
}


export const customTablefilterFunction = (rows: any[], accessor: any, filterValues: any) => (
    rows.filter((x) => {
        if (filterValues === x.values[accessor]) {
            return (
                x.values
            );
        }
        if (filterValues === 'Unassigned' && x.values[accessor] === undefined) {
            return (
                x.values
            );
        }
        return '';
    })
)


