import React, { useState, FormEvent, useCallback, useEffect, useContext } from "react";
import { Modal, Form, Button, Row, FormGroup, Label } from "reactstrap";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import _ from 'lodash';
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CloseButton from "components/framework/modals/CloseButton";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import TextFormError from "components/framework/forms/TextFormError";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import FormatDateTime from "components/framework/date/FormatDateTime";
import TextWithTooltip from "components/common/TextWithTooltip";
import { CNAMReports } from "services/Reports/CNAMReports";
import { FraudScoreReports } from "services/Reports/FraudScoreReports";
// import { TextStatusReports } from "services/Reports/TextStatusReport";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";
import { ClientApi } from "services/apis/ClientApi";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { ActivityApi } from "services/apis/ActivityApi";

type Props = {
    selectedTollFreeNumbers?: any;
    refreshCheckbox?: any;
    closeModal: () => void;
    tollFreeNumber?: TollFreeNumbersDto;
};


export default function TollFreeReportModal(props: Props) {
    const reportType = [{ key: 'CNAM', value: 'CNAM', id: 4, status: false }, { key: 'Fraud Score', value: 'Fraud Score', id: 5, status: false }]
    const { setErrors, getErrorHandler } = useErrors();
    const intl = useIntl();
    const isMounted = useIsMounted();
    const [selectedReportType, setSelectedReportType] = useState<any>(reportType);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(false);
    const [tollFreeFraud, setTollFreeFraud] = useState<Array<FraudScoreReports>>();
    const [tollFreeCnam, setTollFreeCnam] = useState<Array<CNAMReports>>();
    // const [tollFreeText, setTollFreeText] = useState<Array<TextStatusReports>>();
    const [queryResult, setQueryResult] = useState<Array<any>>([]);
    const { appContext } = useContext(AppContext);
    const [showFeesModal, setShowFeesModal] = useState(false);
    const [showFraudFeesModal, setShowFraudFeesModal] = useState(false);
    const [showBothFeesModal, setShowBothFeesModal] = useState(false);
    const [cnamFees, setCnamFees] = useState(0);
    const [fraudFees, setFraudFees] = useState(0);

    const { tollFreeNumber } = props;

    // const closeModal = useCallback(props.closeModal, []);

    const getCNAMFees = useCallback(() => {
        ActivityApi.getCnamFees()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setCnamFees(result.fee);
                    console.log('getCNAMFees', result)
                }
            })
            .catch((error) => {
                console.log('error on cnamFees ', error)
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [isMounted]);

    const getCNAMFeesKind = useCallback(() => {
        ClientApi.getCnamFeesByKind()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setCnamFees(result.fee);
                    console.log('fees', result)
                }
            })
            .catch((error) => {
                console.log('error on cnamFeesbyKind ', error)
                getCNAMFees();
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [getCNAMFees, isMounted]);

    const getFraudFees = useCallback(() => {
        ActivityApi.getFraudFees()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setFraudFees(result.fee);
                    console.log('getFraudFees', result)
                }
            })
            .catch((error) => {
                console.log('error on fraudFees ', error)
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [isMounted]);

    const getFraudFeesKind = useCallback(() => {
        ClientApi.getFraudFeesByKind()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setFraudFees(result.fee);
                    console.log('Fraudfees', result)
                }
            })
            .catch((error) => {
                console.log('error on fraudFeesbyKind ', error)
                getFraudFees();
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [getFraudFees, isMounted]);

    useEffect(() => {
        getCNAMFeesKind();
        getFraudFeesKind();
    }, [getCNAMFeesKind, getFraudFeesKind, appContext.selectedClient]);

    const getCNAMTollFreeNumber = () => {
        const tollFreeNumberRequest = {
            tollFreeNumbers: props.selectedTollFreeNumbers
        };
        TollFreeNumbersApi.getCNAMTollFreeNumber(tollFreeNumberRequest)
            .then((result) => {
                if (isMounted.current) {
                    setTollFreeCnam(result.tollFreeCnams)
                    console.log('CnamResult', result)
                }
            })
            .catch((error) => {
                handleError(error);
                if (isMounted.current) {
                    const errorsResult = getFieldErrors(error.fieldErrors);
                    setErrors(errorsResult);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
    }

    const getFraudQueryTollFreeNumber = () => {
        const tollFreeNumberRequest = {
            tollFreeNumbers: props.selectedTollFreeNumbers
        };
        TollFreeNumbersApi.getFraudQueryTollFreeNumber(tollFreeNumberRequest)
            .then((result) => {
                if (isMounted.current) {
                    setTollFreeFraud(result.tollFreeFrauds);
                    console.log('Fraudquery', result)
                }
            })
            .catch((error) => {
                handleError(error);
                if (isMounted.current) {
                    const errorsResult = getFieldErrors(error.fieldErrors);
                    setErrors(errorsResult);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
    }

    /*const getTextQueryTollFreeNumber = () => {
        const tollFreeNumberRequest = {
            tollFreeNumbers: props.selectedTollFreeNumbers
        };
        TollFreeNumbersApi.getTextQueryTollFreeNumber(tollFreeNumberRequest)
            .then((result) => {
                if (isMounted.current) {
                    setTollFreeText(result.tollFreeTexts)
                    console.log('textQuery', result)
                }
            })
            .catch((error) => {
                handleError(error);
                if (isMounted.current) {
                    const errorsResult = getFieldErrors(error.fieldErrors);
                    setErrors(errorsResult);
                }
            })
            .finally(() => {
                if (isMounted.current) {
                    setShowLoadingIndicator(false);
                }
            });
    }*/

    const getReportDetails = () => {
        selectedReportType && selectedReportType.forEach((reportOption: any) => {
            if (reportOption.key === 'CNAM' && reportOption.status === true) {
                getCNAMTollFreeNumber();
            }
            if (reportOption.key === 'Fraud Score' && reportOption.status === true) {
                getFraudQueryTollFreeNumber();
            }
        })
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const errors = validateTollFreeNumber(selectedReportType);
        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
            }
        }
        else {
            //props.refreshCheckbox(true);
            if (!isNaN(cnamFees) && cnamFees > 0 && selectedReportType.some(x => x.key === "CNAM" && x.status === true) && !selectedReportType.every(x => x.status === true)) {
                setShowFeesModal(true);
                return;
            }
            else if (!isNaN(fraudFees) && fraudFees > 0 && selectedReportType.some(x => x.key === "Fraud Score" && x.status === true) && !selectedReportType.every(x => x.status === true)) {
                setShowFraudFeesModal(true);
                return;
            }
            else if (!isNaN(cnamFees) && cnamFees > 0 && !isNaN(fraudFees) && fraudFees > 0 && selectedReportType.every(x => x.status === true)) {
                setShowBothFeesModal(true);
                return;
            }
            setShowLoadingIndicator(true);
            getReportDetails();
        }
    };

    const handleSelectedReportTypeChanges = (reportType: any, index: number) => {
        const newSelectedReportType = [...selectedReportType];
        newSelectedReportType[index] = reportType;
        if (reportType.status) {
            getErrorHandler(nameOf<any>("reportType")).clear();
        }
        setSelectedReportType(newSelectedReportType);
    }


    useEffect(() => {
        if ((tollFreeFraud && tollFreeFraud.length > 0) || (tollFreeCnam && tollFreeCnam.length > 0)) {
            const mergedValues = _.values(_.merge(_.keyBy(tollFreeFraud, 'number'), _.keyBy(tollFreeCnam, 'number')))
            setQueryResult(mergedValues);
        }
    }, [tollFreeCnam, tollFreeFraud])

    const columns = useCallback((selectedReportType: any) => {
        let selectedReportOption: any = [];
        let CNAMReportAvailable: boolean = false;
        let fraudReportAvailable: boolean = false;
        let textReportAvailable: boolean = false;
        selectedReportType && selectedReportType.forEach((reportSelection: any) => {
            if (reportSelection.status === true) {
                selectedReportOption.push(reportSelection.key)
            }
        })
        selectedReportOption && selectedReportOption.forEach((reports: any) => {
            if (reports === 'CNAM') {
                CNAMReportAvailable = true
            }
            if (reports === 'Fraud Score') {
                fraudReportAvailable = true
            }
        })
        const columnsList = getColumns(intl, CNAMReportAvailable, fraudReportAvailable, textReportAvailable);
        return columnsList;
    }, [intl]);

    const handleConfirm = () => {
        setShowFeesModal(false);
        setShowFraudFeesModal(false);
        setShowBothFeesModal(false);
        setShowLoadingIndicator(true);
        getReportDetails();
    };

    return (
        <Modal className={((queryResult.length) > 0) ? "modal-dialog-centered table-reports-modal-content" : "modal-dialog-centered modal-lg"} isOpen={true} zIndex={2147483648}>
            {queryResult.length > 0 ? null : (
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id={tollFreeNumber ? "tollFreeNumber.create.updateReportModalTitle" : "tollFreeNumber.create.reportModalTitle"} />
                    </h5>
                    <CloseButton close={props.closeModal} />
                </div>
            )}

            {/* {showTollFreeNumberLoadingIndicator ? (
                <LoadingIndicator />
            ) : ( */}
            <Form onSubmit={(e) => handleSubmit(e)}>
                {queryResult.length > 0 ? (<>
                    <ReactTable
                        showViewSettings
                        hideSearch
                        title={intl.formatMessage({ id: "tollFreeNumber.create.reportModalTitle" })}
                        //data={_.merge(exploreDataSearch, exploreDataSearchOfRouting, tollFreeTextStatusList)}
                        showDownloadQuery={true}
                        data={queryResult}
                        autoResetPage={false}
                        columns={columns(selectedReportType)}
                    />
                    <CloseButton close={props.closeModal} />
                </>) : (<>
                    <div className="modal-body">
                        {/* {selectedNumberList.map((numberListValue: any, index: any) => (
                            <>
                                <Row>
                                    {numberLists ? (
                                        <DropdownFormInput
                                            id={index}
                                            className="col-lg-12"
                                            labelTranslationId={(index > 0) ? (`${intl.formatMessage({ id: "tollFreeNumber.create.numberList" })} ${index + 1}`) : "tollFreeNumber.create.numberList"}
                                            value={numberListValue.numberListId}
                                            handleInputChange={(value: string) =>
                                                handleSelectedNumberListChanges({ ...numberListValue, numberListId: (value) }, index)
                                            }
                                            errorHandler={getErrorHandler(`${nameOf<ReportGroup>("numberListId")}${index}`)}
                                            options={[
                                                { key: intl.formatMessage({ id: "tollfree.create.selectNumber" }), value: "" },
                                                ...numberLists.map(x => {
                                                    return { key: x.key, value: x.value.toString() };
                                                })
                                            ]}

                                        />
                                    ) : ''}

                                </Row>
                            </>
                        ))} */}
                        {/* {selectionOfNumberListType === 'Custom' && ( */}
                        {props.selectedTollFreeNumbers && props.selectedTollFreeNumbers.length > 0 && (
                            <Row>
                                {/* <TextAreaFormInput
                                    labelTranslationId="tollFreeNumber.create.customList"
                                    rows={4}
                                    value={props.selectedTollFreeNumbers}
                                    className="col-lg-12"
                                /> */}
                                <FormGroup className={classNames("mb-2", "col-lg-12")}>
                                    <Label className="form-control-label"><FormattedMessage id={"tollFreeNumber.create.customList"} /></Label>
                                    <Label className={classNames("form-control form-control-sm border-0 pl-0 hight-100")}>{props.selectedTollFreeNumbers.join(', ')}</Label>
                                </FormGroup>

                            </Row>
                        )}

                        {/* )} */}
                        <Row><div className="col-lg-12 font-checkbox"><FormattedMessage id="tollFreeNumber.create.reportType" /></div></Row>
                        {selectedReportType && selectedReportType.map((reportTypes: any, index: any) => (
                            <Row key={index}>
                                <CheckboxFormInput
                                    className="col-lg-12 margin-left-15"
                                    label={(reportTypes.value)}
                                    value={(reportTypes.value)}
                                    checked={reportTypes.status}
                                    handleInputChange={(value: boolean) =>
                                        handleSelectedReportTypeChanges({ ...reportTypes, status: value }, index)
                                    }
                                >
                                </CheckboxFormInput>
                            </Row>

                        ))}
                        <TextFormError errorHandler={getErrorHandler(nameOf<any>("reportType"))}></TextFormError>
                    </div>
                    <div className="modal-footer justify-content-end">
                        <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                            <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                        </Button>
                        <Button color="primary" type="submit" disabled={showLoadingIndicator}>
                            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                            <FormattedMessage id="tollfree.create.reportSubmitButton" />
                        </Button>
                    </div>
                </>)}
            </Form>
            {/* )
            } */}
            {showFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.fees.description" values={{ fees: (props.selectedTollFreeNumbers.length * cnamFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
            {showFraudFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowFraudFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.fees.description" values={{ fees: (props.selectedTollFreeNumbers.length * fraudFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
            {showBothFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowBothFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.bothFees.description" values={{ fees: (props.selectedTollFreeNumbers.length * cnamFees).toFixed(4), fraudFees: (props.selectedTollFreeNumbers.length * fraudFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
        </Modal>
    );
}

const validateTollFreeNumber = (reportTypeOption: any) => {
    const errors: Errors = {};
    const isFound = reportTypeOption.some(element => {
        if (element.status === true) {
            return true;
        }
        return false;
    });

    if (!isFound) {
        AddError(errors, nameOf<any>("reportType"), "tollFreeNumber.create.invalidReportTypeCheckbox");
    }
    return errors;
};

const getColumns = (intl: IntlShape, CNAMReportAvailable: boolean, fraudReportAvailable: boolean, textReportAvailable: boolean) => {
    let resultOfReportsColumn: any = [];
    resultOfReportsColumn.push({
        Header: intl.formatMessage({ id: "tollfree.table.number" }),
        accessor: "number",
        className: "text-break",
        Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
    })
    if (CNAMReportAvailable) {
        resultOfReportsColumn.push(
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.cnam" }),
                accessor: "name",
                className: "text-break",
                Cell: (cell: any) => cell.cell.row.original.name ? getValueOrEmpty(cell.cell.row.original.name) : "(no data)"
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.addressLine1" }),
                accessor: "addressLine1",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.addressLine2" }),
                accessor: "addressLine2",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.city" }),
                accessor: "city",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.state" }),
                accessor: "state",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.zipcode" }),
                accessor: "zipcode",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.country" }),
                accessor: "country",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.siteURL" }),
                accessor: "siteURL",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.logoURL" }),
                accessor: "logoURL",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            })
    }
    if (fraudReportAvailable) {
        resultOfReportsColumn.push(
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.score" }),
                accessor: "score",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.propensity" }),
                accessor: "propensity",
                className: "text-break",
                Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
            },

        )
    }
    if (textReportAvailable) {
        resultOfReportsColumn.push({
            Header: intl.formatMessage({ id: "tollfree.reportTable.textStatus" }),
            accessor: "statusType",
            className: "text-break",
            Cell: (cell: any) => (getValueOrEmpty(cell.cell.row.original.providerName) ? <TextWithTooltip
                className="f-big cursor-pointer"
                tooltip={`Service Provider: ${cell.cell.row.original.providerName}`}
                value={cell.cell.value}
            /> : getValueOrEmpty(cell.cell.value)
            )
        },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.statusDate" }),
                accessor: "statusDate",
                className: "text-break",
                Cell: (cell: any) => (
                    <FormatDateTime utcDate={cell.cell.value} />
                )
            }
        )
    }
    return resultOfReportsColumn
}
