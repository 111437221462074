import React from "react";
import { getEstDate, getFormattedEstDate } from "services/util/EstDateUtil";

type Props = {
  utcDate: string | Date | undefined;
  format?: string;
};

export default function FormatDateTime(props: Props) {
  return (
    <>
      {props.format
        ? getEstDate(props.utcDate?.toString()).format(props.format)
        : getFormattedEstDate(props.utcDate)}
    </>
  );
}
