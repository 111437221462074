import { ServiceProviderProfileResult } from "components/common/types/ServiceProviderProfileResult";
import React from "react";
import { useState, useEffect } from "react";
import { PortControlApi } from "services/apis/PortControlApi";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { handleError } from "services/util/ApiUtil";
import { useIsMounted } from "./useIsMounted";

export default function useServiceProviderResult(spId?: string) {
    const [serviceProvider, setServiceProvider] = useState<
        ServiceProviderProfileResult
    >();
    const [serviceProviderAccessToken, setServiceProviderAccessToken] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const isMounted = useIsMounted();

    const fetchServiceProvider = React.useCallback(() => {
        setIsLoading(true);
        setIsError(false);
        if (spId) {
            PortControlApi.getToken()
                .then((result) => {
                    if (isMounted && result) {
                        setServiceProviderAccessToken(result?.access_token)
                        ServiceProviderApi.get(spId, result?.access_token)
                            .then((result) => {
                                if (isMounted && result) {
                                    setServiceProvider(result)
                                }
                            })
                            .catch((error) =>  { handleError(error); setIsError(true); })
                            .finally(() => {
                                if (isMounted) {
                                    setIsLoading(false);
                                }
                            });
                    }
                })
                .catch((error) => { handleError(error); setIsError(true); })
                .finally(() => {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                });
        } else {
            setIsLoading(false);
        }
    }, [spId, isMounted]);

    useEffect(() => {
        if (spId) {
            fetchServiceProvider();
        }
    }, [spId, fetchServiceProvider]);

    return { serviceProvider, isLoading, isError, setIsError, serviceProviderAccessToken, fetchServiceProvider };
}
