import React from "react";
import { AzureMapLayerProvider, AzureMapFeature } from "react-azure-maps";

type Props = {
    multiPolygon?: any,
    mouseOn: () => void,
    mouseLeave: () => void,
    clusterClicked: () => void,
};

export default function OutageMultiPolygon(props: Props) {
    return (<>
            <AzureMapLayerProvider
                options={{
                    color: ["get", "color"],
                    fillColor: [
                        'case', // Use a conditional case expression.

                        ['has', 'fillColor'],   // Check to see if feature has a "fillOpacity" property
                        ['get', 'fillColor'],   // If it does, use it.

                        '#000000'  //If it doesn't, default to black.
                    ],
                    fillOpacity: 0.4,
                    strokeColor: ['get', "label"],
                    strokeOpacity: 0.8,
                    geoId: ['get', 'geoId'],   // If it does, use it.
                    textOptions: {
                        //Reference the label property of the polygon as the text field. 
                        textField: ['get', "label"]
                    },
                    iconOptions: {
                        //Hide the default image. 
                        image: 'none'
                    },
                }}

                id={"shape AzureMapLayerProvider"}
                type={"PolygonLayer"}
                events={{
                    mouseenter: props.mouseOn,
                    mouseleave: props.mouseLeave,
                    click: props.clusterClicked,
                }}
            />
            {props.multiPolygon.map((poly) => (
                <>
                    <AzureMapFeature
                        key={"MultiPolygon"}
                        variant={"feature"}
                        id={poly.id}
                        type="MultiPolygon"
                        multipleDimensionCoordinates={poly.polygonLayout}
                        properties={{
                            label: poly.label,
                            fillColor: poly.color,
                            fillOpacity: 0.4,
                            strokeColor: poly.color,
                            strokeOpacity: 0.8,
                            strokeWeight: 3,
                            geoId: poly.geoId,
                        }}
                        setCoords={poly.polygonLayout}
                    />
                </>
            ))}
    </>);
}