import React from "react";
import { FormGroup, Label } from "reactstrap";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import { KeyValuePair } from "services/types/KeyValuePair";
import DropdownInput from "./DropdownInput";

export type DropdownFormInputProps = {
    disabled?: boolean;
    required?: boolean;
    labelTranslationId?: string;
    labelClassName?: string;
    value?: string;
    errorHandler?: ErrorHandler;
    className?: string;
    handleInputChange: (value: string) => void;
    options: KeyValuePair<string, string>[];
    lg?: boolean;
};

export default function DropdownFormService(props: DropdownFormInputProps) {
    const errors = props.errorHandler?.getErrors();

    return (
        <FormGroup className={classNames("mb-2", props.className)}>
            {props.labelTranslationId && (
                <Label className={classNames("form-control-label", props.labelClassName, { "pc-required-label": props.required })}>
                    <FormattedMessage id={props.labelTranslationId} />
                </Label>
            )}
            <DropdownInput
                disabled={props.disabled}
                className={classNames({
                    "pc-invalid-input": errors && errors.length > 0
                })}
                sm={!props.lg}
                onChange={(e) => {
                    if (props.errorHandler) {
                        props.errorHandler.clear();
                    }
                    props.handleInputChange(e.currentTarget.value);
                }}
                value={props.value}
                options={props.options}
            />
            {errors && <InputError errors={errors} />}
        </FormGroup>
    );
}
