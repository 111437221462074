import {
  ContactInformation,
  DefaultContactInformation,
} from "services/apis/types/serviceProvider/ContactInformation";

export type ServiceProviderProfileResult = {
  spId: string;
  name: string;
  type: string;
  activeRegions: Array<string>;
  contactInformation: ContactInformation;
};

export const DefaultServiceProviderResult: ServiceProviderProfileResult = {
  spId: "",
  name: "",
  type: "",
  activeRegions: [],
  contactInformation: DefaultContactInformation,
};
