import { getUniqueElements } from "./ArrayUtil";

export function getValueOrEmpty(value: string | undefined) {
  return value ? value : "";
}

export function getdno(value: boolean) {
  return value ? "tollfree.table.donotoriginate.yes" :"tollfree.table.donotoriginate.no";
}

export function undefinedIfEmpty(value: string | undefined) {
  return value === "" || value === undefined ? undefined : value;
}

export const lowerCaseIncludes = (value: string, text: string) =>
  !!text && typeof(value) === "string"  && text?.toLowerCase && text?.toLowerCase().includes(value.toLowerCase());

export function isEmail(email: string) {
  const regularExpression = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regularExpression.test(String(email).toLowerCase());
}

export function isUrl(url: string) {
  const regularExpression = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!]))?/;

  return regularExpression.test(url);
}

export const isEmailOrUrl = (value: string) => isEmail(value) || isUrl(value);

export function isRange(value: string) {
  const regularExpression = /^\d{10}-{1}\d{4}$/;

  return regularExpression.test(value);
}

export function isNumber(value: string) {
  const regularExpression = /^[0-9]+$/;

  return regularExpression.test(value);
}

export function isPhoneNumber(value: string) {
  const regularExpression = /^\d{3}-?\d{3}-?\d{4}$/;

  return regularExpression.test(value);
}

export function removeDashes(value: string): string {
  const regularExpression = /-/g;

  return value.replace(regularExpression, "");
}

export function replaceWhitespaceWithComma(value: string): string {
  const regularExpression = /\s+/g;

  return value.replace(regularExpression, ",");
}

export const isAlphanumeric = (value: string) => {
  const regularExpression = /^[0-9a-zA-Z]+$/;

  return regularExpression.test(value);
};

export const isSpid = (value: string) => value.length === 4 && isAlphanumeric(value);
export const isRespOrg = (value: string) => value.length === 5 && isAlphanumeric(value);
export const isCode = (value: string) => value.length === 6 && isNumber(value);
export const isBlock = (value: string) => value.length === 7 && isNumber(value);
export const isLrn = (value: string) => value.length === 10 && isNumber(value);

export const splitCommaSeparatedList = (list: string) =>
  list.split(/,| |, /).filter((x) => x !== "");

export const getFileExtension = (fileName: string) => fileName.substring(fileName.lastIndexOf("."));

export const getPhoneNumberListItems = (numberInputValue: string): string[] =>
  splitCommaSeparatedList(numberInputValue);

export const getPhoneNumberList = (numberInputValue: string): string[] => {
  const listItems = getPhoneNumberListItems(numberInputValue);
  const phoneNumbers: string[] = [];

  for (const listItem of listItems) {
    if (!listItem) {
      continue;
    }

    if (listItem.length === 15 && isRange(listItem)) {
      const startRange = Number(listItem.slice(0, 10));
      const endRange = Number(listItem.slice(0, 6) + listItem.slice(11, 15));
      phoneNumbers.push(
        ...Array.from(Array(endRange - startRange + 1).keys()).map((x) =>
          (startRange + x).toString()
        )
      );
    } else {
      const phoneNumber = removeDashes(listItem);
      if (phoneNumber.length === 10 && isPhoneNumber(phoneNumber)) {
        phoneNumbers.push(listItem);
      }
    }
  }

  return getUniqueElements(phoneNumbers);
};

export const truncateAndAppendEllipsis = (text: string | undefined, maxLength = 80): string => {
  const value = getValueOrEmpty(text);
  return value.length > maxLength ? `${value.substring(0, maxLength)}...` : value;
};

export const removeWhitespace = (value: string) => {
  const regularExpression = /\s+/g;

  return value.replace(regularExpression, "");
};

export const lowerCaseEquals = (first: string, second: string) =>
  first.toLowerCase() === second.toLowerCase();
