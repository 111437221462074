import React from "react";
import classNames from "classnames";
import ReactDatetime from "react-datetime";
import { AppConfiguration } from "AppConfiguration";
import moment, { Moment } from "moment-timezone";

type Props = {
  disabled?: boolean;
  className?: string;
  value?: any;
  placeholder?: string;
  onChange: (value: any) => void;
  isTimeInput?: boolean;
  showModalOnTop?: boolean;
  isValidDate?: (currentDate: Moment, selectedDate: Moment | undefined) => boolean;
};

export default function DateTimeInput(props: Props) {
  return (
    <ReactDatetime
      utc
      inputProps={{
        disabled: props.disabled,
        className: classNames("form-control form-control-sm bg-white", props.className),
        placeholder: props.placeholder,
        readOnly: true
      }}
      className={classNames({ "top-datepicker": props.showModalOnTop })}
      value={props.value}
      onChange={(e: any) => {
        props.onChange(moment(e).tz('America/New_York'));
      }}
      closeOnSelect={true}
      dateFormat={!props.isTimeInput ? AppConfiguration.dateFormat : false}
      timeFormat={props.isTimeInput ? AppConfiguration.timeFormat : false}
      isValidDate={props.isValidDate}
    />
  );
}
