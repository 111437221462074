import React from "react";
import { FormattedMessage } from "react-intl";
import {
    CardBody,
    Col,
    Row,
} from "reactstrap";

export default function SettingsHeader() {
    return (
        <>
            <CardBody>
                <Row className="justify-content-center align-items-center">
                    <Col className="d-flex align-items-center">
                        <span className="toll-free-title display-3"><FormattedMessage id="settings.notification" /></span>
                        {/* <span className="text-white ml-5"><FormattedMessage id="settings.other" /></span> */}
                    </Col>
                    {/* <Authorize userPermissions={[PermissionType.InsertTollFrees, PermissionType.GlobalAdmin]}>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    color="primary"
                                    type="button"
                                onClick={() => setShowSettingsModal(true)}
                                >
                                    <i className="fa fa-plus-square mr-2" />
                                    <FormattedMessage id="tollfree.button.create.title" />
                                </Button>
                            </Col>
                        </Authorize> */}
                </Row>
                {/* {showSettingsModal && (
                        <TollFreeQueryUpsertModal closeModal={() => setShowSettingsModal(false)} />
                    )} */}
            </CardBody>
        </>
    );
}
