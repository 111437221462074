import React from "react";
import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import CardSectionHeader from "components/framework/cards/CardSectionHeader";
import classnames from "classnames";
import { isEmail, isEmailOrUrl } from "services/util/StringUtil";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
import { ErrorHandler } from "services/customHooks/useErrors";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";

type Props = {
  readonly: boolean;
  serviceProvider: ServiceProviderProfile;
  setServiceProvider: (serviceProvider: ServiceProviderProfile) => void;
  getErrorHandler: (key: string) => ErrorHandler;
};

export default function OperationsInformation(props: Props) {
  const formatUrl = (link: string) => {
    const url = isEmail(link) ? `mailto:${link}` : link;

    return url;
  };

  const handleInputClick = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (props.readonly && isEmailOrUrl(e.currentTarget.value)) {
      window.open(formatUrl(e.currentTarget.value), "_blank");
    }
  };

  return (
    <>
      <CardSectionHeader labelTranslationId="tppModal.operationsInformation" />

      <div className="mb-3 d-flex">
        <div className="flex-grow-1 mr-2">
          <div className="d-flex flex-direction-column">
            <TextFormInput
              inputClassName={classnames({
                "cursor-pointer":
                  props.readonly &&
                  isEmailOrUrl(props.serviceProvider.businessHours),
              })}
              readonly={props.readonly}
              labelTranslationId="tppModal.businessHours"
              value={props.serviceProvider.businessHours}
              handleInputChange={(value: string) =>
                props.setServiceProvider({
                  ...props.serviceProvider,
                  businessHours: value,
                })
              }
              onClick={handleInputClick}
              errorHandler={props.getErrorHandler(
                nameOf<ServiceProviderProfile>("businessHours")
              )}
            />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-direction-column">
              <TextAreaFormInput
                labelTranslationId="tppModal.residentialRules"
                disabled={props.readonly}
                rows={4}
                value={props.serviceProvider.residentialRules}
                handleInputChange={(value: string) =>
                  props.setServiceProvider({
                    ...props.serviceProvider,
                    residentialRules: value,
                  })
                }
                errorHandler={props.getErrorHandler(
                  nameOf<ServiceProviderProfile>("residentialRules")
                )}
              />
            </div>
          </div>
          <div className="flex-grow-1">
            <div className="d-flex flex-direction-column">
              <TextAreaFormInput
                rows={7}
                labelTranslationId="tppModal.businessRules"
                disabled={props.readonly}
                value={props.serviceProvider.businessRules}
                handleInputChange={(value: string) =>
                  props.setServiceProvider({
                    ...props.serviceProvider,
                    businessRules: value,
                  })
                }
                errorHandler={props.getErrorHandler(
                  nameOf<ServiceProviderProfile>("businessRules")
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
