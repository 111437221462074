import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import DisasterRecoveryCardActionsCell from "./DisasterRecoveryCardActionsCell";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";

type Props = {
    disasterRecoveries: DisasterRecoveryDto[];
};

export default function DisasterRecoveryCard(props: Props) {
    const intl = useIntl();
    const columns = useMemo(() => getColumns(intl), [intl]);

    return (
        <ReactTable
            title={intl.formatMessage({ id: "disasterRecoveries.table.title" })}
            data={props.disasterRecoveries}
            hideSearch
            hideHeaders
            initialPageSize={5}
            showAdd
            columns={columns}
        />
    );
}

const getColumns = (intl: IntlShape) => [
    {
        accessor: "name",
        className: "text-break",
        Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
    },
    {
        accessor: "actions",
        className: "w-25",
        Cell: (cell: any) => <DisasterRecoveryCardActionsCell disasterRecovery={cell.cell.row.original} />
    }
];
