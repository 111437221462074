import React from "react";
import { FormattedMessage } from "react-intl";
import { Table } from "reactstrap";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";

type Props = {
    carriers: Array<CarrierDto>;
};

export default function TemplateCarriers(props: Props) {
    return (
        <Table borderless size="sm">
            <thead className="thead-light">
                <tr>
                    <th>
                        <FormattedMessage id={"disasterRecovery.create.cic"} />
                    </th>
                    <th>
                        <FormattedMessage id={"disasterRecovery.create.name"} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.carriers.map((carrierObj, index) => (
                    <tr key={index}>
                        <td>
                            {carrierObj.cic}
                        </td>
                        <td>
                            {carrierObj.name}
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>)
}