import React, { useMemo, useEffect, useState, useContext, useCallback } from "react";
import { useIntl, IntlShape, FormattedMessage } from "react-intl";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { handleError } from "services/util/ApiUtil";
import { getdno, getValueOrEmpty } from "services/util/StringUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import ReactTable from "components/framework/table/ReactTable";
import FormatDateTime from "components/framework/date/FormatDateTime";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import IconWithTooltip from "components/common/IconWithTooltip";
import TollFreeNumbersActionsCell from "./TollFreeNumbersActionsCell";
import { AppContext } from "services/appContext/AppContext";
import { useIsMounted } from "services/customHooks/useIsMounted";
import DateRangeColumnFilter, { dateRangefilterFunction } from "components/framework/table/DateRangeColumnFilter";
import { TollFreeNumberTableColumnIdentifiers } from "./types/TollFreeNumberTableColumnIdentifiers";
import { getFormattedEstDate } from "services/util/EstDateUtil";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";
import DefaultCustomColumnFilter, { customfilterFunction } from "components/framework/table/DefaultCustomColumnFilter";
import DefaultTemplateColumnFilter, { customTablefilterFunction } from "components/framework/table/DefaultTemplateColumnFilter";
import TollFreeNumbersCell from "./TollFreeNumbersCell";
import BreadcrumbHeader from "components/common/BreadcrumbHeader";
import { Container } from "reactstrap";
import TollFreeHeader from "./TollFreeHeader";
import { TollFreeTextDto } from "services/apis/types/toll-free-numbers/TollFreeTextDto";
import _ from 'lodash';

type TollFreeNumberContext = {
    tollFreeNumberActionCallback: () => void;
};

export const TollFreeNumberContext = React.createContext<TollFreeNumberContext>({
    tollFreeNumberActionCallback: () => { }
})

export default function TollFreeNumbers() {
    const { appContext } = useContext(AppContext);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [totalCountDisplay, setTotalCountDisplay] = useState<string>();
    const [totalCount, setTotalCount] = useState<number>();
    const [isLimitExceed, setIsLimitExceed] = useState<boolean>(false);
    const [dataTableData, setDataTableData] = useState<Array<TollFreeNumbersDto>>([]);
    const [triggerRefresh, setTriggerRefresh] = useState(false);
    const intl = useIntl();
    const dueDateFilter = useCallback(dateRangefilterFunction, [dateRangefilterFunction]);
    const customFilters = useCallback(customfilterFunction, [customfilterFunction]);
    const customTemplateFilters = useCallback(customTablefilterFunction, [customTablefilterFunction]);
    const [selectedTollFreeNumbers, setSelectedTollFreeNumbers] = useState<Array<any>>([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [dataRefreshed, setDataRefreshed] = useState(false);
    //const [selectedPages, setSelectedPages] = useState<Array<number>>([]);

    const isMounted = useIsMounted();
    const [tollFreeTextStatusList, setTollFreeTextStatusList] = useState<Array<TollFreeTextDto>>([]);
    const toggleRefresh = useCallback(() => setTriggerRefresh((x) => !x), []);

    const handlePageChange = (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const redirectToBlazor = useCallback(() => {
        if (process.env.REACT_APP_REDIRECT_BLAZOR_URL_INVENTORY) { 
            window.location.href = `${process.env.REACT_APP_REDIRECT_BLAZOR_URL_INVENTORY}?cid=${appContext.selectedClient?.clientId}`
        }
      },[appContext.selectedClient])
    
    useEffect(() => redirectToBlazor(), [redirectToBlazor]);
    
    // useEffect(() => {
    //     console.log(dataTableData);
    //     const newTollFreeNumbers = [...dataTableData];
    //     if (selectedPages.length && selectedPages.findIndex(x => x === pageIndex) > -1) {
    //         newTollFreeNumbers.forEach((enabledTollFree) => {
    //             enabledTollFree.modifiedStatus = true;
    //             selectedTollFrees(newTollFreeNumbers);
    //         })
    //     }
    // }, [pageIndex])

    const onTollFreesReceived = useCallback((result: Array<TollFreeNumbersDto>, selectedTollFreeNumbers: any) => {
        result.forEach((x) => {
            if (selectedTollFreeNumbers.find(y => y === x.number)) {
                x.modifiedStatus = true;
            }
        })
        setDataTableData(result);
    }, []);

    const handlePageSizeChange = (event) => {
        setPageSize(event);
        setPageIndex(0);
    };

    const fetchTollFreeNumbers = useCallback((pageIndex, pageSize) => {
        // TollFreeNumbersApi.get(null, true, 1, 10000)
        TollFreeNumbersApi.get(null, true, pageIndex + 1, pageSize)
            .then((result) => {
                if (isMounted && result) {
                    getTollFreeData(result);
                    onTollFreesReceived(result, selectedTollFreeNumbers);
                    setDataRefreshed(true);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });

    }, [isMounted, selectedTollFreeNumbers, onTollFreesReceived]);

    const fetchTollFreeCount = useCallback(() => {
        TollFreeNumbersApi.getcount()
            .then((result) => {
                if (isMounted && result) {
                    setTotalCount(result)
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted) {
                    setShowLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    useEffect(() => {
        setSelectedTollFreeNumbers([]);
        if (pageIndex !== 0 || pageSize !== 10) {
            setPageIndex(0);
            setPageSize(10);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext.selectedClient])

    useEffect(() => {
        setShowLoadingIndicator(true);
        fetchTollFreeNumbers(pageIndex, pageSize);
        fetchTollFreeCount();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchTollFreeCount, appContext.selectedClient?.clientId, triggerRefresh, pageIndex, pageSize]);

    // const tollfreeNumberContext: TollFreeNumberContext = {
    //     tollFreeNumberActionCallback: fetchTollFreeNumbers
    // };

    const selectedTollFree = useCallback((searchNumbers: any) => {
        const newSelected = [...selectedTollFreeNumbers];
        const selectedIndex = newSelected.findIndex(x => x === searchNumbers.number)
        if (searchNumbers.modifiedStatus && selectedIndex < 0) {
            newSelected.push(searchNumbers.number);
        } else if (!searchNumbers.modifiedStatus && selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1);
        }
        setSelectedTollFreeNumbers(newSelected)
    }, [selectedTollFreeNumbers]);


    const selectedTollFrees = useCallback((searchNumbers: any[]) => {
        const newSelected = [...selectedTollFreeNumbers];
        searchNumbers.forEach((x) => {
            const selectedIndex = newSelected.findIndex(y => y === x.number)
            if (x.modifiedStatus && selectedIndex < 0) {
                newSelected.push(x.number);
            } else if (!x.modifiedStatus && selectedIndex >= 0) {
                newSelected.splice(selectedIndex, 1);
            }
        })
        setSelectedTollFreeNumbers(newSelected)
    }, [selectedTollFreeNumbers]);


    const handleChangeSearchNumber = useCallback((searchNumbers: any) => {
        dataTableData && dataTableData.forEach((numberList: any, index) => {
            if ((searchNumbers.number) === (numberList.number)) {
                const newCheckedSearchNumbers = [...dataTableData];
                newCheckedSearchNumbers[index] = searchNumbers;
                setDataTableData(newCheckedSearchNumbers);
            }
        })
        selectedTollFree(searchNumbers);
    }, [dataTableData, selectedTollFree]);

    const getTollFreeData = (data) => {
        if (data && data.length > 0) {
            let numbers: any[] = data.map(s => (s.number));
            TollFreeNumbersApi.queryTollFreeTexts({ tollFreeNumbers: numbers })
                .then((result) => {
                    setTollFreeTextStatusList(result.tollFreeTexts)
                })
                .catch((error) => {
                    if (error && error.message && !error.message.includes("Following"))
                        handleError(error);
                })
        }
    }

    const refreshCheckbox = (doneDetails: any) => {
        if (doneDetails === true) {
            dataTableData && dataTableData.forEach((numbersList) => {
                if (numbersList.modifiedStatus === true) {
                    numbersList.modifiedStatus = false;
                }
                //to do
                //selectedTollFrees(numbersList);
            })
            setDataTableData(dataTableData)
        }
    }

    const columns = useMemo(() => {
        const columnsList = getColumns(intl, handleChangeSearchNumber, dueDateFilter, customFilters, customTemplateFilters);
        return columnsList;
    }, [intl, handleChangeSearchNumber, dueDateFilter, customFilters, customTemplateFilters]);

    useEffect(() => {
        let count = "";
        const limit: any = appContext && appContext.client?.tollFreeLimit;
        if (totalCount && limit > 0) {
            count = `: ${totalCount} / ${limit}`;
            setIsLimitExceed(totalCount > limit);
        } else {
            count = totalCount ? `: ${totalCount}` : "";
        }
        setTotalCountDisplay(count);
    }, [totalCount, appContext]);

    const handleSelectAll = useCallback((value: boolean, tollFreeSearchNumbers: any) => {
        const newTollFreeNumbers = [...dataTableData];
        let modified = false;
        tollFreeSearchNumbers.forEach((enabledTollFree) => {
            let newCheckedSearchNumber = newTollFreeNumbers.find(x => x.number === enabledTollFree.original.number);
            if (newCheckedSearchNumber && newCheckedSearchNumber.modifiedStatus !== value) {
                newCheckedSearchNumber.modifiedStatus = value;
                modified = true;
            }
        })
        if (modified) { setDataTableData(newTollFreeNumbers); selectedTollFrees(newTollFreeNumbers); }
    }, [dataTableData, selectedTollFrees])

    const areSelectedAll = (tollFreeNumbers: any) => {
        let finalVal = true;
        tollFreeNumbers.forEach((enabledTollFree) => {
            let newCheckedSearchNumber = selectedTollFreeNumbers.find(x => x === enabledTollFree.original.number);
            if (!newCheckedSearchNumber) {
                finalVal = false;
                return;
            }
        })
        return finalVal;
    }

    return (
        <>
            <BreadcrumbHeader>
                <FormattedMessage id="inventory.title" />

            </BreadcrumbHeader>
            <TollFreeHeader selectedTollFreeNumbers={selectedTollFreeNumbers} refreshCheckbox={refreshCheckbox} />
            <Container fluid className="mb-5">
                {/* <TollFreeNumberContext.Provider value={tollfreeNumberContext}> */}
                {showLoadingIndicator ? (
                    <>
                        <LoadingIndicator white />
                    </>
                ) :
                    <ReactTable
                        showViewSettings
                        identifier={TableIdentifiers.TollFreeNumbers}
                        title={`${intl.formatMessage({
                            id: "tollfree.table.title",
                        })}${totalCountDisplay}`}
                        data={_.values(_.merge(_.keyBy(dataTableData, 'number'), _.keyBy(tollFreeTextStatusList, 'number')))}
                        columns={columns}
                        showExport={false}
                        showDownload={true}
                        autoResetPage={false}
                        titleClassName={isLimitExceed ? "text-danger" : ""}
                        useColumnFilters
                        dataRefreshed={dataRefreshed}
                        filterMapper={filterMapper}
                        onRefresh={toggleRefresh}
                        handleSelectAll={handleSelectAll}
                        areSelectedAll={areSelectedAll}
                        queryPageSize={pageSize}
                        queryPageIndex={pageIndex}
                        queryCount={totalCount}
                        handlePageChange={handlePageChange}
                        handlePageSizeChange={handlePageSizeChange}
                    />
                }
                {/* </TollFreeNumberContext.Provider> */}
            </Container>
        </>
    )
}

const getColumns = (
    intl: IntlShape, handleChangeSearchNumber: any,
    filter: (x: any, y: any, z: any) => void,
    customfilter: (x: any, y: any, z: any) => void,
    customTemplateFilters: (x: any, y: any, z: any) => void

) => [
        {
            Header: intl.formatMessage({ id: "tollfree.table.number" }),
            accessor: "number",
            identifier: TollFreeNumberTableColumnIdentifiers.Number,
            notConfigurable: true,
            Cell: (cell: any) => (
                <TollFreeNumbersCell
                    tollFreeNumber={cell.cell.row.original}
                    handleInputChange={handleChangeSearchNumber}
                />
            ),
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.template" }),
            accessor: "template.name",
            identifier: TollFreeNumberTableColumnIdentifiers.Template,
            Filter: DefaultTemplateColumnFilter,
            filter: customTemplateFilters,
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.notes" }),
            accessor: "notes",
            identifier: TollFreeNumberTableColumnIdentifiers.Notes,
            Cell: (cell: any) => (getValueOrEmpty(cell.cell.value) ? <IconWithTooltip
                className="fal fa-file-alt fa-lg cursor-pointer"
                tooltip={cell.cell.value}
            /> : ""
            )
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.cnam" }),
            accessor: "cnam.name",
            identifier: TollFreeNumberTableColumnIdentifiers.Cnam,
            Cell: (cell: any) => cell.cell.row.original.cnam ? getValueOrEmpty(cell.cell.row.original.cnam.name) : ""
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.donotoriginate" }),
            accessor: "doNotOriginate",
            identifier: TollFreeNumberTableColumnIdentifiers.DoNotOriginate,
            Filter: DefaultCustomColumnFilter,
            filter: customfilter,
            Cell: (cell: any) => intl.formatMessage({ id: getdno(cell.cell.value) })
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.modifyDate" }),
            accessor: "modifyDate",
            identifier: TollFreeNumberTableColumnIdentifiers.ModifyDate,
            className: "min-width-220",
            Filter: DateRangeColumnFilter,
            filter: filter,
            Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.modifyBy" }),
            accessor: "modifyName",
            identifier: TollFreeNumberTableColumnIdentifiers.ModifyBy,
            filter: "equals",
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "tollfree.reportTable.textStatus" }),
            accessor: "statusType",
            identifier: TollFreeNumberTableColumnIdentifiers.TextStatus,
            filter: "equals",
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "tollfree.reportTable.providerName" }),
            accessor: "providerName",
            identifier: TollFreeNumberTableColumnIdentifiers.ProviderName,
            filter: "equals",
            Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
        },
        {
            Header: intl.formatMessage({ id: "tollfree.table.actions" }),
            accessor: "actions",
            identifier: TollFreeNumberTableColumnIdentifiers.Actions,
            Cell: (cell: any) => (
                <>
                    <TollFreeNumbersActionsCell
                        type="numbers"
                        tollFreeNumber={cell.cell.row.original}
                    />
                </>
            )
        }
    ];

const filterMapper = (tollFreeNumberDto: TollFreeNumbersDto) => {
    return {
        ...tollFreeNumberDto,
        modifyDate: getFormattedEstDate(tollFreeNumberDto.modifyDate),
    };
};