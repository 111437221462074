import React, { useState, FormEvent, useCallback, useContext } from "react";
import { Modal, Form, Button, Row, Label, Col } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { showErrorNotification, showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
// import TextFormInput from "components/framework/forms/TextFormInput";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CloseButton from "components/framework/modals/CloseButton";
// import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
// import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
// import { RequestTollFreeNumber } from "services/apis/types/toll-free-numbers/RequestTollFreeNumber";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { TollFreeNumberContext } from "./TollFreeNumbers";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import FileUpload from "components/framework/forms/FileUpload";
import { TollFreeBulkUpdateDto } from "services/bulkUpdate/TollFreeBulkUpdateDto";
import TollFreeRealBrandModal from "./TollFreeRealBrandModal";
import Papa from "papaparse";

type Props = {
    closeModal: () => void;
};



export default function TollFreeBulkUpdateModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const [showRealBrandLoadingIndicator, setShowRealBrandLoadingIndicator] = useState(false);
    const [showTollFreeNumberLoadingIndicator, setShowTollFreeNumberLoadingIndicator] = useState(false);
    const [realBrandUpdate, setRealBrandUpdate] = useState<boolean>(false);
    const [showRealBrandModal, setShowRealBrandModal] = useState<boolean>(false);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const tollfreeNumberContext = useContext(TollFreeNumberContext);
    const closeModal = useCallback(props.closeModal, []);
    const [file, setFile] = useState<any>();

    const handleChangeFileUpload = (file: any) => {
        console.log('fileUpload', file);
        setFile(file);
    }

    const handleRealBrand = (results) => {
        if (results && results.data && results.data.length > 1) {
            const header = results.data.splice(0, 1);
            if (header && header.length > 0 && header[0] && header[0].length > 1 && header[0][1].toUpperCase() === 'CNAM') {
                const numArray = { numbers: results.data.map(x => x[0]) };
                TollFreeNumbersApi.updateRealBrand(numArray)
                    .then(() => {
                        showInfoNotification(
                            intl.formatMessage({ id: "tollFreeNumber.realBrandUpdate.successNotificationMessage" })
                        );
                        closeModal();
                    })
                    .catch((error) => {
                        handleError(error);
                        setShowRealBrandLoadingIndicator(false);
                        if (isMounted.current) {
                            const errorsResult = getFieldErrors(error.fieldErrors);
                            setErrors(errorsResult);
                        }
                        closeModal();
                    })
                    .finally(() => {
                        if (isMounted.current) {
                            setShowRealBrandLoadingIndicator(false);
                        }
                    });
            }
        }
    }

    const uploadBulkData = (convertedTollFreeBulkUpdate:any) => {
        if (convertedTollFreeBulkUpdate.length > 0) {
            const finalResultBulkUpdate = convertedTollFreeBulkUpdate[1];
            console.log('data', finalResultBulkUpdate);
            const bulk: TollFreeBulkUpdateDto = { updateFile: { bytes: finalResultBulkUpdate } };
            TollFreeNumbersApi.bulkUpdate(bulk)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "tollFreeNumber.bulkUpdate.successNotificationMessage" })
                    );
                    if (realBrandUpdate) {
                        Papa.parse(file, {
                            skipEmptyLines: true,
                            complete: function (results) {
                                handleRealBrand(results);
                            }
                        });
                    } else {
                        closeModal();
                    }
                    tollfreeNumberContext.tollFreeNumberActionCallback();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                        closeModal();
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
    }

    const bulkUpdate = () => {
        setShowLoadingIndicator(true);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            let tollFreeBulkUpdateResult: any = reader.result
            const convertedTollFreeBulkUpdate = tollFreeBulkUpdateResult.split(',')
            uploadBulkData(convertedTollFreeBulkUpdate);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const errors = validateTollFreeBulkUpdate(file);

        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
                setShowTollFreeNumberLoadingIndicator(false);
            }
        } else {
            if (file && file.type === "text/csv") {
                if (realBrandUpdate) {
                    setShowRealBrandModal(true);
                } else {
                    setShowLoadingIndicator(true);
                    bulkUpdate();
                }
            } else {
                showErrorNotification(intl.formatMessage({ id: "tollfree.bulkUpdate.errorNotificationMessage" }));
                closeModal();
            }
        }
    };

    const updateRealBrand = () => {
        setShowRealBrandLoadingIndicator(true);
        bulkUpdate();
    }

    return (
        <>
            <Modal className={"modal-dialog-centered"} isOpen={true} zIndex={2147483648}>
                <div className="modal-header">
                    <h5 className="modal-title">
                        <FormattedMessage id={"tollFreeNumber.bulkUpdate.createModalTitle"} />
                    </h5>
                    <CloseButton close={props.closeModal} />
                </div>
                {showTollFreeNumberLoadingIndicator ? (
                    <LoadingIndicator />
                ) : (
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className={"modal-body"}>
                            <div className="p-3">
                                <Row>
                                    <Label className="form-control-label">
                                        <FormattedMessage id="bulkUpdate.file" />
                                    </Label>
                                </Row>
                                <Row>
                                    <Col className="p-0">
                                        <FileUpload handleInputChange={handleChangeFileUpload} errorHandler={getErrorHandler(nameOf<any>("file"))} />
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col className="p-0">
                                        <Label>
                                            {file && file.name.length > 0 ? <FormattedMessage id={file.name} /> : <FormattedMessage id="bulkUpdate.noFile" />}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row className="my-2">
                                    <div className="col-lg-12 p-0 ul-table-header">
                                        <CheckboxFormInput
                                            className="col-lg-6 real-brand-text"
                                            label={intl.formatMessage({ id: "bulkUpdate.realBrandUpdate" })}
                                            value={"realBrandUpdate"}
                                            checked={realBrandUpdate}
                                            handleInputChange={(value: boolean) =>
                                                setRealBrandUpdate(value)
                                            }
                                        />
                                    </div>
                                </Row>
                                <Row className="mt-3">
                                    <div className="ul-table-header">
                                        <a href="TollFrees/BulkNotes.csv" download="Bulk Notes Update Sample.csv" target="_blank"><FormattedMessage id="bulkUpdate.notes.sample" /></a>
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="ul-table-header">
                                        <a href="TollFrees/BulkCNAM.csv" download="Bulk CNAM Update Sample.csv" target="_blank"><FormattedMessage id="bulkUpdate.cnam.sample" /></a>
                                    </div>
                                </Row>
                                <Row className="mt-2">
                                    <div className="ul-table-header">
                                        <a href="TollFrees/BulkDNO.csv" download="Bulk DNO Update Sample.csv" target="_blank"><FormattedMessage id="bulkUpdate.dno.sample" /></a>
                                    </div>
                                </Row>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                                <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                            </Button>
                            <Button color="primary" type="submit" disabled={showLoadingIndicator || showTollFreeNumberLoadingIndicator}>
                                {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                <FormattedMessage id="disasterRecovery.create.submitButton" />
                            </Button>
                        </div>
                    </Form>
                )
                }

            </Modal>
            {realBrandUpdate && showRealBrandModal && <TollFreeRealBrandModal closeRealBrandModal={() => setShowRealBrandModal(false)} onSubmit={() => updateRealBrand()} showLoadingIndicator={showRealBrandLoadingIndicator}/>}
        </>
    );
}

const validateTollFreeBulkUpdate = (fileName: string) => {
    const errors: Errors = {};

    if (!fileName) {
        AddError(errors, nameOf<any>("file"), "bulkUpdate.invalidFileName");
    }
    return errors;
};
