import React from "react";
import { LocalStorageInfo } from "./LocalStorageInfo";
import { AppContextUtil } from "./AppContextUtil";
import { ClientDto } from "services/apis/types/client/ClientDto";
import { UserClient } from "services/apis/types/account/UserClient";

type AppContextWrapper = {
  appContext: AppContext;
  setAppContext: (newAppContext: AppContext) => unknown;
};

export type AppContext = {
  client?: ClientDto
  localStorageInfo: LocalStorageInfo;
  selectedClient?: UserClient;
  initialized: boolean;
  showSessionExpiredError: boolean;
};

export function GetAppContext(): AppContext {
  const info: LocalStorageInfo = AppContextUtil.getFromLocalStorage();
  const UserClient: UserClient = AppContextUtil.getClientFromLocalStorage();

  return {
    localStorageInfo: info,
    selectedClient: UserClient,
    initialized: false,
    showSessionExpiredError: false
  };
}

export const AppContext = React.createContext<AppContextWrapper>({
  appContext: GetAppContext(),
  setAppContext: () => { }
});
