import React from "react";
import { FormGroup, Label } from "reactstrap";
import { useIntl } from "react-intl";
import classNames from "classnames";
import InputError from "components/framework/errorHandling/InputError";
import { ErrorHandler } from "services/customHooks/useErrors";
import InfoTooltip from "components/common/InfoTooltip";

export type TextFormInputProps = {
    readonly?: boolean;
    labelTranslationId?: string;
    value?: string | number;
    errorHandler?: ErrorHandler;
    formGroupClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    placeholderTranslationId?: string;
    lg?: boolean;
    required?: boolean;
    handleInputChange?: (value: string) => void;
    onClick?: (value: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    tooltip?: string | React.ReactNode;
};

export default function TextFormError(props: TextFormInputProps) {
    const intl = useIntl();
    // const errors = props.errorHandler ? props.errorHandler.getErrors() : [];
    const label = !props.labelTranslationId
        ? undefined
        : intl.formatMessage({ id: props.labelTranslationId });
    // const placeholder = props.readonly
    //     ? ""
    //     : !props.placeholderTranslationId
    //         ? undefined
    //         : intl.formatMessage({ id: props.placeholderTranslationId });

    return (
        <FormGroup className={classNames("mb-2", props.formGroupClassName)}>
            {label && (
                <Label
                    className={classNames("form-control-label", props.labelClassName, {
                        "pc-required-label": props.required
                    })}>
                    {label}
                    {props.tooltip && <InfoTooltip tooltip={props.tooltip} />}
                </Label>
            )}
            {props.errorHandler && <InputError errors={props.errorHandler.getErrors()} />}
        </FormGroup>
    );
}
