import { HasFieldErrors } from "components/framework/errorHandling/ErrorUtil";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { TradingPartnerApi } from "services/apis/TradingPartnerApi";
import { TradingPartnerProfile } from "services/apis/types/tpp/TradingPartnerProfile";
import { AppContext } from "services/appContext/AppContext";
import { handleFieldErrors, handleError } from "services/util/ApiUtil";

export default function useTpp(spId: string, serviceProviderAccessToken: string) {
  const [tpp, setTpp] = useState<TradingPartnerProfile>();
  const { appContext } = useContext(AppContext);

  const fetchTpp = React.useCallback(() => {
    TradingPartnerApi.get(spId, serviceProviderAccessToken)
      .then((tpp) => setTpp(tpp))
      .catch((errors) => {
        if (HasFieldErrors(errors)) {
          handleFieldErrors(errors);
        } else if (errors && errors.error !== 404) {
          handleError(errors);
        }

        setTpp(undefined);
      });
    
  }, [serviceProviderAccessToken,spId]);

  useEffect(() => {
    if (spId) {
      fetchTpp();
    }
  }, [spId, appContext.localStorageInfo.selectedProfile, fetchTpp]);

  return { tpp, fetchTpp };
}
