export enum TollFreeNumberTableColumnIdentifiers {
  Number = "number",
  Template = "template",
  TemplateId = "templateId",
  Notes = "notes",
  Cnam = "cnam",
  DoNotOriginate = "doNotOriginate",
  ModifyDate = "modifyDate",
  ModifyBy = "modifyName",
  TextStatus = "textStatus",
  ProviderName = "providerName",
  Actions = "actions"
}