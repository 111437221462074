import translations from "translations";
import { AuthenticationInfo, DefaultAuthenticationInfo } from "./AuthenticationInfo";
import { UserDto } from "services/apis/types/account/UserDto";
import { UserClient } from "services/apis/types/account/UserClient";
import { UserRole } from "services/apis/types/account/UserRole";
import { SpIdProfileDto } from "services/apis/types/account/SpIdProfileDto";

export type LocalStorageInfo = {
  authenticationInfo: AuthenticationInfo;
  language: keyof typeof translations;
  user?: UserDto;
  userClients?: UserClient[];
  userRoles?: UserRole[];
  selectedProfile?: SpIdProfileDto;
  sendNotifications?: boolean;
};

export const DefaultLocalStorageInfo: LocalStorageInfo = {
  authenticationInfo: DefaultAuthenticationInfo,
  language: "en"
};
