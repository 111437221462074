export enum ActitivtyStatusType {
    complete = "Complete",
    pendingProcessor = "PendingProcessor",
    pendingValidation = "PendingValidation",
    pendingReview = "PendingReview",
    pendingAssign = "PendingAssign",
    pendingPort = "PendingPort",
    pendingDelete = "PendingDelete",
    cancel = "Cancel"
}
