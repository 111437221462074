import React, { useState, useRef, FormEvent, useEffect, useContext } from "react";
import { CardBody, Card, Col, Row, CardHeader, Button, Form, CardFooter } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { ChangePasswordDto, ChangePasswordDefault } from "services/apis/types/identity/ChangePasswordDto";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { Errors, AddError, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { getFieldErrors, handleError } from "services/util/ApiUtil";
import { IdentityApi } from "services/apis/IdentityApi";
import PasswordFormInput from "components/framework/forms/PasswordFormInput";
import { AppContext } from "services/appContext/AppContext";

export default function ChangePasswordCard() {
  const { setErrors, getErrorHandler } = useErrors();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const [changePassword, setChangePassword] = useState<ChangePasswordDto>(ChangePasswordDefault);
  const isMounted = useRef(true);
  const intl = useIntl();
  const { appContext } = useContext(AppContext);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setShowLoadingIndicator(true);

    const errors = validateModel(changePassword);

    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else if (appContext.localStorageInfo.user) {
      IdentityApi.changePassword(appContext.localStorageInfo.user?.id,
        {
          email: appContext.localStorageInfo.user?.email,
          firstName: appContext.localStorageInfo.user.firstName,
          lastName: appContext.localStorageInfo.user.lastName,
          phone: appContext.localStorageInfo.user.phone,
          username: appContext.localStorageInfo.user.username,
          sendTextNotifications: appContext.localStorageInfo.user.sendTextNotifications,
          requireMultiFactorAuth: appContext.localStorageInfo.user.requireMultiFactorAuth,
          password: changePassword.newPassword,
          enabled: appContext.localStorageInfo.user.enabled
        })
        .then(() => {
          showInfoNotification(intl.formatMessage({ id: "changePasswordCard.successMessage" }));
        })
        .catch(error => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  return (
    <Card>
      <CardHeader>
        <Row className="align-items-center">
          <Col xs="12">
            <h3 className="mb-0">
              <FormattedMessage id="changePasswordCard.changePassword" />
            </h3>
          </Col>
        </Row>
      </CardHeader>
      <Form onSubmit={e => handleSubmit(e)}>
        <CardBody>
          {/* <Row>
            <PasswordFormInput
              className="col-md-6 col-lg-10"
              labelTranslationId="changePasswordCard.currentPassword"
              value={changePassword.currentPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, currentPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePasswordDto>("currentPassword"))}
            />
          </Row> */}
          <Row>
            <PasswordFormInput
              className="col-md-6 col-lg-10"
              labelTranslationId="changePasswordCard.newPassword"
              value={changePassword.newPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, newPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePasswordDto>("newPassword"))}
            />
          </Row>
          <Row>
            <PasswordFormInput
              className="col-md-6 col-lg-10"
              labelTranslationId="changePasswordCard.confirmNewPassword"
              value={changePassword.confirmPassword}
              handleInputChange={(value: string) => setChangePassword({ ...changePassword, confirmPassword: value })}
              errorHandler={getErrorHandler(nameOf<ChangePasswordDto>("confirmPassword"))}
            />
          </Row>
        </CardBody>
        <CardFooter className="text-right">
          <Button color="primary" type="submit" className="ml-auto" disabled={showLoadingIndicator}>
            {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
            <FormattedMessage id="changesPasswordCard.button" />
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
}

const validateModel = (changePassword: ChangePasswordDto) => {
  const errors: Errors = {};

  // if (changePassword.currentPassword.length === 0) {
  //   AddError(errors, nameOf<ChangePasswordDto>("currentPassword"), "changePasswordCard.emptyCurrentPassword");
  // }

  if (changePassword.newPassword.length === 0) {
    AddError(errors, nameOf<ChangePasswordDto>("newPassword"), "changePasswordCard.emptyNewPassword");
  }

  if (changePassword.confirmPassword.length === 0) {
    AddError(errors, nameOf<ChangePasswordDto>("confirmPassword"), "changePasswordCard.emptyNewPassword");
  }

  if (
    changePassword.newPassword &&
    changePassword.confirmPassword &&
    changePassword.newPassword !== changePassword.confirmPassword
  ) {
    AddError(errors, nameOf<ChangePasswordDto>("confirmPassword"), "changePasswordCard.passwordsDoNotMatch");
  } 
  return errors;
};
