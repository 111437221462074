import React from "react";
import DateTimeInput from "../forms/DateTimeInput";
import { sort } from "services/util/DateUtil";
import { getEstDate, isBetween  } from "services/util/EstDateUtil";

type Props = {
  column: {
    filterValue: Array<Date | undefined>;
    preFilteredRows: Array<any>;
    setFilter: React.Dispatch<React.SetStateAction<Array<Date | undefined>>>;
    id: string;
  };
};

export default function DateRangeColumnFilter(props: Props) {
  const [min, max] = React.useMemo(() => {
    const values = props.column.preFilteredRows.length
      ? props.column.preFilteredRows.map((x) => x.values[props.column.id])
      : [];
    let min = getEstDate(values.length ? (sort(values as Array<Date>)[0] as Date) : undefined);
    let max = getEstDate(
      values.length ? (sort(values as Array<Date>, true)[0] as Date) : undefined
    );
    return [min, max];
  }, [props.column.id, props.column.preFilteredRows]);

  return (
    <div className="d-flex">
      <DateTimeInput
        value={(props.column.filterValue && getEstDate(props.column.filterValue[0])) || min}
        onChange={(val) => {
          props.column.setFilter([
            val?.toDate(),
            props.column.filterValue ? props.column.filterValue[1] : max.toDate()
          ]);
        }}
        isValidDate={(currentDate) => {
          let isValid = true;
          if (!isBetween(currentDate, min, max)) {
            isValid = false;
          }
          if (
            props.column.filterValue &&
            !currentDate.isSameOrBefore(props.column.filterValue[1])
          ) {
            isValid = false;
          }
          return isValid;
        }}
      />
      <span className="m-1">to</span>
      <DateTimeInput
        value={(props.column.filterValue && getEstDate(props.column.filterValue[1])) || max}
        onChange={(val) => {
          props.column.setFilter([
            props.column.filterValue ? props.column.filterValue[0] : min.toDate(),
            val?.toDate()
          ]);
        }}
        isValidDate={(currentDate) => {
          let isValid = true;
          if (!isBetween(currentDate, min, max)) {
            isValid = false;
          }
          if (props.column.filterValue && !currentDate.isSameOrAfter(props.column.filterValue[0])) {
            isValid = false;
          }
          return isValid;
        }}
      />
    </div>
  );
}

export const dateRangefilterFunction = (rows: any[], accessor: any, filterValues: any) =>
  rows.filter((x) => {
    return (
      x.values[accessor] &&
      isBetween(getEstDate(x.values[accessor]), filterValues[0], filterValues[1])
    );
  });
