import React, { useEffect } from "react";
import LoginLayout from "components/framework/loginLayout/LoginLayout";
import LoginHeader from "components/framework/loginLayout/LoginHeader";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";

export default function Login() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <LoginLayout>
      <LoginHeader title="" />
      <LoadingIndicator className="center" white />
    </LoginLayout>
  );
}