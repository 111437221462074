import React, { useMemo } from "react";
import { useIntl, IntlShape } from "react-intl";
import ReactTable from "components/framework/table/ReactTable";
import { getValueOrEmpty } from "services/util/StringUtil";
import { ActivityDto } from "services/activities/ActivityDto";
import FormatDateTime from "components/framework/date/FormatDateTime";

type Props = {
    activities: ActivityDto[];
    status: string;
};

export default function ActivityCard(props: Props) {
    const intl = useIntl();
    const columns = useMemo(() => getColumns(intl), [intl]);

    const statusClassMapping: { [key in string]: string } = {
        "initiated": "danger-header border-top-radius",
        "pending": "warning-header  border-top-radius",
        "none": "success-header border-top-radius",
    };

    return (
        <ReactTable
            title={intl.formatMessage({ id: "activities.table.title" })}
            data={props.activities}
            hideSearch
            headerClassName={props.status ? statusClassMapping[props.status] : ""}
            titleClassName={props.status ? "text-white" : ""}
            initialPageSize={5}
            columns={columns}
        />
    );
}

const getColumns = (intl: IntlShape) => [
    {
        Header: intl.formatMessage({ id: "activities.table.description" }),
        accessor: "description",
        className: "text-break",
        Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
    },
    {
        Header: intl.formatMessage({ id: "activities.table.modifyDate" }),
        accessor: "modifyDate",
        Cell: (cell: any) => <FormatDateTime utcDate={cell.cell.value} />
    },
    {
        Header: intl.formatMessage({ id: "activities.table.statusType" }),
        accessor: "statusTypeDescription",
        Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
    },
    {
        Header: intl.formatMessage({ id: "activities.table.modifyName" }),
        accessor: "modifyName",
        Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
    },
];
