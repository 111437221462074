import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { RequestSettingsNoficationsDto } from "./types/settings/RequestSettingsNotificationsDto";
import { SettingsNotificationDto } from "./types/settings/SettingsNotificationDto";
import { UserDetailsDto } from "./types/settings/UserDetailsDto";

export const SettingsApi = {
    put: (userId: string | undefined, enableNotifications: RequestSettingsNoficationsDto[]): Promise<ApiError> => {
        return HttpClient.put<ApiError>(`users/${userId}/activityKinds`, enableNotifications, true, true);
    },
    get: (userId: string | undefined): Promise<SettingsNotificationDto[]> => {
        return HttpClient.get<SettingsNotificationDto[]>(`users/${userId}/activityKinds`, true, true);
    },
    getUserDetails: (userId: number | undefined): Promise<UserDetailsDto> => {
        return HttpClient.get<UserDetailsDto>(`users/${userId}`, true, true);
    },
    putUserDetails: (userId: number | undefined, userDetails: UserDetailsDto): Promise<ApiError> => {
        return HttpClient.put<ApiError>(`users/${userId}`, userDetails, true, true);
    },
};
