import { PermissionType } from "./PermissionType";

export const Permissions: Record<keyof typeof PermissionType, string> = {
  GlobalAdmin: "GlobalAdmin",
  AssignTollFrees: "AssignTollFrees",
  EditTemplates: "AssignTollFrees",
  EditUsers: "EditUsers",
  InsertTollFrees: "InsertTollFrees",
  ReleaseTollFrees: "ReleaseTollFrees",
  AssignReserved: "AssignReserved",
  ReserveTollFrees: "ReserveTollFrees",
  ViewTemplates: "ViewTemplates",
  DisasterRecovery: "DisasterRecovery",
  RespOrgChange: "RespOrgChange",
  QueryCnam: "QueryCnam"
};
