// import { UserSettingsDto } from "./UserSettingsDto";

export type UpdateAccountDto = {
  email: string;
  firstName: string;
  lastName: string;
  // locale: string;
  // zoneInfo: string;
  // userSettings: UserSettingsDto;
  phone: string;
  username: string;
  sendTextNotifications: boolean;
  requireMultiFactorAuth: boolean;
  password?: string
  enabled: boolean;
};

export const DefaultUpdateAccountDto: UpdateAccountDto = {
  email: "",
  firstName: "",
  lastName: "",
  // userSettings: {},
  phone: "",
  username: "",
  sendTextNotifications: false,
  requireMultiFactorAuth: false,
  enabled: false,
};
