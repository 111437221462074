import React, { useContext } from "react";
import { authorizeAny } from "services/authorization/AuthorizationService";
import { AppContext } from "services/appContext/AppContext";
import { PermissionType } from "services/authorization/PermissionType";

type Props = {
  userPermissions?: PermissionType[];
  children: React.ReactNode;
};

export default function Authorize(props: Props) {
  const { appContext } = useContext(AppContext);

  var isAuthorized =
    (!props.userPermissions &&
      appContext.localStorageInfo.authenticationInfo.authenticated) ||
    (props.userPermissions && authorizeAny(props.userPermissions));

  if (!appContext.selectedClient) {
    isAuthorized = false;
  }


  return <>{isAuthorized && props.children}</>;
}
