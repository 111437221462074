import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { ServiceProviderProfile } from "./types/serviceProvider/ServiceProviderProfile";
import { SpInfo } from "./types/serviceProvider/SpInfo";

export const ServiceProviderApi = {
  get: (id: string, serviceProviderAccessToken: string): Promise<SpInfo> => {
    return HttpClient.getPortControl<SpInfo>(`serviceprovider/${id}`, serviceProviderAccessToken);
  },
  list: (): Promise<SpInfo[]> => {
    return HttpClient.get<SpInfo[]>("serviceprovider");
  },
  addOrUpdate: (serviceProvider: ServiceProviderProfile, serviceProviderAccessToken: string): Promise<ApiError> => {
    return HttpClient.putPortControl<ApiError>(
      `serviceprovider/${serviceProvider.spId}`,
      serviceProvider, serviceProviderAccessToken
    );
  },
  getLogo: (id: string, token: string): Promise<Blob | ApiError> => {
    return HttpClient.getResource(`serviceprovider/${id}/logo`, token);
  },
  uploadLogo: (id: string, file: File): Promise<Blob | ApiError> => {
    const data = new FormData();
    data.append("image", file);
    return HttpClient.putResource(`serviceprovider/${id}/logo`, data);
  },
};
