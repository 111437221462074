import React from "react";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import { Table } from "reactstrap";
import { useIntl } from "react-intl";
import classnames from "classnames";
import FormatDateTime from "components/framework/date/FormatDateTime";

type Props = {
    showCarrierLoadingIndicator: boolean;
    carriersList: CarrierDto[];
    region: string;
};

export default function OutageDetailsModal(props: Props) {
    const intl = useIntl();
    return (
        <>
            {props.showCarrierLoadingIndicator ? (
                <LoadingIndicator />
            ) : (
                // <Table borderless size="sm">
                //     <thead>
                //         <tr>
                //             <th className="ul-table-header">
                //                 {props.region}
                //             </th>
                //         </tr>
                //     </thead>
                //     <ul style={{ listStyleType: "none" }}>
                //         <li>
                //             <p className="li-outage mt-2"><span className="ml-2">{intl.formatMessage({ id: "outage.cicCode" })}</span><span className="ml-4">{intl.formatMessage({ id: "outage.carrierName" })}</span><span className="ml-6">{intl.formatMessage({ id: "outage.lastReportedDate" })}</span></p>
                //         </li>
                //     </ul>
                //     {props.carriersList && props.carriersList.map((carrierObj) => {
                //         const date = new Date(carrierObj.insertDate);
                //         const formattingDate = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                //         return (
                //             <ul className="list-style-type:circle; ul-outage">
                //                 <li>
                //                     <p className="li-outage mt-2"><span className="ml-2">{carrierObj.cic}</span><span className="ml-4">{carrierObj.name}</span><span className="ml-4">{formattingDate}</span></p>
                //                 </li>

                //             </ul>
                //         )
                //     })}
                // </Table>
                <div
                    className={`table-responsive ${classnames({
                        "pc-overflow-x-auto": !false
                    })}`}
                >
                    <Table responsive className={"table"} size="sm">
                        <thead>
                            <tr>
                                <th className="ul-table-header border-0">
                                    {props.region}
                                </th>
                            </tr>
                        </thead>
                        <thead className="thead-light">
                            <tr key={"tr_outage"}>
                                <th key={1}>{intl.formatMessage({ id: "outage.cicCode" })}</th>
                                <th key={2}>{intl.formatMessage({ id: "outage.carrierName" })}</th>
                                <th key={3}>{intl.formatMessage({ id: "outage.notes" })}</th>
                                <th key={3}>{intl.formatMessage({ id: "outage.lastReportedDate" })}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.carriersList && props.carriersList.map((carrierObj) => {
                                return (
                                    <tr><td>
                                        <ul className="pl-3 list-style-type:circle; ul-outage">
                                            <li>
                                                <p className="li-outage"><span className="ml-2">{carrierObj.cic}</span></p>
                                            </li>

                                        </ul>
                                    </td>
                                        <td>{carrierObj.name}</td>
                                        <td>{carrierObj.notes}</td>
                                        <td><FormatDateTime utcDate={carrierObj.modifyDate ? carrierObj.modifyDate : carrierObj.insertDate} /></td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </Table></div>
            )}
        </>
    );
}

