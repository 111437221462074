export type RequestTollFreeReserve = {
    numbers: string[];
    templateId: number;
    provisionIds: string[];
    notes: string;
    reserveType: string;
    //quantity: string;
};

export enum ReserveType {
    Specific = "Specific",
    Pattern = "Pattern"
}

export enum PoolType {
    Somos = "Somos",
    Prime = "Prime",
}