import React from "react";
import { toast, ToastOptions} from "react-toastify";
import { css } from "glamor";
import NotificationCloseButton from "./NotificationCloseButton";
import { AppContextAccessor } from "services/appContext/AppContextAccessor";
import classNames from "classnames";


const toastOptions: ToastOptions = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  autoClose: 5000,
  bodyClassName: css({
    fontFamily: "Open Sans, sans-serif",
    color: "white"
  }),
  closeButton: <NotificationCloseButton />,
  closeOnClick: false
};

export function showInfoNotification(
  message: string,
  customToastOptions?: ToastOptions,
  largeText?: boolean
) {
  if (AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.authenticated)
    toast(message, {
      ...toastOptions,
      className: classNames("rounded text-white bg-info p-3", {
        "text-sm": !largeText
      }),
      ...customToastOptions
    });
}

export function showErrorNotification(message: string) {
  if (AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.authenticated)
    toast(<Msg message= {message}/>, { ...toastOptions, className: "rounded text-white bg-danger text-sm p-3"});
}

export function showErrorNotificationNonAuthenticated(message: string) {
  toast(message, { ...toastOptions, className: "rounded text-white bg-danger text-sm p-3" });
}

export interface MsgProps {
  message:string;
}

const Msg = (props: MsgProps)=> {
   return <div>
     { props.message.split("\r\n")
      .map(messageLine=> (<div>{messageLine}</div>))
     }
   </div>
};