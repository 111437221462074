import {
  AddError,
  Errors,
  HasErrors,
} from "components/framework/errorHandling/ErrorUtil";
import DateTimeFormInput from "components/framework/forms/DateTimeFormInput";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import TextFormInput from "components/framework/forms/TextFormInput";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import moment, { Moment } from "moment";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Card, CardFooter, Form, Label } from "reactstrap";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { ServiceProviderProfile } from "services/apis/types/serviceProvider/ServiceProviderProfile";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";
import { useErrors } from "services/customHooks/useErrors";
import useServiceProvider from "services/customHooks/useServiceProvider";
import useTpp from "services/customHooks/useTpp";
import {
  getFieldErrors,
  handleError,
  handleFieldOrApiErrors,
} from "services/util/ApiUtil";
import { getEstDate } from "services/util/EstDateUtil";
import { nameOf } from "services/util/ObjectUtil";
import { isEmail, isEmailOrUrl } from "services/util/StringUtil";
import { ProcessingTimeType } from "../types/ProcessingTimeType";
import { ServiceProviderProfileResult } from "../types/ServiceProviderProfileResult";
import ContactInformation from "./ContactInformation";
import TradingPartnerProfileCardFooterButtons from "./ExploreProfileCardFooter";
import ExploreProfileCardHeader from "./ExploreProfileCardHeader";
import LnpInformation from "./LnpInformation";
import OperationsInformation from "./OperationsInformation";

type Props = {
  serviceProviderResult: ServiceProviderProfileResult;
  cardIsUsedInModal?: boolean;
  closeModalOnCancel?: () => void;
  serviceProviderAccessToken: string;
};

export default function ExploreProfileCard(props: Props) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const { setErrors, getErrorHandler } = useErrors();
  const [serviceProvider, setServiceProvider] =
    useState<ServiceProviderProfile>({
      ...props.serviceProviderResult.contactInformation,
      lnpSpeed:
        props.serviceProviderResult.contactInformation?.lnpSpeed ??
        ProcessingTimeType.Instant,
      spId: props.serviceProviderResult.spId,
    });
  const [userIsAdmin] = useState(authorize(PermissionType.GlobalAdmin));
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { tpp, fetchTpp } = useTpp(props.serviceProviderResult.spId, props.serviceProviderAccessToken);
  const [triggerRefreshLogo, setTriggerRefreshLogo] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [validationChanged, setValidationChanged] = useState(false);
  const { fetchServiceProvider } = useServiceProvider(props.serviceProviderResult.spId, props.serviceProviderAccessToken);
  const intl = useIntl();
  const isMounted = useRef(true);
  console.log('hsfks', userIsAdmin);

  const editMode = !readOnlyMode;


  useEffect(() => {
    setErrors({});
  }, [validationChanged, setErrors]);

  useEffect(() => {
    if (!props.serviceProviderResult.spId) {
      setCreateMode(true);
      setReadOnlyMode(false);
    }

    return () => {
      isMounted.current = false;
    };
  }, [props.serviceProviderResult]);

  const handleSubmit = (e: FormEvent) => {
    // if (userIsAdmin) {
    e.preventDefault();
    setShowLoadingIndicator(true);
    const errors = validateServiceProvideProfile(serviceProvider, createMode);
    if (HasErrors(errors)) {
      setErrors(errors);
      if (isMounted.current) {
        setShowLoadingIndicator(false);
      }
    } else {
      ServiceProviderApi.addOrUpdate(serviceProvider, props.serviceProviderAccessToken)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "tppModal.successNotificationMessage" })
          );
          fetchServiceProvider();
          setReadOnlyMode(true);
        })
        .catch((error) => {
          handleError(error);
          if (isMounted.current) {
            const errorsResult = getFieldErrors(error.fieldErrors);
            setErrors(errorsResult);
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setShowLoadingIndicator(false);
          }
        });
    }
  };

  const handleUploadLogo = (file?: File) => {
    if (file && !createMode) {
      ServiceProviderApi.uploadLogo(serviceProvider.spId, file)
        .then(() => {
          showInfoNotification(
            intl.formatMessage({ id: "tppModal.logo.uploadSuccessfullMessage" })
          );
          setTriggerRefreshLogo((p) => !p);
        })
        .catch((error) => handleFieldOrApiErrors(error));
    }
  };

  return (
    <div>
      {(props.serviceProviderResult && props.serviceProviderResult.spId) ? (
        <Card className="mb-0">
          <ExploreProfileCardHeader
            serviceProviderResult={props.serviceProviderResult}
            triggerRefreshLogo={triggerRefreshLogo}
            createMode={createMode}
            readOnlyMode={readOnlyMode}
            tpp={tpp}
            setReadOnlyMode={setReadOnlyMode}
            serviceProviderAccessToken={props.serviceProviderAccessToken}
          />
          <Form onSubmit={(e) => handleSubmit(e)}>
            <div className="card-header pb-0">
              {createMode && (
                <TextFormInput
                  readonly={readOnlyMode}
                  labelTranslationId="tppModal.spidId"
                  value={serviceProvider.spId}
                  required
                  handleInputChange={(value: string) =>
                    setServiceProvider((previousState) => {
                      if (
                        (previousState.spId.length === 4 && value.length === 5) ||
                        (previousState.spId.length === 5 && value.length === 4)
                      )
                        setValidationChanged((previous) => !previous);
                      return { ...serviceProvider, spId: value };
                    })
                  }
                  errorHandler={getErrorHandler(
                    nameOf<ServiceProviderProfile>("spId")
                  )}
                />
              )}
              {userIsAdmin && editMode && (
                <div className="d-flex">
                  <div className="flex-basis-33 pr-2">
                    <Label className="form-control-label">
                      <FormattedMessage id="tppModal.logo" />
                    </Label>
                    <div className="custom-file mb-2">
                      <input
                        type="file"
                        className="custom-file-input"
                        lang="en"
                        onChange={(e) => handleUploadLogo(e.target.files?.[0])}
                      />
                      <label className="custom-file-label">
                        <FormattedMessage id="tppModal.logo.placeholder" />
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div className="flex-basis-33 mr-2">
                  <LnpInformation
                    readonly={readOnlyMode}
                    serviceProvider={serviceProvider}
                    isRespOrgId={serviceProvider.spId.length === 5}
                    setServiceProvider={setServiceProvider}
                    getErrorHandler={getErrorHandler}
                  />
                </div>
                <div className="flex-basis-33 mr-2">
                  <OperationsInformation
                    readonly={readOnlyMode}
                    serviceProvider={serviceProvider}
                    setServiceProvider={setServiceProvider}
                    getErrorHandler={getErrorHandler}
                  />
                </div>
                <div className="flex-basis-33">
                  <ContactInformation
                    readonly={readOnlyMode}
                    serviceProvider={serviceProvider}
                    setServiceProvider={setServiceProvider}
                    getErrorHandler={getErrorHandler}
                  />
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="d-flex flex-column">
                <div className="flex-grow-1">
                  <div className="d-flex flex-direction-column">
                    <TextAreaFormInput
                      labelTranslationId="tppModal.notes"
                      placeholderTranslationId="tppModal.notes.placeholder"
                      rows={1}
                      disabled={readOnlyMode}
                      value={serviceProvider.notes}
                      handleInputChange={(value: string) =>
                        setServiceProvider({ ...serviceProvider, notes: value })
                      }
                      errorHandler={getErrorHandler(
                        nameOf<ServiceProviderProfile>("notes")
                      )}
                    />
                  </div>
                </div>
                {!readOnlyMode && userIsAdmin && (
                  <div className="d-flex">
                    <DateTimeFormInput
                      className="mr-3"
                      labelTranslationId="tppModal.verifiedOn"
                      disabled={readOnlyMode}
                      value={
                        serviceProvider.verifiedDate
                          ? moment(serviceProvider.verifiedDate)
                          : undefined
                      }
                      showModalOnTop
                      handleInputChange={(value: Moment) =>
                        setServiceProvider({
                          ...serviceProvider,
                          verifiedDate: getEstDate(value.toDate()).toDate(),
                        })
                      }
                      errorHandler={getErrorHandler(
                        nameOf<ServiceProviderProfile>("verifiedDate")
                      )}
                    />
                    <i
                      className="far fa-calendar-times form-control-sm mb-2 align-self-end cursor-pointer"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Clear"
                      onClick={() =>
                        setServiceProvider({
                          ...serviceProvider,
                          verifiedDate: null,
                        })
                      }
                    ></i>
                  </div>
                )}
              </div>
            </div>
            {!readOnlyMode && (
              <div className="modal-footer justify-content-end">
                <Button
                  color="primary"
                  className="btn btn-outline-default"
                  type="button"
                  onClick={() => {
                    if (props.closeModalOnCancel) props.closeModalOnCancel();
                    else {
                      setServiceProvider({
                        ...props.serviceProviderResult.contactInformation,
                        spId: props.serviceProviderResult.spId,
                      });
                      setReadOnlyMode(true);
                      setErrors({});
                    }
                  }}
                >
                  <FormattedMessage id="tppModal.cancelButton" />
                </Button>
                  <Button
                    color="primary"
                    type="submit"
                    disabled={showLoadingIndicator}
                  >
                    {showLoadingIndicator && (
                      <i className="fas fa-spinner fa-spin mr-2" />
                    )}
                    <FormattedMessage id="tppModal.submitButton" />
                  </Button>
              </div>
            )}
          </Form>
          {readOnlyMode && (
            <CardFooter className="pc-flex-button-container">
              <TradingPartnerProfileCardFooterButtons
                requestedTradeDate={tpp ? tpp.requestedTradeDate : undefined}
                spid={serviceProvider.spId}
                serviceProvider={serviceProvider}
                requestTradingPartnerCallback={fetchTpp}
              />
            </CardFooter>
          )}
        </Card>
      ) : (<><LoadingIndicator /></>)}
    </div>
  );
}

const validateServiceProvideProfile = (
  serviceProvider: ServiceProviderProfile,
  createMode: boolean
) => {
  const errors: Errors = {};

  if (createMode && !serviceProvider.spId) {
    AddError(
      errors,
      nameOf<ServiceProviderProfile>("spId"),
      "tppModal.emptySpid"
    );
  }

  if (serviceProvider.spId.length === 5) {
    if (!serviceProvider.lnpEmail)
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lnpEmail"),
        "tppModal.lnpEmail.required"
      );

    if (!serviceProvider.lnpPhoneNumber) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lnpPhoneNumber"),
        "tppModal.lnpPhoneNumber.required"
      );
    }
  } else {
    if (!serviceProvider.lsrLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lsrLocation"),
        "tppModal.lsrLocation.required"
      );
    }

    if (!serviceProvider.csrLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("csrLocation"),
        "tppModal.csrLocation.required"
      );
    }

    if (!serviceProvider.lnpTppLocation) {
      AddError(
        errors,
        nameOf<ServiceProviderProfile>("lnpTppLocation"),
        "tppModal.lnpTppLocation.required"
      );
    }
  }

  if (serviceProvider.lnpEmail && !isEmail(serviceProvider.lnpEmail)) {
    AddError(
      errors,
      nameOf<ServiceProviderProfile>("lnpEmail"),
      "tppModal.notAnEmail"
    );
  }

  if (
    serviceProvider.lnpTppLocation &&
    !isEmailOrUrl(serviceProvider.lnpTppLocation)
  ) {
    AddError(
      errors,
      nameOf<ServiceProviderProfile>("lnpTppLocation"),
      "tppModal.notAnEmailOrUrl"
    );
  }

  if (
    serviceProvider.csrLocation &&
    !isEmailOrUrl(serviceProvider.csrLocation)
  ) {
    AddError(
      errors,
      nameOf<ServiceProviderProfile>("csrLocation"),
      "tppModal.notAnEmailOrUrl"
    );
  }

  if (
    serviceProvider.lsrLocation &&
    !isEmailOrUrl(serviceProvider.lsrLocation)
  ) {
    AddError(
      errors,
      nameOf<ServiceProviderProfile>("lsrLocation"),
      "tppModal.notAnEmailOrUrl"
    );
  }

  return errors;
};
