import React, { useState, FormEvent, useEffect, useContext, useCallback } from "react";
import { Modal, Form, Button, Row, FormGroup, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import classNames from "classnames";
import { AppContext } from "services/appContext/AppContext";
import { ClientApi } from "services/apis/ClientApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { ActivityApi } from "services/apis/ActivityApi";

type Props = {
    selectedTollFreeNumbers?: any;
    refreshCheckbox?: any;
    closeModal: () => void;
    tollFreeNumber?: TollFreeNumbersDto;
    reportTypeSelection?: any;
    reportFees?: any;
};

export default function TollFreeQueryReportModal(props: Props) {
    const reportType = [{ key: 'CNAM', value: 'CNAM', id: 4, status: false }, { key: 'Fraud Score', value: 'Fraud Score', id: 5, status: false }]
    const { getErrorHandler } = useErrors();
    const [selectedReportType, setSelectedReportType] = useState<any>(reportType);
    const [showLoadingIndicator, setShowLoadingIndicator] = useState<boolean>(false);
    const { appContext } = useContext(AppContext);
    const isMounted = useIsMounted();
    const intl = useIntl();
    const [showFeesModal, setShowFeesModal] = useState(false);
    const [showFraudFeesModal, setShowFraudFeesModal] = useState(false);
    const [showBothFeesModal, setShowBothFeesModal] = useState(false);
    const [cnamFees, setCnamFees] = useState(0);
    const [fraudFees, setFraudFees] = useState(0);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!isNaN(cnamFees) && cnamFees > 0 && selectedReportType.some(x => x.key === "CNAM" && x.status === true) && !selectedReportType.every(x => x.status === true)) {
            setShowFeesModal(true);
            props.reportFees(cnamFees, fraudFees, selectedReportType)
            return;
        }
        else if (!isNaN(fraudFees) && fraudFees > 0 && selectedReportType.some(x => x.key === "Fraud Score" && x.status === true) && !selectedReportType.every(x => x.status === true)) {
            setShowFraudFeesModal(true);
            props.reportFees(cnamFees, fraudFees, selectedReportType)
            return;
        }
        else if (!isNaN(cnamFees) && cnamFees > 0 && !isNaN(fraudFees) && fraudFees > 0 && selectedReportType.every(x => x.status === true)) {
            setShowBothFeesModal(true);
            props.reportFees(cnamFees, fraudFees, selectedReportType)
            return;
        }
        setShowLoadingIndicator(true);
        props.reportTypeSelection(selectedReportType, props.selectedTollFreeNumbers);
        props.closeModal();
    };

    const handleSelectedReportTypeChanges = (reportType: any, index: number) => {
        const newSelectedReportType = [...selectedReportType];
        newSelectedReportType[index] = reportType;
        if (reportType.status) {
            getErrorHandler(nameOf<any>("reportType")).clear();
        }
        setSelectedReportType(newSelectedReportType);
    }

    const getCNAMFees = useCallback(() => {
        ActivityApi.getCnamFees()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setCnamFees(result.fee);
                    console.log('getCNAMFees', result)
                }
            })
            .catch((error) => {
                console.log('error on cnamFees ', error)
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [isMounted]);

    const getCNAMFeesKind = useCallback(() => {
        ClientApi.getCnamFeesByKind()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setCnamFees(result.fee);
                    console.log('fees', result)
                }
            })
            .catch((error) => {
                console.log('error on cnamFeesbyKind ', error)
                getCNAMFees();
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [getCNAMFees, isMounted]);

    const getFraudFees = useCallback(() => {
        ActivityApi.getFraudFees()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setFraudFees(result.fee);
                    console.log('getFraudFees', result)
                }
            })
            .catch((error) => {
                console.log('error on fraudFees ', error)
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [isMounted]);

    const getFraudFeesKind = useCallback(() => {
        ClientApi.getFraudFeesByKind()
            .then((result) => {
                if (isMounted.current) {
                    if (result && result.fee) setFraudFees(result.fee);
                    console.log('Fraudfees', result)
                }
            })
            .catch((error) => {
                console.log('error on fraudFeesbyKind ', error)
                getFraudFees();
            })
            .finally(() => {
                if (isMounted.current) {
                }
            });
    }, [getFraudFees, isMounted]);

    useEffect(() => {
        getCNAMFeesKind();
        getFraudFeesKind();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getCNAMFeesKind, getFraudFeesKind, appContext.selectedClient?.clientId]);

    const handleConfirm = () => {
        setShowFeesModal(false);
        setShowFraudFeesModal(false);
        setShowBothFeesModal(false);
        setShowLoadingIndicator(true);
        props.reportTypeSelection(selectedReportType, props.selectedTollFreeNumbers);
        props.closeModal();
    };

    return (
        <Modal className={"modal-dialog-centered"} isOpen={true} zIndex={2147483648}>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <div className="modal-body">
                    {props.selectedTollFreeNumbers && props.selectedTollFreeNumbers.length > 0 && (
                        <Row>
                            <FormGroup className={classNames("mb-2", "col-lg-12")}>
                                <Label className="form-control-label"><FormattedMessage id={"tollFreeNumber.create.customList"} /></Label>
                                <Label className={classNames("form-control form-control-sm border-0 pl-0 hight-100")}>{props.selectedTollFreeNumbers.join(', ')}</Label>
                            </FormGroup>
                        </Row>
                    )}
                    <Row><div className="col-lg-12 font-checkbox"><FormattedMessage id="tollFreeNumber.create.reportType" /></div></Row>
                    {selectedReportType && selectedReportType.map((reportTypes: any, index: any) => (
                        <Row key={index}>
                            <CheckboxFormInput
                                className="col-lg-12 margin-left-15"
                                label={(reportTypes.value)}
                                value={(reportTypes.value)}
                                checked={reportTypes.status}
                                handleInputChange={(value: boolean) =>
                                    handleSelectedReportTypeChanges({ ...reportTypes, status: value }, index)
                                }
                            >
                            </CheckboxFormInput>
                        </Row>
                    ))}
                    {props.reportTypeSelection && <Row>
                        <div className="col-lg-12 mt-2">
                            <FormattedMessage id="tollfree.report.notes" />
                        </div>
                    </Row>}
                </div>
                <div className="modal-footer justify-content-end">
                    <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                        <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                    </Button>
                    <Button color="primary" type="submit" disabled={showLoadingIndicator}>
                        {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                        <FormattedMessage id="tollfree.create.reportSubmitButton" />
                    </Button>
                </div>
            </Form>
            {showFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.fees.description" values={{ fees: (props.selectedTollFreeNumbers.length * cnamFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
            {showFraudFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowFraudFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.fees.description" values={{ fees: (props.selectedTollFreeNumbers.length * fraudFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
            {showBothFeesModal && (
                <ConfirmationModal
                    confirmCallback={handleConfirm}
                    closeModal={() => setShowBothFeesModal(false)}
                    title={intl.formatMessage({
                        id: "tollfree.report.feesModalTitle",
                    })}
                    confirmButtonText={intl.formatMessage({
                        id: "tollfree.report.feesModal.proceed",
                    })}
                    showLoadingIndicator={showLoadingIndicator}
                >
                    <p>
                        <FormattedMessage
                            id="tollfree.report.bothFees.description" values={{ fees: (props.selectedTollFreeNumbers.length * cnamFees).toFixed(4), fraudFees: (props.selectedTollFreeNumbers.length * fraudFees).toFixed(4) }}
                        />
                    </p>
                </ConfirmationModal>
            )}
        </Modal>
    );
}

