import { HttpClient } from "../httpClient/HttpClient";

export const NumberStatisticsApi = {
  getCnam: (hasCnam: boolean): Promise<any> => {
    return HttpClient.get<any>(`tollFrees/count?hasCnam=${hasCnam}`, true, true);
  },
  getCount: (): Promise<any> => {
    return HttpClient.get<any>(`tollFrees/count`, true, true);
  },
  getdoNotOrginate: (hasDoNotOrginate: boolean): Promise<any> => {
    return HttpClient.get<any>(`tollFrees/count?hasDoNotOriginate=${hasDoNotOrginate}`, true, true);
  },
  getUnassigned: (hasTemplate: boolean): Promise<any> => {
    return HttpClient.get<any>(`tollFrees/count?hasTemplate=${hasTemplate}`, true, true);
  },
  getChart: (hasCnam: boolean, hasDoNotOrginate: boolean, hasTemplate: boolean): Promise<any> => {
    return HttpClient.get<any>(`tollFrees/count?hasCnam=${hasCnam}?hasDoNotOriginate=${hasDoNotOrginate}?hasTemplate=${hasTemplate}`, true, true);
  },
};
