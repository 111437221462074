import { EnvironmentUtil } from "services/util/EnvironmentUtil";
import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";
import { TradingPartnerProfile } from "./types/tpp/TradingPartnerProfile";

export const TradingPartnerApi = {
  add: (spid: string): Promise<ApiError> => {
    return HttpClient.post<ApiError>("tpp", { tradingSpId: spid }, true, true);
  },
  get: (spid: string, serviceProviderAccessToken: string): Promise<TradingPartnerProfile> => {
    return HttpClient.getPortControl<TradingPartnerProfile>(`tpp/${spid}`, serviceProviderAccessToken, false ,"", EnvironmentUtil.oPortControlTenant);
  },
};
