import React from "react";
import { FormattedMessage } from "react-intl";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { ListGroup } from "reactstrap";
import { SettingsNotificationDto } from "services/apis/types/settings/SettingsNotificationDto";
import { DefaultNotifications } from "services/apis/types/settings/DefaultNotifications";


type Props = {
    notificationType: boolean;
    handleCheckAllActivities: (value: boolean) => void;
    filteredActivityNotification: SettingsNotificationDto[];
    handleChangeNotifications: (value: SettingsNotificationDto, index: number) => void;
};

export default function ActivitiesSettings(props: Props) {
    return (
        <div>
            <ListGroup as="ul" className="background-color-ul">
                <div className="col-lg-12 border-settings">
                    <CheckboxFormInput
                        className="col-lg-6 checkbox-settings pt-2"
                        label={DefaultNotifications.NotificationType}
                        value={"notificationType"}
                        checked={props.notificationType}
                        handleInputChange={(value: boolean) =>
                            props.handleCheckAllActivities(value)
                        }
                    />
                </div>
                {props.filteredActivityNotification.length > 0 ? (props.filteredActivityNotification.map((settings, index) => (
                    <div className="col-lg-12 mt-2">
                        <CheckboxFormInput
                            className="col-lg-6 checkbox-settings-sub"
                            label={settings.activityKindName}
                            value={(settings.activityKindId).toString()}
                            checked={settings.modifiedAssigned}
                            handleInputChange={(value: boolean) =>
                                props.handleChangeNotifications({ ...settings, modifiedAssigned: value }, index)
                            }
                        >
                            <div className="sub-card-message">
                                {settings.description}
                            </div>
                        </CheckboxFormInput>
                    </div>
                ))) : ((<>
                    <div className="py-4 text-center">
                        <FormattedMessage id="table.noData" />
                    </div>
                </>))}
            </ListGroup>
        </div>
    );
}