import React from "react";
import { useIntl } from "react-intl";
import { getUniqueElements } from "services/util/ArrayUtil";
import { getdno } from "services/util/StringUtil";
import DropdownInput from "../forms/DropdownInput";

type Props = {
    column: {
        filterValue: string;
        preFilteredRows: Array<any>;
        setFilter: (value: string | undefined) => void;
        id: string;
    };
};


export default function DefaultCustomColumnFilter(props: Props) {
    const intl = useIntl();


    const options = React.useMemo(() => {
        const options = props.column.preFilteredRows.map((x) => (
            intl.formatMessage({ id: getdno((x.values[props.column.id])) })
        ));
        return [
            { key: "Any", value: "" },
            ...getUniqueElements(options)
                .map((x, i) => {
                    return { value: x, key: x };
                })
        ];


    }, [props.column.id, props.column.preFilteredRows, intl]);

    return (
        <DropdownInput
            sm
            onChange={(e) => {
                props.column.setFilter(e.target.value || undefined);
            }}
            value={props.column.filterValue}
            options={options}
        />
    );
}


export const customfilterFunction = (rows: any[], accessor: any, filterValues: any) => (
    rows.filter((x) => {
        if (filterValues === 'Yes' && x.values[accessor] === true) {
            return (
                x.values
            );
        }
        if (filterValues === 'No' && x.values[accessor] === false) {
            return (
                x.values
            );
        }
        return '';
    })
)


