import React from "react";

type Props = {
    text?: string;
    size?: string;
    checked?: boolean;
    disabled?: boolean;
    handleInputChange: (value: boolean) => void;
    offstyle?: string;
    onstyle?: string
};

export default function ToggleCheckbox(props: Props) {
    let displayStyle = props.checked ? props.onstyle : props.offstyle;
    return (
        <label>
            <span className={`${props.size} switch-wrapper`}>
                <input
                    type="checkbox"
                    checked={props.checked}
                    disabled={props.disabled}
                    onChange={(e) => props.handleInputChange(e.currentTarget.checked)}
                />
                <span className={`${displayStyle} switch`}>
                    <span className="switch-handle" /></span>
            </span>
        </label>
    );
}
