import { DefaultUserClient, UserClient } from "services/apis/types/account/UserClient";
import { LocalStorageInfo, DefaultLocalStorageInfo } from "./LocalStorageInfo";

const localStorageInfoKey = "Atlas.info";

const localStorageSelectedClientKey = "Atlas.client";

export const AppContextUtil = {
  saveToLocalStorage: (info: LocalStorageInfo) => {
    localStorage.setItem(localStorageInfoKey, JSON.stringify(info));
  },

  getFromLocalStorage: (): LocalStorageInfo => {
    const localStorageInfo = localStorage.getItem(localStorageInfoKey);

    if (localStorageInfo) {
      const user = JSON.parse(localStorageInfo);

      return user;
    }

    return DefaultLocalStorageInfo;
  },

  saveClientToLocalStorage: (info?: UserClient) => {
    localStorage.setItem(localStorageSelectedClientKey, JSON.stringify(info));
  },

  getClientFromLocalStorage: (): UserClient => {
    const localStorageInfo = localStorage.getItem(localStorageSelectedClientKey);

    if (localStorageInfo && localStorageInfo !== "undefiend") {
      const user = JSON.parse(localStorageInfo);

      return user;
    }

    return DefaultUserClient;
  }
};
