import React from "react";
import { AzureMapLayerProvider, AzureMapFeature } from "react-azure-maps";

type Props = {
    line?: any
};

export default function OutageStroke(props: Props) {
    return (<>
        <AzureMapLayerProvider
            options={{
                strokeColor: ['get', 'strokeColor'],
                strokeOpacity: ['get', 'strokeOpacity'],
            }}
            type={"LineLayer"}
        />
        {props.line.map((poly: any) => (
            <>
                <AzureMapFeature
                    key={"Stroke"}
                    variant={"feature"}
                    id={poly.id}
                    type="Polygon"
                    coordinate={poly.polygonLayout}
                    properties={{
                        strokeColor: poly.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 3,
                    }}
                    setCoords={poly.polygonLayout}
                />
            </>
        ))}
    </>);
}