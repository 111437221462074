import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { AppContext } from "../appContext/AppContext";
import { PermissionType } from "./PermissionType";
import { Permissions } from "./Permissions";

export const authorize = (
  permission: keyof typeof PermissionType,
): boolean => {
  const appContext = AppContextAccessor.getAppContext();

  if (
    appContext.localStorageInfo.authenticationInfo.authenticated &&
    (userIsAuthorized(appContext, permission))
  ) {
    return true;
  }

  return false;
};

export const authorizeAny = (permissions: PermissionType[]): boolean => {
  return permissions && permissions.findIndex((x) => authorize(x)) > -1;
};

const selectedClientIdIsAuthorized = (appContext: AppContext, permission: string): boolean => {
  return (
    appContext.selectedClient !== undefined);
};

const userIsAuthorized = (appContext: AppContext, permission: string): boolean => {
  return (
    appContext.localStorageInfo.userRoles !== undefined &&
    appContext.localStorageInfo.userRoles.length > 0 &&
    (appContext.localStorageInfo.userRoles.findIndex((role) => role.roleType === permission) > 0 ||
      appContext.localStorageInfo.userRoles.findIndex((permission) => permission.roleType === PermissionType.GlobalAdmin) > 0)
  );
};

export const hasAnyPermissions = () => {
  const appContext = AppContextAccessor.getAppContext();

  return (
    Object.keys(Permissions).findIndex(
      (x) => selectedClientIdIsAuthorized(appContext, x) || userIsAuthorized(appContext, x)
    ) > -1
  );
};
