import React from "react";
import { DropdownItem } from "reactstrap";
import { FormattedMessage } from "react-intl";

type Props = {
  handleAction: () => void;
  iconClassName?: string;
  labelId: string;
  disabled?: boolean
};

export default function ActionDropdownItem(props: Props) {
  return (
    <DropdownItem onClick={props.handleAction} className="d-flex align-items-center cursor-pointer" disabled={props.disabled}>
      <i className={props.iconClassName}></i>
      <span>
        <FormattedMessage id={props.labelId} />
      </span>
    </DropdownItem>
  );
}
