import React, { useState } from "react";
import ServiceProviderModal from "./ServiceProviderModal";
import { Button } from "reactstrap";

type Props = {
  spId: string;
  labelColor?: string;
};

export default function RespOrgIdCell(props: Props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {props.spId && (
        <>
          <Button
            className={`cursor-pointer ${
              props.labelColor || "bg-primary"
            } text-white rounded p-1 border-0`}
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}>
            {props.spId}
          </Button>
          {showModal && (
            <ServiceProviderModal closeModal={() => setShowModal(false)} spId={props.spId} />
          )}
        </>
      )}
    </>
  );
}
