import { ServiceProviderProfileResult } from "components/common/types/ServiceProviderProfileResult";
import React, { useState, useEffect } from "react";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { handleError } from "services/util/ApiUtil";
import { useIsMounted } from "./useIsMounted";

export default function useServiceProvider(spId?: string, serviceProviderAccessToken?: string) {
  const [serviceProvider, setServiceProvider] = useState<
    ServiceProviderProfileResult | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();

  const fetchServiceProvider = React.useCallback(() => {
    setIsLoading(true);
    if (spId && serviceProviderAccessToken) {
      ServiceProviderApi.get(spId, serviceProviderAccessToken)
        .then((result) => {
          if (isMounted.current) {
            setServiceProvider(result);
          }
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    } else {
      setIsLoading(false);
    }
   
  }, [spId, isMounted, serviceProviderAccessToken]);

  useEffect(() => {
    if (spId && serviceProviderAccessToken) {
      fetchServiceProvider();
    }
  }, [spId, serviceProviderAccessToken, fetchServiceProvider]);

  return { serviceProvider, isLoading, fetchServiceProvider };
}
