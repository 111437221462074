export const EnvironmentUtil = {
  apiV1Url: (path: string): string => `${process.env.REACT_APP_API_URL}/api/v1/${path}`,
  apiV2Url: (path: string): string => `${process.env.REACT_APP_API_URL}/v2/${path}`,
  apiV3Url: (path: string): string => `${process.env.REACT_APP_ATLAS_MAP}/${path}`,
  apiPortControlUrl: (path: string): string => `${process.env.REACT_APP_TEST_URL}/api/${path}`,
  identityServerUrl: (path: string): string =>
    `${process.env.REACT_APP_IDENTITY_SERVER_URL}/api/v1/${path}`,
  signalRUrl: `${process.env.REACT_APP_API_URL}/hub/notification`,
  oAuthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  oAuthClientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  oAuthScope: process.env.REACT_APP_OAUTH_SCOPE,
  oAuthUsername: process.env.REACT_APP_OAUTH_USER_NAME,
  oAuthPassword: process.env.REACT_APP_OAUTH_PASSWORD,
  oPortControlTenant: process.env.REACT_APP_PORT_CONTROL_TENANT,
  oAuthIdentityUrl: (path: string): string =>
    `${process.env.REACT_APP_IDENTITY_SERVER_PORTCONTROL_URL}/${path}`,
};
