import React, { useState, useEffect } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Row,
    Input,
    Label
} from 'reactstrap';
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

export type DropdownFormInputProps = {
    className?: string;
    handleInputChange: (value: string, checked: boolean) => void;
    options: Array<any>;
};

export default function DropdownCheckboxFormInput(props: DropdownFormInputProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedCount, setSelectedCounter] = useState(0)
    const [isChanged, setChanged] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    useEffect(() => {

        const selectedList = props.options ? props.options.filter(
            option => option.value !== 'Select All' && option.status === true
        ) : []

        setSelectedCounter(selectedList.length)

    }, [props.options, isChanged])

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'} className={classNames("mb-2 p-0", props.className)}
        >
            <DropdownToggle caret className='mr-0 dropdown-content'>
                {selectedCount ? <FormattedMessage id="numberStatistics.dropdown.selected.title" values={{ selected: selectedCount, total: props.options.length - 1 }}
                /> : <FormattedMessage id="numberStatistics.dropdown.default.title"
                />}
            </DropdownToggle>
            <DropdownMenu bottom className="mb-2 dropdown-content">
                {props.options && props.options.map(
                    (option) => (
                        <Row>
                            {option.value === 'Select All' ?
                                <div color="link" onClick={() => {
                                    props.handleInputChange("selectAll", !option.status)
                                    setChanged(!isChanged)
                                }} className="select-all mb-2 ml-2">{(option.status === true && selectedCount === props.options.length - 1) ? <FormattedMessage id={"numberstatistic.clearAll"} /> : <FormattedMessage id={"numberstatistic.selectAll"} />}</div> :
                                <div className="custom-control custom-checkbox mb-2 ml-2" >
                                    <Input className="custom-control-input" type="checkbox" id={`customCheck${option.key}`} value={option.key} checked={option.status}
                                        onChange={(e) => { props.handleInputChange(option.key, e.currentTarget.checked); setChanged(!isChanged) }} />
                                    <Label className="custom-control-label" id={`customCheckLabel${option.key}`} htmlFor={`customCheck${option.key}`}>
                                        {option.value}
                                    </Label>
                                </div>
                            }
                        </Row>
                    )
                )}
            </DropdownMenu>
        </Dropdown>
    );

}
