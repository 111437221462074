import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  AzureMap,
  AzureMapDataSourceProvider,
  AzureMapFeature,
  AzureMapLayerProvider,
  AzureMapPopup,
  AzureMapsProvider,
  ControlOptions,
  IAzureMapControls,
  IAzureMapOptions,
} from "react-azure-maps";
import { AuthenticationType, ControlPosition } from "azure-maps-control";
import { FormattedMessage, useIntl } from "react-intl";
import { Container, Row, Col, Card, CardBody, CardHeader, Table } from "reactstrap";
import OutageZipcode from "./OutageZipcode";
import OutageStroke from "./OutageStroke";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { MapApi } from "services/apis/MapApi";
import { handleError } from "services/util/ApiUtil";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import OutageMultiPolygon from "./OutageMultiPolygon";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import { HomeContext } from "components/home/Home";
import OutageDetailsModal from "./OutageDetailsModal";
import { FullscreenControl } from "./FullscreenControl";
import classnames from "classnames";
import FormatDateTime from "components/framework/date/FormatDateTime";

type Props = {
  zipcode?: Array<CarrierDto>;
  status?: string;
  showLoadingIndicator: boolean;
  // centerOutages: Array<number>;
};

const controls: IAzureMapControls[] = [
  {
    controlName: 'ZoomControl',
    options: { position: 'top-left' } as ControlOptions,
  },
];

const customControls: any = [
  {
    control: new FullscreenControl({
      style: 'auto'
    }),
    controlOptions: {
      position: ControlPosition.TopLeft,
    },
  },
];

export default function OutagePolygon(props: Props) {

  let option: IAzureMapOptions = {
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: process.env.REACT_APP_AZURE_MAP_SUBSCRIPTION_KEY,
      clientId: process.env.REACT_APP_AZURE_MAP_CLIENT_ID
    },
    // center: props.centerOutages,
    // zoom: 9,
    // For default view 
    center: [-102.462776, 51.213890],
    zoom: 2,
    view: "Auto",
  };
  const intl = useIntl();
  const rendId = Math.random();
  const [geomentoryCoordinates, setGeomentoryCoordinates] = useState<Array<string>>([]);
  const [hasBoundary, setHasBoundary] = useState<boolean>(false);
  const [showCarrierLoadingIndicator, setShowCarrierLoadingIndicator] = useState<boolean>(false);
  const [carriersList, setCarriersList] = useState<Array<CarrierDto>>([]);
  const [geoObjForOutage, setGeoObjForOutage] = useState<Array<any>>([]);
  const [positionOutages, setPositionOutages] = useState<Array<any>>([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [positionCoordinates, setPositionCoordinates] = useState<any>();
  const [fullscreenHeight, setFullscreenHeight] = useState(500);
  const [region, setRegion] = useState<string>('');
  const homeContext = useContext(HomeContext);
  let polygonGeomentries: any[] = [];

  const isMounted = useIsMounted();
  const fetchGeomentry = useCallback((zipcodes: any) => {
    // geomentory api
    const zipCodeArray: any[] = [];
    const geoOfGeomentry: any[] = [];
    if (zipcodes.length > 0) {
      zipcodes && zipcodes.forEach((zipcodeObj: any) => {
        zipCodeArray.push(zipcodeObj.zipCode);
      });
      const uniqueZipCodes = zipCodeArray.filter((element, index) => {
        return zipCodeArray.indexOf(element) === index;
      });
      uniqueZipCodes && uniqueZipCodes.forEach((zipCode) => {
        MapApi.get(zipCode)
          .then((result) => {
            if (isMounted && result) {
              geoOfGeomentry.push(result);
            }
            if (geoOfGeomentry.length === uniqueZipCodes.length) {
              setGeoObjForOutage(geoOfGeomentry);
            }
          })
          .catch((error) => handleError(error))
          .finally(() => {
            if (isMounted) {
            }
          });
      })
    }
    // eslint-disable-next-line
  }, [isMounted]);

  useEffect(() => {
    fetchGeomentry(props.zipcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchGeomentry, props.zipcode]);


  const fetchPolygonBoundary = useCallback((geoObjForOutage: any) => {

    const geomentoryIdsandZipcode: any[] = [];
    const geomentoryIds: any[] = [];
    const geoResults: any[] = [];
    const coordsArray: any[] = [];
    const positionIdsandZipcode: any[] = [];

    geoObjForOutage && geoObjForOutage.forEach((geoOutageObj) => {
      geoResults.push({ geoIds: geoOutageObj.results, zipcode: geoOutageObj.summary.query });
    });


    geoResults && geoResults.forEach((geoIdObj) => {
      geoIdObj.geoIds && geoIdObj.geoIds.forEach((geoObj) => {
        if (geoObj && geoObj.type === 'Geography' && (geoObj.address.countryCode === 'US' || geoObj.address.countryCode === 'CA')) {
          if (geoObj && geoObj.dataSources && geoObj.dataSources?.geometry.id !== undefined) {
            geomentoryIdsandZipcode.push({ geoIdOfGeomentryId: geoObj.dataSources?.geometry.id, zipCodes: geoIdObj.zipcode });
          } else if (geoObj && geoObj.position) {
            geomentoryIdsandZipcode.push({ geoPosition: geoObj.position, zipCodes: geoIdObj.zipcode });
          }
        }
      })
    });

    geomentoryIdsandZipcode && geomentoryIdsandZipcode.forEach((geoPolygon) => {
      if (geoPolygon.geoIdOfGeomentryId) {
        geomentoryIds.push(geoPolygon.geoIdOfGeomentryId);
      } else if (geoPolygon.geoPosition) {
        const newGeoPosition = {
          lon: Number(geoPolygon.geoPosition.lon),
          lat: Number(geoPolygon.geoPosition.lat)
        }
        positionIdsandZipcode.push(({ position: newGeoPosition, positionZipcodes: geoPolygon.zipCodes }))
      }
    });
    setPositionOutages(positionIdsandZipcode);
    const uniqueGeomentryIds = geomentoryIds.filter((element, index) => {
      return geomentoryIds.indexOf(element) === index;
    });
    const zipCodesStr = uniqueGeomentryIds.join(',');

    let encodedGeomentries = encodeURIComponent(zipCodesStr);

    // geomentoryIds && geomentoryIds.forEach((geoIdBoundary) => {
    MapApi.getPolygon(encodedGeomentries)
      .then((result) => {
        if (isMounted && result) {
          result && result.additionalData.forEach((dataObj: any) => {
            dataObj && dataObj.geometryData.features.forEach((coordsObj: any) => {
              geomentoryIdsandZipcode && geomentoryIdsandZipcode.forEach((geoZipObj) => {
                if (geoZipObj.geoIdOfGeomentryId === dataObj.providerID) {
                  coordsArray.push({
                    coordinates: coordsObj.geometry.coordinates,
                    geoId: dataObj.providerID,
                    zipcode: geoZipObj.zipCodes.split(" ")[0],
                    polygonType: coordsObj.geometry.type
                  });
                }
              });
              // const statusClassMapping: { [key in string]: string } = {
              //   "initiated": "#F5365C",
              //   "pending": "#ffc107",
              //   "none": "#2dce89",
              // };
              let polygonBoundaries: any[] = [];
              if (coordsArray.length > 0) {
                let polygonBoundaryObj = {};
                coordsArray && coordsArray.forEach((geoLocation: any, index) => {
                  polygonBoundaryObj = {
                    id: index + 1,
                    polygonLayout: geoLocation.coordinates,
                    color: "#F5365C",
                    label: geoLocation.zipcode,
                    geoId: geoLocation.geoId,
                    type: geoLocation.polygonType
                  }
                  polygonBoundaries.push(polygonBoundaryObj);
                });
              }
              if (polygonBoundaries.length > 0) {
                setGeomentoryCoordinates(polygonBoundaries);
              }
            });
          });
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted) {
        }
      });
    // })

  }, [isMounted]);

  useEffect(() => {
    if (geoObjForOutage.length > 0) {
      fetchPolygonBoundary(geoObjForOutage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPolygonBoundary, geoObjForOutage]);

  const mouseOn = (e: any) => {
    e.map.getCanvas().style.cursor = 'pointer';
  }

  const mouseLeave = (e: any) => {
    e.map.getCanvas().style.cursor = '';
  }

  const clusterClicked = (e: any) => {
    const zipCodes = e.shapes[0]?.properties?.label;
    setRegion(zipCodes);
    let carrierIdsArray: any[] = [];
    props.zipcode?.forEach((zipObj: any) => {
      if ((zipCodes.toString() === zipObj.zipCode.toString()) || (zipCodes === zipObj.zipCode.toLowerCase())) {
        carrierIdsArray.push({ zipCode: zipObj.zipCode.toString(), carrierId: zipObj.carrierId, insertName: zipObj.insertName, insertDate: zipObj.insertDate, notes: zipObj.notes });
      }
    });
    let carriersArray: CarrierDto[] = [];
    setCarriersList(carriersArray);
    let uniqueCarriers = carrierIdsArray.filter((v, i, a) => a.findIndex(v2 => ['carrierId'].every(k => v2[k] === v[k])) === i);

    uniqueCarriers.forEach((zip) => {
      setShowCarrierLoadingIndicator(true);
      MapApi.getZipcodeCarrier(zip.carrierId)
        .then((result) => {
          if (isMounted.current && result) {
            carrierIdsArray.forEach((zipObj) => {
              if (zipObj.zipCode === zip.zipCode && zipObj.carrierId === zip.carrierId) {
                carriersArray.push({id: result.id, name:result.name, cic: result.cic, insertDate: zipObj.insertDate, insertName: zipObj.insertName, notes: zipObj.notes});
              }
            });
          }
          setCarriersList(carriersArray);
          homeContext.disasterRecoveryActionCallback();
          homeContext.activityActionCallback();
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setShowCarrierLoadingIndicator(false);
          }
        });
    });
    if ((window.innerHeight === e.map.map._canvas.clientHeight)) {
      console.log('FULL SCREEN');
      toggleFullscreenPopup(e.position);
      setFullscreenHeight(e.map.map._canvas.clientHeight);
    } else {
      console.log('NORMAL SCREEN');
      setHasBoundary(true);
    }
  }

  if (geomentoryCoordinates.length > 0) {
    polygonGeomentries = geomentoryCoordinates;
  }

  const clusterPositionClicked = (e: any) => {
    const zipCodesPosition = JSON.parse(e.shapes[0]?.data?.properties?.label);
    setRegion(zipCodesPosition);
    let carrierIdsArray: any[] = [];
    props.zipcode?.forEach((zipObj: any) => {
      if ((zipCodesPosition.toString() === zipObj.zipCode.toString()) || (zipCodesPosition === zipObj.zipCode.toLowerCase())) {
        carrierIdsArray.push({ zipCode: zipObj.zipCode.toString(), carrierId: zipObj.carrierId, insertName: zipObj.insertName, insertDate: zipObj.insertDate, notes: zipObj.notes });
      }
    });
    let carriersArray: CarrierDto[] = [];
    setCarriersList(carriersArray);
    let uniqueCarriers = carrierIdsArray.filter((v, i, a) => a.findIndex(v2 => ['carrierId'].every(k => v2[k] === v[k])) === i);

    uniqueCarriers.forEach((zip) => {
      setShowCarrierLoadingIndicator(true);
      MapApi.getZipcodeCarrier(zip.carrierId)
        .then((result) => {
          if (isMounted.current && result) {
            carrierIdsArray.forEach((zipObj) => {
              if (zipObj.zipCode === zip.zipCode && zipObj.carrierId === zip.carrierId) {
                carriersArray.push({ id: result.id, name: result.name, cic: result.cic, insertDate: zipObj.insertDate, insertName: zipObj.insertName, notes: zipObj.notes });
              }
            });
          }
          setCarriersList(carriersArray);
          homeContext.disasterRecoveryActionCallback();
          homeContext.activityActionCallback();
        })
        .catch((error) => handleError(error))
        .finally(() => {
          if (isMounted.current) {
            setShowCarrierLoadingIndicator(false);
          }
        });
    });
    if ((window.innerHeight === e.map.map._canvas.clientHeight)) {
      console.log('FULL SCREEN');
      toggleFullscreenPopup(e.position);
    } else {
      console.log('NORMAL SCREEN');
      setHasBoundary(true);
    }
  }

  const toggleFullscreenPopup = useCallback((position) => {
    const popupOptions = {
      position: position,
      pixelOffset: [0, -18],
      // closeButton: false,
      // fillColor: 'rgba(0,0,0,0)',
    };
    setPositionCoordinates(popupOptions);
    setPopupVisible((prevState) => !prevState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopupVisible, positionCoordinates]);

  /*const handleChangeLocation = () => {
    console.log('MapLocation'--To find nearest polygon);
  }*/

  return (
    <>
      <Container className="nopadding">
        {props.showLoadingIndicator ?
          (<LoadingIndicator white />)
          :
          (<Row className="justify-content-center">
            <Col className="nopadding">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="6"><h3 className="mb-0">{intl.formatMessage({ id: "outage.table.title" })}</h3></Col>
                  </Row>
                </CardHeader>
                <CardBody className="padding-card">
                  <AzureMapsProvider>
                    <div style={{ height: "450px" }}
                    // onClick={() => handleChangeLocation()}
                    >
                      <AzureMap options={option} controls={controls} customControls={customControls}>
                        <AzureMapDataSourceProvider id={"DataSource Provider"}>
                          <AzureMapLayerProvider
                            options={{
                              color: ["get", "color"],
                              fillColor: [
                                'case', // Use a conditional case expression.

                                ['has', 'fillColor'],   // Check to see if feature has a "fillOpacity" property
                                ['get', 'fillColor'],   // If it does, use it.

                                '#000000'  //If it doesn't, default to black.
                              ],
                              geoId: ['get', 'geoId'],   // If it does, use it.
                              fillOpacity: 0.4,
                              strokeColor: ['get', "label"],
                              strokeOpacity: 0.8,
                              textOptions: {
                                //Reference the label property of the polygon as the text field. 
                                textField: ['get', "label"]
                              },
                              iconOptions: {
                                //Hide the default image. 
                                image: 'none'
                              },
                            }}

                            id={"shape AzureMapLayerProvider"}
                            type={"PolygonLayer"}
                            events={{
                              mouseenter: mouseOn,
                              mouseleave: mouseLeave,
                              click: clusterClicked,
                            }}
                          />
                          {polygonGeomentries && polygonGeomentries.map((poly) => (
                            <>
                              <AzureMapFeature
                                key={"Feature"}
                                variant={"feature"}
                                id={poly.id}
                                type="Polygon"
                                coordinates={poly.polygonLayout}
                                properties={{
                                  label: poly.label,
                                  fillColor: poly.color,
                                  fillOpacity: 0.4,
                                  strokeColor: poly.color,
                                  strokeOpacity: 0.8,
                                  strokeWeight: 3,
                                  geoId: poly.geoId,
                                }}
                                setCoords={poly.polygonLayout}
                              />
                            </>
                          ))}
                          <OutageZipcode data={polygonGeomentries} />
                          <OutageStroke line={polygonGeomentries} />
                          <OutageMultiPolygon multiPolygon={polygonGeomentries} mouseOn={() => mouseOn} mouseLeave={() => mouseLeave} clusterClicked={() => clusterClicked} />
                        </AzureMapDataSourceProvider>
                        <AzureMapDataSourceProvider
                          id={'Markers AzureMapDataSourceProvider'}
                        >
                          <AzureMapLayerProvider
                            options={{
                              textOptions: {
                                textField: ['get', 'label']
                              },
                              iconOptions: {
                                image: ['get', 'icon'],
                              },
                            }}
                            type={"SymbolLayer"}
                            events={{
                              mouseenter: mouseOn,
                              mouseleave: mouseLeave,
                              click: clusterPositionClicked,
                            }}
                          />
                          {positionOutages && positionOutages.map((posObj: any, index: any) => (
                            <AzureMapFeature
                              key={rendId}
                              id={index.toString()}
                              type="Point"
                              coordinate={Object.values(posObj.position)}
                              properties={{
                                label: posObj.positionZipcodes,
                                icon: 'marker-red',
                              }}
                            />
                          ))}
                        </AzureMapDataSourceProvider>
                        {carriersList.length > 0 ? (
                          <AzureMapPopup
                            isVisible={isPopupVisible}
                            options={positionCoordinates}
                            popupContent={
                              <div
                                className={`table-responsive ${classnames({
                                  "pc-overflow-x-auto": !false
                                })}`}
                              >
                                <Table responsive className={"table"} size="sm">
                                  <thead>
                                    <tr>
                                      <th className="ul-table-header">
                                        {region}
                                      </th>
                                    </tr>
                                  </thead>
                                  <thead className="thead-light">
                                    <tr key={"tr_outage"}>
                                      <th key={1}>{intl.formatMessage({ id: "outage.cicCode" })}</th>
                                      <th key={2}>{intl.formatMessage({ id: "outage.carrierName" })}</th>
                                      <th key={3}>{intl.formatMessage({ id: "outage.notes" })}</th>
                                      <th key={3}>{intl.formatMessage({ id: "outage.lastReportedDate" })}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {carriersList && (carriersList.map((carrierObj) => {
                                      return (
                                        <tr><td>
                                          <ul className="pl-3 list-style-type:circle; ul-outage">
                                            <li>
                                              <p className="li-outage"><span className="ml-2">{carrierObj.cic}</span></p>
                                            </li>

                                          </ul>
                                        </td>
                                          <td>{carrierObj.name}</td>
                                          <td>{carrierObj.notes}</td>
                                          <td><FormatDateTime utcDate={carrierObj.insertDate} /></td></tr>
                                      )
                                    }))}
                                  </tbody>
                                </Table>
                              </div>
                            }
                          />
                        ) : (<></>)}
                      </AzureMap>
                    </div>
                  </AzureMapsProvider>
                </CardBody>
                <CardBody className="padding-sub-card">
                  {hasBoundary && carriersList.length > 0 ? (
                    <OutageDetailsModal showCarrierLoadingIndicator={showCarrierLoadingIndicator} carriersList={carriersList} region={region} />
                  ) :
                    (<FormattedMessage id={"outage.create.createModalTitle"} />)
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>)
        }
      </Container>
    </>
  );
};