import { QueryType } from "./RequestTollFreeQuery";

export type TollFreeQueryDto = {
    number: string[];
    id: number;
    numberCount: number;
    queryType: QueryType;
    statusType:StatusType;
    reportFile: string;
    queryDate: Date;
    insertName: string;
};

export enum StatusType {
    Complete = "Complete",
    Pending = "Pending",
}