import { useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { AppContext } from "services/appContext/AppContext";

export const useProfileSwitcher = () => {
    const {appContext, setAppContext} = useContext(AppContext);
    const history = useHistory();

    const navigate = useCallback((url: string, tenant: string| null) => {
        
        const needToSwitchProfile = appContext?.localStorageInfo?.selectedProfile?.spId !== tenant;

        const spIdProfile = appContext.localStorageInfo.userClients
        ? appContext.localStorageInfo.userClients.find((x) => x.clientId === (tenant && parseInt(tenant)) )
        : undefined;
      
      if (needToSwitchProfile && spIdProfile) {
        setAppContext({
          ...appContext,
          selectedClient: spIdProfile
        });
      }
      
      history.push(url);
      },[appContext, history, setAppContext]);
      
      return { navigate: navigate};
}