import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";

export default function Index() {
  const [pathName, ] = useState<string | null>(localStorage.getItem('location') ? localStorage.getItem('location') : "/home");

  useEffect(() => {
    localStorage.removeItem('location');
  }, [pathName])

  return (
    <>
      {authorize(PermissionType.DisasterRecovery) ? (
        <Redirect to={{ pathname: (pathName !== null && pathName !== "/" ?  pathName : "/home")  }} />
      ) 
      // : authorize(PermissionType.Network) ? (
      //   <Redirect to={{ pathname: "/NetworkExplorer" }} />
      // ) : authorizeAny([PermissionType.SOARead, PermissionType.PreOrderFree], true) ? (
      //   <Redirect to={{ pathname: "/Orders" }} />
      // ) 
      : (
        <Redirect to={{ pathname: "/unauthorized" }} />
      )}
    </>
  );
}
