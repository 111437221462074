import { TemplateDto } from "services/apis/types/template/TemplateDto";
import { CarrierDto } from "./CarrierDto";

export type TemplateGroup = {
    workingTemplateId: number,
    initiateTemplateId: number,
    revertTemplateId: number,
    workingCarriers?: Array<CarrierDto>,
    initiateCarriers?: Array<CarrierDto>,
    revertCarriers?: Array<CarrierDto>,
    initiateTemplate?: TemplateDto,
    revertTemplate?: TemplateDto,
    workingTemplate?: TemplateDto
}

export const DefaultTemplateGroup: TemplateGroup = {
    workingTemplateId: 0,
    initiateTemplateId: 0,
    revertTemplateId: 0,
    workingCarriers: [],
    initiateCarriers: [],
    revertCarriers: []
};