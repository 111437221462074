import React from "react";
import classNames from "classnames";

type Props = {
  white?: boolean;
  small?: boolean;
  className?: string;
};

export function LoadingIndicator(props: Props) {
  return (
    <div className={classNames("text-center p-4 flex flex-fill", props.className)}>
      <i
        className={classNames("fas fa-spinner fa-spin", {
          "text-white": props.white,
          "fa-2x": !props.small
        })}
      />
    </div>
  );
}
