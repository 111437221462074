import React, { useState, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { DisasterRecoveryApi } from "services/apis/DisasterRecoveryApi";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { handleError } from "services/util/ApiUtil";
import ConfirmationModal from "components/framework/modals/ConfirmationModal";
import { HomeContext } from "components/home/Home";

type Props = {
  id: string;
  name: string;
  closeModal: () => void;
};

export default function DisasterRecoveryDeleteModal(props: Props) {
  const intl = useIntl();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const homeContext = useContext(HomeContext);
  const isMounted = useIsMounted();
  const { id } = props;
  const handleDelete = () => {
    if (isMounted.current) {
      setShowLoadingIndicator(true);
    }

    DisasterRecoveryApi.delete(parseInt(id))
      .then(() => {
        showInfoNotification(
          intl.formatMessage({
            id: "disasterRecovery.delete.deleteNotification",
          })
        );
        if (isMounted.current) {
          props.closeModal();
          homeContext.disasterRecoveryActionCallback();
          homeContext.activityActionCallback();
        }
      })
      .catch((error) => handleError(error))
      .finally(() => {
        if (isMounted.current) {
          setShowLoadingIndicator(false);
        }
      });
  };

  return (
    <ConfirmationModal
      confirmCallback={handleDelete}
      closeModal={props.closeModal}
      title={intl.formatMessage({
        id: "disasterRecovery.delete.deleteModalTitle",
      })}
      confirmButtonText={intl.formatMessage({
        id: "disasterRecovery.delete.submitDelete",
      })}
      showLoadingIndicator={showLoadingIndicator}
    >
      <p>
        <FormattedMessage
          id="disasterRecovery.delete.deleteQuestion" values={{ name: props.name }}
        />
      </p>
    </ConfirmationModal>
  );
}
