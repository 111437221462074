import React from "react";
import { CSVLink } from "react-csv";
import { Button, ButtonGroup } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import ReactToPrint from "react-to-print";
import copy from "copy-to-clipboard";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";
import { utils, writeFile } from "xlsx";

export default function ExportButtons(props) {
  const intl = useIntl();
  const table = dataToTable(props.data, props.columns);

  return (
    <ButtonGroup>
      <CSVLink
        data={table}
        target="_blank"
        className="btn btn-default btn-sm bg-primary text-white rounded mr-2 border-0"
        filename={"result.csv"}>
        <FormattedMessage id="table.exportToCsv" />
      </CSVLink>
          <Button color="default" type="button" size="sm" className="bg-primary text-white rounded mr-2 border-0" onClick={() => exportToXslxHandler(table)}>
        <FormattedMessage id="table.exportToXslx" />
      </Button>
          <Button color="default" type="button" size="sm" className="bg-primary text-white rounded mr-2 border-0" onClick={() => copyHandler(table, intl)}>
        <FormattedMessage id="table.copy" />
      </Button>
      <ReactToPrint
        onBeforeGetContent={props.onBeforePrint}
        onAfterPrint={props.onAfterPrint}
        trigger={() => (
            <Button color="default" type="button" size="sm" className="bg-primary text-white rounded border-0" >
            <FormattedMessage id="table.print" />
          </Button>
        )}
        content={() => props.printRef.current}
      />
    </ButtonGroup>
  );
}

function exportToXslxHandler(matrix) {
  let workbook = { SheetNames: ["Sheet1"], Sheets: { Sheet1: utils.aoa_to_sheet(matrix) } };

  writeFile(workbook, "result.xlsx");
}

function copyHandler(matrix, intl) {
  let string = matrix.map((x) => x.join(", ")).join("\n");

  copy(string);

  showInfoNotification(intl.formatMessage({ id: "table.copyNotification" }));
}

function dataToTable(data, columns) {
  let table = [columns.map((column) => column.Header)];

  if (data) {
    data
      .map((row) =>
        columns.map((column) => (row[column.accessor] ? `${row[column.accessor]}` : ""))
      )
      .forEach((x) => table.push(x));
  }

  return table;
}
