import React, { useEffect, useState, useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";
import { getValueOrEmpty } from "services/util/StringUtil";
import ReactTable from "components/framework/table/ReactTable";
import TollFreeNumbersActionsCell from "./TollFreeNumbersActionsCell";
import { TollFreeNumberTableColumnIdentifiers } from "./types/TollFreeNumberTableColumnIdentifiers";
import Papa from "papaparse";
import { TollFreeQueryDto } from "services/apis/types/toll-free-numbers/TollFreeQueryDto";
import { TollFreeTextDto } from "services/apis/types/toll-free-numbers/TollFreeTextDto";
import { TollFreeTextQueryDto } from "services/apis/types/toll-free-numbers/TollFreeTextQueryDto";
import _ from 'lodash';
import TextWithTooltip from "components/common/TextWithTooltip";
import ExploreSearchNamecell from "./ExploreSearchNamecell";
import RespOrdIdCell from "components/common/serviceProviderCard/RespOrgIdCell";

type Props = {
    tollFreeNumberQuery?: TollFreeQueryDto;
    tollFreeQueryRouting?: TollFreeQueryDto;
    tollFreeTextStatus?: TollFreeTextQueryDto;
    tollFreeNotInDatabase?: string[];
    selectedTollFreeNumbers?: string[];
    handleChangeSearchNumber?: any;
    handleSelectAll?: any;
    selectedReportType?: any;
    queryResult?: any;
    cnamCharge?: any;
    fraudCharge?: any;
};

const defaultColumns = ['Network', 'Carriers', 'CPR Nodes', 'Effective Date (ET)', 'statusType']
const reportColumns = ['number','name', "addressLine1", '"addressLine2"', 'city', 'state','zipcode','country','siteURL','logoURL','score']

export default function ExploreSearch(props: Props) {
    const [exploreDataSearch, setExploreDataSearch] = useState<Array<any>>([]);
    const [exploreDataSearchOfRouting, setExploreDataSearchOfRouting] = useState<Array<any>>([]);
    const [tollFreeTextStatusList, setTollFreeTextStatusList] = useState<Array<TollFreeTextDto>>([]);
    const intl = useIntl();
    // const { appContext } = useContext(AppContext);

    useEffect(() => {
        if (props.selectedTollFreeNumbers) {
            const newExploreDataSearch = [...exploreDataSearch];
            newExploreDataSearch.forEach(val => {
                const tollFree = props.selectedTollFreeNumbers?.find(x => x === val.Number);
                if (tollFree) {
                    val.modifiedStatus = true;
                } else val.modifiedStatus = false;
            });
            setExploreDataSearch(newExploreDataSearch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedTollFreeNumbers]);

    useEffect(() => {
        if (props.tollFreeTextStatus && props.tollFreeTextStatus?.tollFreeTexts) {
            const newList: TollFreeTextDto[] = []
            if (props.tollFreeNotInDatabase) {
                props.tollFreeNotInDatabase.forEach(x => {
                    newList.push({
                        "Number": x, number: x,
                        statusDate: "",
                        providerName: "",
                        providerPhone: "",
                        providerEmail: "",
                        rejectCode: ""
                    });
                })
            }
            props.tollFreeTextStatus?.tollFreeTexts.forEach((x) => {
                x.Number = x.number;
                newList.push(x);
            })
            setTollFreeTextStatusList(_.map(newList, _.partialRight(_.pick, ['Number', 'statusType', 'providerName'])));
        }

    }, [props.tollFreeTextStatus, props.tollFreeNotInDatabase]);

    useEffect(() => {
        if (props.tollFreeNumberQuery) {
            let decodeExploreDataList = Buffer.from(props.tollFreeNumberQuery.reportFile, "base64").toString();
            Papa.parse(decodeExploreDataList, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    console.log("Finished:", results.data);
                    if (results.data) {
                        results.data.forEach((x) => {
                            x["respOrgId"] = x["RespOrg Id"]
                        })
                    }
                    setExploreDataSearch(results.data);
                }
            });
        }
    }, [props.tollFreeNumberQuery]);

    useEffect(() => {
        if (props.tollFreeQueryRouting) {
            let decodeExploreDataOfRoutingList = Buffer.from((props.tollFreeQueryRouting) ? props.tollFreeQueryRouting.reportFile : '', "base64").toString();
            Papa.parse(decodeExploreDataOfRoutingList, {
                header: true,
                skipEmptyLines: true,
                complete: function (results) {
                    console.log("Finished:", results.data);
                    if (props.tollFreeNotInDatabase) {
                        props.tollFreeNotInDatabase.forEach(x => {
                            results.data.push({
                                "Number": x, "number": x, "Network": "", "Carriers": "", "CPR Nodes": "", "Effective Date (ET)": ""

                            });
                        })
                    }
                    setExploreDataSearchOfRouting(_.map(results.data, _.partialRight(_.pick, ['Number', 'Network', 'Carriers', 'CPR Nodes', 'Effective Date (ET)'])));
                }
            });
        }

    }, [props.tollFreeQueryRouting, props.tollFreeNotInDatabase]);

    const columns = useCallback((exploreDataSearch: any, handleChangeSearchNumber: any, selectedReportType: any) => {
        console.log('Decoded data', exploreDataSearchOfRouting);
        let columnsList: object[] = [];
        let columnsListReports: object[] = [];
        const columnHeaders: Object[] = [];
        let removeDuplicateColumnList: any[] = [];
        let numberKeyAvailabile = false;
        let selectedReportOption: any = [];
        let CNAMReportAvailable: boolean = false;
        let fraudReportAvailable: boolean = false;
        if (exploreDataSearch) {
            for (const key in exploreDataSearch[0]) {
                if (reportColumns.indexOf(key) > -1) {
                   continue;
                }
                if (key === "Number") {
                    numberKeyAvailabile = true;
                }
                if (key !== 'propensity')
                    columnHeaders.push(key);
            }
        }
        selectedReportType && selectedReportType.forEach((reportSelection: any) => {
            if (reportSelection.status === true) {
                selectedReportOption.push(reportSelection.key)
            }
        })
        selectedReportOption && selectedReportOption.forEach((reports: any) => {
            if (reports === 'CNAM') {
                CNAMReportAvailable = true
            }
            if (reports === 'Fraud Score') {
                fraudReportAvailable = true
            }
        })
        
        columnsListReports = getColumns(intl, CNAMReportAvailable, fraudReportAvailable);

        removeDuplicateColumnList = columnHeaders.filter((item, pos) => columnHeaders.indexOf(item) === pos)
        if (removeDuplicateColumnList.length > 0) {
            defaultColumns.forEach((column) => {
                if (!removeDuplicateColumnList.find(x => x === column)) {
                    removeDuplicateColumnList.push(column);
                }
            })
        }
        if (removeDuplicateColumnList) {
            removeDuplicateColumnList.forEach((key) => {
                if (key === "providerName" || key === "respOrgId") return;
                if (key === "Number") {
                    const columnsObjRouting = {
                        Header: "Number",
                        accessor: key,
                        Cell: (cell: any) => (
                            <>
                                <ExploreSearchNamecell
                                    tollFreeNumber={cell.cell.row.original}
                                    handleInputChange={handleChangeSearchNumber}
                                />

                            </>
                        ),
                    };
                    columnsList.push(columnsObjRouting);
                    return;
                }
                if (key === "RespOrg Id") {
                    const columnsObjRouting = {
                        Header: "RespOrg Id",
                        accessor: key,
                        Cell: (cell: any) => (
                            <RespOrdIdCell
                                spId={cell.cell.row.original.respOrgId}
                            />
                        ),
                    };
                    columnsList.push(columnsObjRouting);
                    return;
                }
                if (key === "statusType") {
                    const columnsObjRouting = {
                        Header: "Text Status",
                        accessor: key,
                        Cell: (cell: any) => (getValueOrEmpty(cell.cell.row.original.providerName) ? <TextWithTooltip
                            className="f-big cursor-pointer"
                            tooltip={`Service Provider: ${cell.cell.row.original.providerName}`}
                            value={cell.cell.value}
                        /> : getValueOrEmpty(cell.cell.value)
                        )
                    }
                    columnsList.push(columnsObjRouting)
                    return;
                }
                if (key === "In Database") {
                    const columnsObjRouting = {
                        Header: "My Number",
                        accessor: key,
                        Cell: (cell: any) => (cell.cell.value),
                    };
                    columnsList.push(columnsObjRouting);
                    return;
                }
                const columnsObjRouting = {
                    Header: key,
                    accessor: key,
                    Cell: (cell: any) => getValueOrEmpty(cell.cell.value)
                }
                columnsList.push(columnsObjRouting)
            });
        }
        if (numberKeyAvailabile) {
            const columnsActionObj = {
                Header: intl.formatMessage({ id: "tollfree.table.actions" }),
                accessor: "actions",
                identifier: TollFreeNumberTableColumnIdentifiers.Actions,
                Cell: (cell: any) => (
                    <>
                        <TollFreeNumbersActionsCell
                            type="explore"
                            tollFreeNumber={cell.cell.row.original}
                        />
                    </>
                )
            }
            columnsListReports.push(columnsActionObj);
        }
       
        return columnsList.concat(columnsListReports);
    }, [exploreDataSearchOfRouting, intl]);

    return (
            <ReactTable
                showViewSettings
                hideSearch
                title={intl.formatMessage({ id: "explore.subtable.title" })}
                data={_.values(_.merge(_.keyBy(exploreDataSearch, 'Number'), _.keyBy(exploreDataSearchOfRouting, 'Number'), _.keyBy(tollFreeTextStatusList, 'Number'), _.keyBy(props.queryResult, 'Number')))}
                columns={columns(exploreDataSearch, props.handleChangeSearchNumber, props.selectedReportType)}
                autoResetPage={false}
                // handleSelectAll={handleSelectAll}
                // areSelectedAll={areSelectedAll}
                showDownload
            />
    )
}

const getColumns = (intl: IntlShape, CNAMReportAvailable: boolean, fraudReportAvailable: boolean) => {
    let resultOfReportsColumn: any = [];
    if (CNAMReportAvailable) {
        resultOfReportsColumn.push(
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.cnam" }),
                accessor: "name",
                className: "text-break",
                Cell: (cell: any) => cell.cell.row.original.name ? getValueOrEmpty(cell.cell.row.original.name) : "(no data)"
            }
        )
    }
    if (fraudReportAvailable) {
        resultOfReportsColumn.push(
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.score" }),
                accessor: "score",
                className: "text-break",
                Cell: (cell: any) => cell.cell.value ? getValueOrEmpty(cell.cell.value) : ''
            },
            {
                Header: intl.formatMessage({ id: "tollfree.reportTable.propensity" }),
                accessor: "propensity",
                className: "text-break",
                Cell: (cell: any) => cell.cell.value ? getValueOrEmpty(cell.cell.value) : ''
            },
        )
    }
    return resultOfReportsColumn
}