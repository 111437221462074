import React from "react";
import { Container, Row, Col } from "reactstrap";

type Props = {
  title: string;
};

export default function LoginHeader(props: Props) {
  return (
    <div className="header py-5 py-lg-6 pt-lg-6">
      <Container>
        <div className="header-body text-center mb-7 mt-7">
          <Row className="justify-content-center">
            <Col className="px-5" lg="6" md="8" xl="5">
              {props.title ? <h1 className="text-white">{props.title}</h1> : null}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
