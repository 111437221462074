
export type UserClient = {
  assigned: boolean,
  clientId: number,
  clientName: string,
  respOrgId: string,
  userId: number
};

export const DefaultUserClient: UserClient = {
  assigned: false,
  clientId: 0,
  clientName: "",
  respOrgId: "",
  userId: 0
};

