import { IdentityApi } from "services/apis/IdentityApi";
import {
  AuthenticationInfo,
  DefaultAuthenticationInfo
} from "services/appContext/AuthenticationInfo";
import { IdentityResponse } from "services/apis/types/identity/IdentityResponse";
import { AppContextAccessor } from "../appContext/AppContextAccessor";
import { DefaultUserClient, UserClient } from "services/apis/types/account/UserClient";

export const IdentityService = {
  clearAuthenticationInfo: (sessionExpired?: boolean) => {
    AppContextAccessor.setAppContext((appContext) => {
      return {
        ...appContext,
        localStorageInfo: {
          ...appContext.localStorageInfo,
          authenticationInfo: DefaultAuthenticationInfo,
          user: undefined,
          selectedClient: undefined,
          userClients: [],
          userRoles: [],
        },
        showSessionExpiredError: sessionExpired || false
      };
    });
  },

  login: async (username: string, password: string): Promise<IdentityResponse> => {
    return IdentityApi.login({ username, password }).then((response: IdentityResponse) => handleIdentityResponse(response));
  },

  userInfo: async (auth0AccessToken): Promise<IdentityResponse> => {
    return IdentityApi.userInfo(auth0AccessToken).then((response: IdentityResponse) => handleAuth0IdentityResponse(response, auth0AccessToken));
  },

  verifyAuthCode: async (username: string, authCode: string): Promise<IdentityResponse> => {
    return IdentityApi.verifyAuthCode({ username, authCode }).then((response: IdentityResponse) => handleIdentityResponse(response));
  },

  checkAndRenewAccessToken: async (): Promise<boolean> => {
    if (AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.authenticated) {
      if (
        accessTokenIsExpired(
          AppContextAccessor.getAppContext().localStorageInfo.authenticationInfo.expiry
        )
      ) {
        IdentityService.clearAuthenticationInfo(true);
        return false;
        // const identityResponse: IdentityResponse = await IdentityApi.refreshAccessToken();

        // if (renewFailed(identityResponse)) {
        //   IdentityService.clearAuthenticationInfo(true);

        //   return false;
        // }

        // AppContextAccessor.setAppContext((appContext) => {
        //   return {
        //     ...appContext,
        //     localStorageInfo: {
        //       ...appContext.localStorageInfo,
        //       authenticationInfo: getNewAuthenticationInfo(
        //         identityResponse,
        //         appContext.localStorageInfo.authenticationInfo
        //       )
        //     }
        //   };
        // });
      }

      return true;
    }

    return false;
  },

  // portControlApi: async (): Promise<boolean> => {
  //   const identityResponse = await IdentityApi.portControlApi();

  //   if (identityResponse.error) {
  //     return false;
  //   }

  //   AppContextAccessor.setAppContext((appContext) => {
  //     const authenticationInfo = getNewAuthenticationInfo(
  //       identityResponse,
  //       appContext.localStorageInfo.authenticationInfo
  //     );

  //     return {
  //       ...appContext,
  //       localStorageInfo: {
  //         ...appContext.localStorageInfo,
  //         authenticationInfo: {
  //           ...authenticationInfo,
  //           authenticated: true,
  //           // username: EnvironmentUtil.oAuthUsername
  //         }
  //       }
  //     };
  //   });

  //   return true;
  // },
};

const handleIdentityResponse = async (identityResponse: IdentityResponse): Promise<IdentityResponse> => {

  if (identityResponse && identityResponse.authPhone) { return identityResponse; }

  AppContextAccessor.setAppContext((appContext) => {
    const authenticationInfo = getNewAuthenticationInfo(
      identityResponse,
      appContext.localStorageInfo.authenticationInfo
    );

    return {
      ...appContext,
      localStorageInfo: {
        ...appContext.localStorageInfo,
        authenticationInfo: {
          ...authenticationInfo,
          authenticated: true,
          username: identityResponse.user.username
        },
        user: identityResponse.user,
        userClients: identityResponse.userClients,
        userRoles: identityResponse.userRoles,
      },
      selectedClient: getSelectedClient(appContext.selectedClient ? appContext.selectedClient : DefaultUserClient, identityResponse.userClients)
    };
  });
  return identityResponse;
};

const handleAuth0IdentityResponse = async (identityResponse: IdentityResponse, auth0AccessToken: string): Promise<IdentityResponse> => {

  if (identityResponse && identityResponse.authPhone) { return identityResponse; }

  AppContextAccessor.setAppContext((appContext) => {
    const authenticationInfo = getAuth0NewAuthenticationInfo(
      auth0AccessToken,
      appContext.localStorageInfo.authenticationInfo
    );

    return {
      ...appContext,
      localStorageInfo: {
        ...appContext.localStorageInfo,
        authenticationInfo: {
          ...authenticationInfo,
          authenticated: true,
          username: identityResponse.user.username
        },
        user: identityResponse.user,
        userClients: identityResponse.userClients,
        userRoles: identityResponse.userRoles,
      },
      selectedClient: getSelectedClient(appContext.selectedClient ? appContext.selectedClient : DefaultUserClient, identityResponse.userClients)
    };
  });
  return identityResponse;
};

const getSelectedClient = (selectedClient: UserClient, userClients: UserClient[]): UserClient => {
  const client = userClients.find((x) => x.clientId === selectedClient.clientId)
  if (client) return client;
  if (userClients.length > 0) return userClients[0];
  return selectedClient;
}

const getNewAuthenticationInfo = (
  identityResponse: IdentityResponse,
  authenticationInfo: AuthenticationInfo
): AuthenticationInfo => {
  const newAuthenticationInfo: AuthenticationInfo = {
    ...authenticationInfo,
    access_token: identityResponse.accessToken,
    expiry: getNewExpiryDate(identityResponse.expiresIn)
  };

  return newAuthenticationInfo;
};

const getAuth0NewAuthenticationInfo = (
  auth0AccessToken: string,
  authenticationInfo: AuthenticationInfo,
): AuthenticationInfo => {
  const newAuthenticationInfo: AuthenticationInfo = {
    ...authenticationInfo,
    access_token: auth0AccessToken,
    expiry: getNewExpiryDate(100000)
  };

  return newAuthenticationInfo;
};

const accessTokenIsExpired = (expiry: string): boolean => {
  // const date = new Date();
  // const expiryDate = new Date(expiry);

  // return date > expiryDate;
  return false;
};

const getNewExpiryDate = (expiresIn: number): string =>
  new Date(new Date().getTime() + expiresIn * 1000).toString();

