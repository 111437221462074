import React, { useCallback, useContext, useMemo, useState } from "react";
import {
    Container,
    Row,
    Col
} from "reactstrap";
import DisasterRecovery from "components/disasterrecovery/DisasterRecovery";
// import ComingSoon from "components/comingsoon/ComingSoon";
import Activities from "components/activities/Activities";
import { useEffect } from "react";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";
import { DisasterRecoveryApi } from "services/apis/DisasterRecoveryApi";
import { useIsMounted } from "services/customHooks/useIsMounted";
import { DisasterRecoveryStatusType } from "services/disasterRecovery/DisasterRecoveryStatusType";
import { handleError } from "services/util/ApiUtil";
import { authorize } from "services/authorization/AuthorizationService";
import { PermissionType } from "services/authorization/PermissionType";
import { AppContext } from "services/appContext/AppContext";
import { ActivityDto } from "services/activities/ActivityDto";
import { ActivityApi } from "services/apis/ActivityApi";
import { ActitivtyStatusType } from "services/activities/ActivityStatusType";
import { useIntl } from "react-intl";
import OutagePolygon from "components/outage/OutagePolygon";
import OutageConfirmationModal from "components/outage/OutageConfirmationModal";
import { MapApi } from "services/apis/MapApi";
import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
// import ChartNumbers from "components/numberStatistics/ChartNumbers";
import NumberStatisticsChart from "components/numberStatistics/NumberStatisticsChart";

type HomeContext = {
    activityActionCallback: () => void;
    disasterRecoveryActionCallback: () => void;
    confirmationZipcodeActionCallback: () => void;
    confirmationActionCallback: (mapAction: boolean) => void;
};

export const HomeContext = React.createContext<HomeContext>({
    activityActionCallback: () => { },
    disasterRecoveryActionCallback: () => { },
    confirmationZipcodeActionCallback: () => { },
    confirmationActionCallback: () => { }
})

export default function Home() {
    const [showDisasterRecoveryLoadingIndicator, setShowDisasterRecoveryLoadingIndicator] = useState(false);
    const [showActivityLoadingIndicator, setShowActivityLoadingIndicator] = useState(false);
    const [disasterTotalStatus, setDisasterTotalStatus] = useState("");
    const [activities, setActivities] = useState<Array<ActivityDto>>([]);
    const [disasterRecoveriesList, setDisasterRecoveries] = useState<Array<DisasterRecoveryDto>>([]);
    const isMounted = useIsMounted();
    const { appContext } = useContext(AppContext);
    const intl = useIntl();
    const [zipcodes, setZipcodes] = useState<Array<CarrierDto>>([]);
    const [showOutageConfirmationModal, setShowOutageConfirmationModal] = useState<boolean>(false);
    const [showOutagesLoadingIndicator, setShowOutagesLoadingIndicator] = useState<boolean>(false);
    /* const [centerOutages, setCenterOutages] = useState<Array<any>>([]);
     const [centerOutageLoadingIndicator, setCenterOutageLoadingIndicator] = useState<boolean>(false);*/

     const redirectToBlazor = useCallback(() => {
        if (process.env.REACT_APP_REDIRECT_BLAZOR_URL) { 
            window.location.href = `${process.env.REACT_APP_REDIRECT_BLAZOR_URL}?cid=${appContext.selectedClient?.clientId}`
        }
      },[appContext.selectedClient])
    
      useEffect(() => redirectToBlazor(), [redirectToBlazor]);
    
    const fetchConfirmationModal = useCallback((mapAction) => {
        setShowOutageConfirmationModal(mapAction);
    }, []);

    const fetchDisasterRecoveries = useCallback(() => {
        DisasterRecoveryApi.list()
            .then((result) => {
                if (isMounted.current) {
                    setDisasterRecoveries(result);
                    if (!result || result.length === 0) {
                        setDisasterTotalStatus("");
                    }
                }
            })
            .catch((error) => { handleError(error); setDisasterTotalStatus(""); })
            .finally(() => {
                if (isMounted.current) {
                    setShowDisasterRecoveryLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    const fetchActivities = useCallback(() => {
        const activityStatusMapping: { [key in ActitivtyStatusType]: string } = {
            [ActitivtyStatusType.cancel]: intl.formatMessage({ id: "activities.table.statustype.cancel" }),
            [ActitivtyStatusType.complete]: intl.formatMessage({ id: "activities.table.statustype.complete" }),
            [ActitivtyStatusType.pendingProcessor]: intl.formatMessage({ id: "activities.table.statustype.pendingProcessor" }),
            [ActitivtyStatusType.pendingValidation]: intl.formatMessage({ id: "activities.table.statustype.pendingValidation" }),
            [ActitivtyStatusType.pendingReview]: intl.formatMessage({ id: "activities.table.statustype.pendingReview" }),
            [ActitivtyStatusType.pendingAssign]: intl.formatMessage({ id: "activities.table.statustype.pendingAssign" }),
            [ActitivtyStatusType.pendingPort]: intl.formatMessage({ id: "activities.table.statustype.pendingPort" }),
            [ActitivtyStatusType.pendingDelete]: intl.formatMessage({ id: "activities.table.statustype.pendingDelete" })
        };
        var today = new Date();
        var priorDate = new Date(new Date().setDate(today.getDate() - 30));
        var dd = String(priorDate.getDate()).padStart(2, '0');
        var mm = String(priorDate.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = priorDate.getFullYear();

        let strToday = mm + '/' + dd + '/' + yyyy;
        ActivityApi.list(strToday, 3, 1, 10000)
            .then((result) => {
                if (isMounted.current) {
                    result.forEach(element => {
                        element.statusTypeDescription = activityStatusMapping[element.statusType];
                        element.modifyDate = element.modifyDate ? element.modifyDate : element.insertDate;
                    });
                    setActivities(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowActivityLoadingIndicator(false);
                }
            });
    }, [isMounted, intl]);

    const fetchOutages = useCallback(() => {
        setShowOutagesLoadingIndicator(true);
        const now = new Date();
        const event = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        MapApi.getZipcodes(event.toISOString())
            .then((result) => {
                if (isMounted.current) {
                    setZipcodes(result);
                    /*setCenterOutageLoadingIndicator(true);
                     MapApi.get(result[0]?.zipCode)
                         .then((result) => {
                             if (isMounted && result) {
                                 result && result.results.forEach((geoCenterObj) => {
                                     if (geoCenterObj.type === 'Geography' && (geoCenterObj.id.includes('US/GEO/p0') || geoCenterObj.id.includes('CA/GEO/p0')))
                                         if (geoCenterObj && geoCenterObj.dataSources?.geometry?.id !== undefined) {
                                             MapApi.getPolygon(geoCenterObj.dataSources?.geometry?.id)
                                                 .then((result) => {
                                                     if (isMounted && result) {
                                                         setCenterOutageLoadingIndicator(true);
                                                         result && result.additionalData.forEach((additionalObj) => {
                                                             additionalObj && additionalObj.geometryData && additionalObj.geometryData.features.forEach((geoObjCenter) => {
                                                                 if (geoObjCenter.geometry.type === "Polygon") {
                                                                     setCenterOutages(geoObjCenter.geometry.coordinates[0][0])
                                                                 }
                                                                 if (geoObjCenter.geometry.type === "MultiPolygon") {
                                                                     setCenterOutages(geoObjCenter.geometry.coordinates[0][0][0])
                                                                 }
                                                             })
                                                         })
                                                     }
                                                 })
                                                 .catch((error) => handleError(error))
                                                 .finally(() => {
                                                     if (isMounted) {
                                                         setCenterOutageLoadingIndicator(false);
                                                     }
                                                 });
                                         } else if (geoCenterObj && geoCenterObj.position) {
                                             const centerPosition = geoCenterObj.position;
                                             setCenterOutages([centerPosition?.lon, centerPosition?.lat]);
                                         } else {
                                             const center = [-77.697104, 39.077012];
                                             setCenterOutages(center);
                                         }
                                 })
                             }
                         })
                         .catch((error) => handleError(error))
                         .finally(() => {
                             if (isMounted) {
                                 setCenterOutageLoadingIndicator(false);
                             }
                         });*/
                }
            })
            .catch((error) => { handleError(error); })
            .finally(() => {
                if (isMounted.current) {
                    setShowOutagesLoadingIndicator(false);
                }
            });
    }, [isMounted]);

    useEffect(() => {
        if (process.env.REACT_APP_ATLAS_AUTO_REFRESH_TIMER && !isNaN(Number(process.env.REACT_APP_ATLAS_AUTO_REFRESH_TIMER))) {
            const interval = setInterval(() => {
                console.log('Auto refresh timer in millis', Number(process.env.REACT_APP_ATLAS_AUTO_REFRESH_TIMER));
                fetchDisasterRecoveries();
                fetchActivities();
                fetchOutages();
            }, Number(process.env.REACT_APP_ATLAS_AUTO_REFRESH_TIMER));
            return () => {
                clearInterval(interval);
            }
        }
    }, [fetchActivities, fetchDisasterRecoveries, fetchOutages]);

    useEffect(() => {
        if (authorize(PermissionType.DisasterRecovery)) {
            setShowDisasterRecoveryLoadingIndicator(true);
            fetchDisasterRecoveries();
            setShowActivityLoadingIndicator(true);
            fetchActivities();
            setShowOutagesLoadingIndicator(true);
            fetchOutages();
        }
    }, [fetchDisasterRecoveries, fetchActivities, fetchOutages, appContext.selectedClient]);


    const homeContext: HomeContext = {
        activityActionCallback: fetchActivities,
        disasterRecoveryActionCallback: fetchDisasterRecoveries,
        confirmationZipcodeActionCallback: fetchOutages,
        confirmationActionCallback: fetchConfirmationModal
    };

    /*const handleDisassterRecoveriesStatues = () => {
        const disasterRecoveries: DisasterRecoveryDto[] = [];
        if (disasterRecoveriesList.length > 0 && activities.length > 0) {
            let disasterRecoveryStatus = "none";
            const newDisasterRecoveriesList = Object.assign([], disasterRecoveriesList);
            newDisasterRecoveriesList.forEach((disasterRecovery: DisasterRecoveryDto) => {
                disasterRecovery.displayStatusType = disasterRecovery.statusType;
                const list = activities.filter((activity) => activity.description.includes(`'${disasterRecovery.name}'`)).sort((a, b) => new Date(b.modifyDate).getTime() - new Date(a.modifyDate).getTime());
                if (list.length > 0 && (list[0].statusType === ActitivtyStatusType.pendingAssign ||
                    list[0].statusType === ActitivtyStatusType.pendingDelete ||
                    list[0].statusType === ActitivtyStatusType.pendingPort ||
                    list[0].statusType === ActitivtyStatusType.pendingProcessor ||
                    list[0].statusType === ActitivtyStatusType.pendingValidation ||
                    list[0].statusType === ActitivtyStatusType.pendingReview)) {
                    disasterRecovery.displayStatusType = DisasterRecoveryStatusType.pending;
                    disasterRecoveryStatus = "pending";
                }
                disasterRecoveries.push(disasterRecovery)
            })
            if (disasterRecoveryStatus === "none") {
                disasterRecoveries.forEach((element: DisasterRecoveryDto) => {
                    element.displayStatusType = element.statusType;
                    if (element.statusType === DisasterRecoveryStatusType.initiated) {
                        disasterRecoveryStatus = "initiated";
                    }
                })
            }
            setDisasterTotalStatus(disasterRecoveryStatus);
        };
        return disasterRecoveries;
    }

    const disasterRecoveries = useMemo(() => handleDisassterRecoveriesStatues(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [disasterRecoveriesList, activities]);*/

    const disasterRecoveries = useMemo(() => {
        const disasterRecoveries: DisasterRecoveryDto[] = [];
        if (disasterRecoveriesList.length > 0) {
            let disasterRecoveryStatus = "none";
            const newDisasterRecoveriesList = Object.assign([], disasterRecoveriesList);
            newDisasterRecoveriesList.forEach((disasterRecovery: DisasterRecoveryDto) => {
                disasterRecovery.displayStatusType = disasterRecovery.statusType;
                if (activities.length > 0) {
                    const list = activities.filter((activity) => activity.description.includes(`'${disasterRecovery.name}'`)).sort((a, b) => new Date(b.modifyDate).getTime() - new Date(a.modifyDate).getTime());
                    if (list.length > 0 && (list[0].statusType === ActitivtyStatusType.pendingAssign ||
                        list[0].statusType === ActitivtyStatusType.pendingDelete ||
                        list[0].statusType === ActitivtyStatusType.pendingPort ||
                        list[0].statusType === ActitivtyStatusType.pendingProcessor ||
                        list[0].statusType === ActitivtyStatusType.pendingValidation ||
                        list[0].statusType === ActitivtyStatusType.pendingReview)) {
                        disasterRecovery.displayStatusType = DisasterRecoveryStatusType.pending;
                        disasterRecoveryStatus = "pending";
                    }
                }
                disasterRecoveries.push(disasterRecovery)
            })
            if (disasterRecoveryStatus === "none") {
                disasterRecoveries.forEach((element: DisasterRecoveryDto) => {
                    element.displayStatusType = element.statusType;
                    if (element.statusType === DisasterRecoveryStatusType.initiated) {
                        disasterRecoveryStatus = "initiated";
                    }
                })
            }
            setDisasterTotalStatus(disasterRecoveryStatus);
        };
        return disasterRecoveries;
    }, [disasterRecoveriesList, activities]);

    return (
        <>
            <HomeContext.Provider value={homeContext}>
                <Container fluid className="mt-6">
                    <Row className="pl-3 pr-3">
                        <Col lg="6" className="mb-4">
                            <Row>
                                <Col lg="12" className="p-0">
                                    <DisasterRecovery disasterRecoveries={disasterRecoveries} showLoadingIndicator={showDisasterRecoveryLoadingIndicator} />
                                </Col>
                                <Col lg="12" className="mt-4 p-0">
                                    {/* <ComingSoon /> */}
                                    {/* <NumberStatistics /> */}
                                    <NumberStatisticsChart />
                                </Col>
                            </Row>

                        </Col>
                        <Col lg="6" className="mb-4">
                            <Row>
                                <Col lg="12" className="p-0">
                                    <Activities activities={activities} status={disasterTotalStatus} showLoadingIndicator={showActivityLoadingIndicator} />
                                </Col>
                                <Col lg="12" className="mt-4 p-0">
                                    {showOutagesLoadingIndicator ? (<LoadingIndicator />) : (
                                        // (centerOutages.length > 0) && (
                                        <OutagePolygon status={disasterTotalStatus} zipcode={zipcodes} showLoadingIndicator={showOutagesLoadingIndicator} />
                                        // )
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col lg="6" className="mb-6">
                            <ComingSoon />
                        </Col> */}
                    </Row>
                </Container>
                {showOutageConfirmationModal && (
                    <OutageConfirmationModal closeModal={() => setShowOutageConfirmationModal(false)} />
                )}
            </HomeContext.Provider>
        </>
    );
}
