import { CarrierDto } from "services/disasterRecovery/CarrierDto";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";
import { RequestAddDisasterRecovery } from "services/disasterRecovery/RequestAddDisasterRecovery";
import { HttpClient } from "../httpClient/HttpClient";
import { ApiError } from "./types/ApiError";

export const DisasterRecoveryApi = {
  list: (): Promise<DisasterRecoveryDto[]> => {
    return HttpClient.get<DisasterRecoveryDto[]>("disasterRecoveries", true, true);
  },

  add: (disasterRecovery: RequestAddDisasterRecovery): Promise<ApiError> => {
    return HttpClient.post<ApiError>("disasterRecoveries", disasterRecovery, true, true);
  },

  modify: (disasterRecovery: DisasterRecoveryDto): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`disasterRecoveries/${disasterRecovery.id}`, disasterRecovery, true, true);
  },

  delete: (disasterRecoveryId: number): Promise<ApiError> => {
    return HttpClient.delete<ApiError>(`disasterRecoveries/${disasterRecoveryId}`, {});
  },

  initiate: (disasterRecoveryId: number): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`disasterRecoveries/${disasterRecoveryId}/initiate`, {});
  },

  revert: (disasterRecoveryId: number): Promise<ApiError> => {
    return HttpClient.post<ApiError>(`disasterRecoveries/${disasterRecoveryId}/revert`, {});
  },

  get: (disasterRecovery: DisasterRecoveryDto, includeTemplates: boolean): Promise<RequestAddDisasterRecovery> => {
    return HttpClient.get<RequestAddDisasterRecovery>(`disasterRecoveries/${disasterRecovery.id}?includeTemplates=${includeTemplates}`, true, true);

  },
  edit: (disasterRecovery: DisasterRecoveryDto, updateDisasterRecovery: RequestAddDisasterRecovery): Promise<ApiError> => {
    return HttpClient.put<ApiError>(`disasterRecoveries/${disasterRecovery.id}`, updateDisasterRecovery, true, true);
  },

  getCarrier: (carrierId: number): Promise<CarrierDto> => {
    return HttpClient.get<CarrierDto>(`carriers/${carrierId}`, true, true);
  },

};
