import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

export default function NavigatePortControl() {
    return (
        <Row className="mt-3">
            <Col lg="6">
                <Card className="m-3">
                    <CardBody>
                        <Row className="justify-content-center align-items-center">
                            <Col>
                                <h3 className="text-navigate"><FormattedMessage id="port.navigate.title" /></h3>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <Button
                                    color="primary"
                                    type="button"
                                    onClick={() => window.open(`${process.env.REACT_APP_PORT_NAVIGATE_URL}`, '_blank')}
                                >
                                    <FormattedMessage id="port.navigate.button.title" />
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}