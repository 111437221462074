import moment, { Moment } from "moment-timezone";
import { AppConfiguration } from "AppConfiguration";

export const getEstDate = (date?: Date | string): Moment => {
    const zone = 'America/New_York';
    return date ? moment.utc(date, 'YYYYMMDD HH:mm:ss').tz(zone) : moment().tz(zone).milliseconds(0).seconds(0).minutes(0).hours(0);
};

export const getFormattedEstDate = (date?: Date | string): string => {
    return (date && getEstDate(date).format(AppConfiguration.fullDateFormat)) || "";
};

export const isBetween = (
    value: any,
    min: Moment | string | Date,
    max: Moment | string | Date
) => value.isSameOrAfter(min) && value.isSameOrBefore(max);

export const sort = (dates: Array<Date | string>, desc?: boolean) => {
    return dates.sort((a, b) => {
        const aValue = getEstDate(a).toDate().getTime();
        const bValue = getEstDate(b).toDate().getTime();

        return desc ? bValue - aValue : aValue - bValue;
    });
};