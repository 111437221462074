import React, { useState, FormEvent, useCallback, useContext, useEffect } from "react";
import { Modal, Form, Button, Row, Label } from "reactstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { handleError, getFieldErrors } from "services/util/ApiUtil";
import { useErrors } from "services/customHooks/useErrors";
import { nameOf } from "services/util/ObjectUtil";
import { useIsMounted } from "services/customHooks/useIsMounted";
import CloseButton from "components/framework/modals/CloseButton";
import TextAreaFormInput from "components/framework/forms/TextAreaFormInput";
import CheckboxFormInput from "components/framework/forms/CheckboxFormInput";
import { TollFreeNumbersApi } from "services/apis/TollFreeNumbersApi";
import { AddError, Errors, HasErrors } from "components/framework/errorHandling/ErrorUtil";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";
import DropdownFormInput from "components/framework/forms/DropdownFormInput";
import { DefaultTemplateGroup, TemplateGroup } from "services/disasterRecovery/TemplateGroup";
import { TemplateApi } from "services/apis/TemplateApi";
import { WorkingTemplateInfo } from "services/apis/types/template/WorkingTemplateInfo";
import { PoolType, ReserveType } from "services/apis/types/toll-free-numbers/RequestTollFreeReserve";
import { AppContext } from "services/appContext/AppContext";
import { TollFreeProvisionDto } from "services/apis/types/toll-free-numbers/TollFreeProvisionDto";
import { TollFreeSearchNumberDto } from "services/apis/types/toll-free-numbers/TollFreeSearchNumberDto";
import { TollFreeNumbersDto } from "services/apis/types/toll-free-numbers/TollFreeNumbersDto";
import { showInfoNotification } from "components/framework/notification/NotificationUtil";

type Props = {
    closeModal: () => void;
    tollFreeNumber?: TollFreeNumbersDto;
};

export default function TollFreeQueryEditReserveModal(props: Props) {
    const { setErrors, getErrorHandler } = useErrors();
    const [reserveNotes, setReserveNotes] = useState<string>("");
    const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
    const intl = useIntl();
    const isMounted = useIsMounted();
    const [selectedReserveType, setSelectedReserveType] = useState<Array<TemplateGroup>>([DefaultTemplateGroup]);
    const [showWorkingTemplateLoadingIndicator, setShowWorkingTemplateLoadingIndicator] = useState(false);
    const [workingTemplates, setWorkingTemplates] = useState<Array<WorkingTemplateInfo>>([]);
    const [provisionIds, setProvisionIds] = useState<Array<TollFreeProvisionDto>>([]);
    const [showProvisionLoadingIndicator, setShowProvisionLoadingIndicator] = useState(false);
    const [showTollFreeNumberListLoadingIndicator, setShowTollFreeNumberListLoadingIndicator] = useState(false);
    const [searchTollFreeNumberList, setSearchTollFreeNumberList] = useState<Array<TollFreeSearchNumberDto>>([]);
    const { appContext } = useContext(AppContext);
    const [errormessage, setErrorMessage] = useState<boolean>(false);

    const { tollFreeNumber } = props;

    const closeModal = useCallback(props.closeModal, []);

    const fetchWorkingTemplates = useCallback(() => {
        setShowWorkingTemplateLoadingIndicator(true)
        TemplateApi.list(false, true, true, 1, 10000)
            .then((result) => {
                if (isMounted.current) {
                    setWorkingTemplates(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowWorkingTemplateLoadingIndicator(false);
                }
            });
    }, [isMounted]);
    const fetchProvision = useCallback(() => {
        setShowProvisionLoadingIndicator(true)
        TollFreeNumbersApi.provision(appContext.selectedClient?.clientId)
            .then((result) => {
                if (isMounted.current) {
                    setProvisionIds(result);
                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowProvisionLoadingIndicator(false);
                }
            });
       
    }, [isMounted, appContext.selectedClient]);

    const fetchTollFreeNumberSearch = useCallback(() => {
        setShowTollFreeNumberListLoadingIndicator(true);
        let number: any = '';
        if (tollFreeNumber?.number) {
            number = tollFreeNumber?.number
        } else {
            number = tollFreeNumber?.Number
        }
        let searchTollFreeNumbers = {
            pattern: number,
            // pattern: "8338790540",
            poolType: PoolType.Somos
        }
        TollFreeNumbersApi.searchReserve(searchTollFreeNumbers)
            .then((result) => {
                if (isMounted.current) {
                    setSearchTollFreeNumberList(result);
                    if (!result.length) {
                        setErrorMessage(true);
                    }

                }
            })
            .catch((error) => handleError(error))
            .finally(() => {
                if (isMounted.current) {
                    setShowTollFreeNumberListLoadingIndicator(false);
                }
            });
        
    }, [isMounted, tollFreeNumber]);

    useEffect(() => {
        fetchWorkingTemplates();
        fetchProvision();
        fetchTollFreeNumberSearch();
        
    }, [fetchWorkingTemplates, fetchProvision, fetchTollFreeNumberSearch]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setShowLoadingIndicator(true);
        const errors = validateTollFreeNumberReserve(selectedReserveType);
        let numberList: any[] = [];
        searchTollFreeNumberList.forEach((numberObj) => {
            numberList.push(numberObj.number)
        });
        let templateId;
        selectedReserveType.forEach((reserveType) => {
            templateId = reserveType.workingTemplateId;
        });


        let provisionIdArray: any[] = [];
        provisionIds.forEach((provisionObj) => {
            let provisionIdValues = provisionObj.id;
            provisionIdArray.push(provisionIdValues);
        });
        if (HasErrors(errors)) {
            setErrors(errors);
            if (isMounted.current) {
                setShowLoadingIndicator(false);
                setShowWorkingTemplateLoadingIndicator(false);
                setShowProvisionLoadingIndicator(false);
            }
        } else {
            const tollFreeNumberBulkQueryRequest: any = {
                numbers: numberList,
                templateId,
                provisionIds: provisionIdArray,
                notes: reserveNotes,
                reserveType: ReserveType.Specific,
            }
            TollFreeNumbersApi.reserve(tollFreeNumberBulkQueryRequest)
                .then(() => {
                    showInfoNotification(
                        intl.formatMessage({ id: "tollFreeNumber.query.successNotificationMessage" })
                    );
                    closeModal();
                })
                .catch((error) => {
                    handleError(error);
                    if (isMounted.current) {
                        const errorsResult = getFieldErrors(error.fieldErrors);
                        setErrors(errorsResult);
                    }
                    closeModal();
                })
                .finally(() => {
                    if (isMounted.current) {
                        setShowLoadingIndicator(false);
                    }
                });
        }
    };


    const handleSelectedTemplateChanges = (reserve: TemplateGroup, index: number) => {
        const newSelectedReserveType = [...selectedReserveType];
        newSelectedReserveType[index] = reserve;
        setSelectedReserveType(newSelectedReserveType);
    }

    const handleChangeProvision = (provisionList: TollFreeProvisionDto, index: number) => {
        const newCheckedProvision = [...provisionIds];
        newCheckedProvision[index] = provisionList;
        setProvisionIds(newCheckedProvision);
    }

    return (

        <Modal className="modal-dialog-centered modal-sm" isOpen={true} zIndex={2147483648}>
            <div>
                {showTollFreeNumberListLoadingIndicator &&
                    <LoadingIndicator />}
                {(searchTollFreeNumberList.length > 0) ? (
                    <div>
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {searchTollFreeNumberList.map((searchNumber) => (
                                    <Label className="form-control-label">{searchNumber.number}</Label>
                                ))}
                            </h5>
                            <CloseButton close={props.closeModal} />
                        </div>
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <div className="modal-body">
                                {selectedReserveType.map((reserve, index) => (
                                    <>
                                        <Row>
                                            {showWorkingTemplateLoadingIndicator &&
                                                <LoadingIndicator />}

                                            {workingTemplates.length > 0 ? (
                                                <DropdownFormInput
                                                    id={index}
                                                    canDelte={index > 0}
                                                    className="col-lg-12"
                                                    labelTranslationId={"disasterRecovery.create.workingTemplateTitle"}
                                                    value={reserve.workingTemplateId.toString()}
                                                    handleInputChange={(value: string) =>
                                                        handleSelectedTemplateChanges({ ...reserve, workingTemplateId: parseInt(value) }, index)
                                                    }
                                                    errorHandler={getErrorHandler(`${nameOf<TemplateGroup>("workingTemplateId")}${index}`)}
                                                    options={[
                                                        { key: intl.formatMessage({ id: "disasterRecovery.create.selectTemplate" }), value: "" },
                                                        ...workingTemplates.map(x => {
                                                            return { key: x.name, value: x.id };
                                                        })
                                                    ]}

                                                />
                                            ) : ''}
                                        </Row>
                                    </>
                                ))}
                                <div>
                                    {showProvisionLoadingIndicator &&
                                        <LoadingIndicator />}
                                    {provisionIds && provisionIds.map((element, index) => (
                                        <>
                                            <label className="form-control-label"><FormattedMessage id={"tollFreeNumber.bulkQuery.provision"} /></label>
                                            <div className="col-lg-12">
                                                <CheckboxFormInput
                                                    className="col-lg-6 checkbox-settings-sub pl-2"
                                                    label={`${element.carrierType} - ${element.name}`}
                                                    value={(element.id).toString()}
                                                    checked={element.status}
                                                    handleInputChange={(value: boolean) =>
                                                        handleChangeProvision({ ...element, status: value }, index)
                                                    }
                                                >
                                                </CheckboxFormInput>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <Row>
                                    <TextAreaFormInput
                                        labelTranslationId="tollFreeNumber.create.reserveNotes"
                                        rows={4}
                                        value={reserveNotes}
                                        handleInputChange={(value: string) =>
                                            setReserveNotes(value)
                                        }
                                        className="col-lg-12"
                                    />
                                </Row>
                            </div>

                            <div className="modal-footer justify-content-end">
                                <Button color="primary" className="btn btn-outline-default" type="button" onClick={() => props.closeModal()}>
                                    <FormattedMessage id="tollFreeNumber.create.cancelButton" />
                                </Button>
                                <Button color="primary" type="submit" disabled={showLoadingIndicator}>
                                    {showLoadingIndicator && <i className="fas fa-spinner fa-spin mr-2" />}
                                    <FormattedMessage id="disasterRecovery.create.submitButton" />
                                </Button>
                            </div>
                        </Form>
                    </div>
                ) : (
                    <></>
                )}

                {(errormessage) ? (
                    <>
                        <div className="alert alert-info mb-0" role="alert">
                            <span className="alert-icon"><i className="fa fa-exclamation-triangle fa-2x"></i></span>
                            <span className="alert-text"><FormattedMessage id="tollFree.bulkQuery.message1" /></span>
                            <CloseButton close={props.closeModal} />
                        </div>
                    </>) : (<></>)}
            </div>
        </Modal>

    );
}




const validateTollFreeNumberReserve = (tollFreeReserveGroup: TemplateGroup[]) => {
    const errors: Errors = {};

    tollFreeReserveGroup.forEach((template: TemplateGroup, index: number) => {
        if (!template.workingTemplateId || template.workingTemplateId <= 0) {
            AddError(errors, `${nameOf<TemplateGroup>("workingTemplateId")}${index}`, "disasterRecovery.create.workingTemplate");
        }
    });
    return errors;
};


