import React, { useContext } from "react";
import {
    Card,
    CardBody,
    Container,
    Row,
    Col,
} from "reactstrap";
import { FormattedMessage } from "react-intl";
import { AppContext } from "services/appContext/AppContext";
import DisasterRecoveryCard from "./DisasterRecoveryCard";
import { DisasterRecoveryDto } from "services/disasterRecovery/DisasterRecoveryDto";
import { LoadingIndicator } from "components/framework/loadingIndicator/LoadingIndicator";

type Props = {
    showLoadingIndicator : boolean;
    disasterRecoveries: Array<DisasterRecoveryDto>;
};

export default function DisasterRecovery(props: Props) {
    const { appContext } = useContext(AppContext);
    return (
        <>
            {appContext && appContext.client?.hasDisasterRecovery ?
                <Container className="nopadding hight-100">
                    {props.showLoadingIndicator ?
                        <LoadingIndicator white />
                        :
                        <DisasterRecoveryCard disasterRecoveries={props.disasterRecoveries} />
                    }
                </Container> :
                <Container className="nopadding hight-100">
                    <Row className="justify-content-center hight-100">
                        <Col className="nopadding">
                            <Card className="bg-secondary border-0 mb-0 hight-100">
                                <CardBody>
                                    <div className="header">
                                        <div className="text-center">
                                            <span>
                                                <i className="fad fa-info-circle fa-3x text-info text-center"></i>
                                            </span>
                                        </div>
                                        <h3 className="flex-grow-1 text-center margin-top-29">
                                            <FormattedMessage id="disastorRecovery.disabled" />
                                        </h3>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
}
