import React from "react";
import { ButtonGroup } from "reactstrap";
import { FormattedMessage } from "react-intl";
import { CSVLink } from "react-csv";
import _ from "lodash";

export default function Download(props) {
    const table = dataToTable(props.data, props.columns);

    return (
        <ButtonGroup className={props.queryDownload && "csv_button"}>
            <CSVLink
                data={table}
                target="_blank"
                className="btn btn-default btn-sm bg-primary text-white rounded mr-2 border-0"
                filename={props.queryDownload ? "Query.csv" : "TollFreeNumbers.csv"}>
                <FormattedMessage id="table.download" />
            </CSVLink>
            {/* <Button color="default" type="button" size="sm" className="bg-primary text-white rounded mr-2 border-0" onClick={() => exportToXslxHandler(table)}>
                <FormattedMessage id="table.download" />
            </Button> */}
        </ButtonGroup>
    );
}

// function exportToXslxHandler(matrix) {
//     let workbook = { SheetNames: ["Sheet1"], Sheets: { Sheet1: utils.aoa_to_sheet(matrix) } };

//     writeFile(workbook, "result.xlsx");
// }

function dataToTable(data, columns) {
    let table = [columns.map((column) => column.Header)];

    if (data) {
        data
            .map((row) =>
                columns.map((column) => (_.get(row, column.accessor) ? `${_.get(row, column.accessor)}` : ""))
            )
            .forEach((x) => table.push(x));
    }

    return table;
}
