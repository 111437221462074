import FormateDate from "components/framework/date/FormateDate";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CardHeader, Tooltip } from "reactstrap";
//import { ServiceProviderApi } from "services/apis/ServiceProviderApi";
import { ServiceProviderProfileResult } from "../types/ServiceProviderProfileResult";
import { SvType } from "services/apis/types/block/SvType";
// import { authorize } from "services/authorization/AuthorizationService";
// import { PermissionType } from "services/authorization/PermissionType";
import { TradingPartnerProfile } from "services/apis/types/tpp/TradingPartnerProfile";
import { isSpid } from "services/util/StringUtil";
import { ServiceProviderApi } from "services/apis/ServiceProviderApi";

type Props = {
  serviceProviderResult: ServiceProviderProfileResult;
  triggerRefreshLogo: boolean;
  createMode: boolean;
  readOnlyMode: boolean;
  tpp?: TradingPartnerProfile;
  setReadOnlyMode: (value: boolean) => void;
  serviceProviderAccessToken: string
};

export default function ExploreProfileCardHeader(props: Props) {
  const [defaultIcon, setDefaultIcon] = useState(false);
  const [logo, setLogo] = useState<string>();
  const [verifiedDateTooltipOpen, setVerifiedDateTooltipOpen] = useState(false);
  const editMode = !props.readOnlyMode;

  useEffect(() => {
    if (props.serviceProviderResult.spId) {
      ServiceProviderApi.getLogo(props.serviceProviderResult.spId, props.serviceProviderAccessToken)
        .then((x) => {
          console.log('hfksfk', x instanceof Blob, x);
          if (x instanceof Blob) {

            setLogo(URL.createObjectURL(x));
          }
        })
        .catch(() => setDefaultIcon(true));
    } else {
      setDefaultIcon(true);
    }
    
  }, [props.serviceProviderResult.spId, props.triggerRefreshLogo, props.serviceProviderAccessToken]);

  return (
    <CardHeader className="d-flex">
      <div>
        {defaultIcon ? (
          <div className="avatar rounded-circle bg-primary">
            <i className="fas fa-building fa-lg"></i>
          </div>
        ) : (
          <div className="pc-logo mt-2">
            <img src={logo} alt="" onError={() => setDefaultIcon(true)} />
          </div>
        )}
      </div>
      <div className="flex-grow-1 mx-2 d-flex align-items-center">
        {props.createMode && (
          <h5 className="modal-title">
            <FormattedMessage id="tppModal.createTradingPartnerProfile" />
          </h5>
        )}
        {!props.createMode && (
          <div>
            {editMode ? (
              <>
                <div className="mb-0">
                  <FormattedMessage id="tppModal.modifyTradingPartnerProfile" />
                </div>
                <h5 className="mb-0 text-gray">
                  {props.serviceProviderResult.spId} -{" "}
                  {props.serviceProviderResult.name}
                </h5>
              </>
            ) : (
              <>
                <div className="d-flex align-items-center">
                  <div className="mb-0 mr-1">
                    {props.serviceProviderResult.name}
                  </div>
                  {props.serviceProviderResult.contactInformation
                    ?.verifiedDate && (
                      <i
                        className="fa fa-badge-check text-success"
                        id="verifiedDateBadge"
                      >
                        <Tooltip
                          placement="right"
                          isOpen={verifiedDateTooltipOpen}
                          target="verifiedDateBadge"
                          toggle={() =>
                            setVerifiedDateTooltipOpen(!verifiedDateTooltipOpen)
                          }
                        >
                          <FormattedMessage
                            id="tppModal.verifiedBadgeLabel"
                            values={{
                              date: (
                                <FormateDate
                                  utcDate={
                                    props.serviceProviderResult.contactInformation
                                      ?.verifiedDate
                                  }
                                />
                              ),
                            }}
                          />
                        </Tooltip>
                      </i>
                    )}
                </div>
                <h5 className="mb-0 text-gray">
                  <FormattedMessage
                    id={
                      isSpid(props.serviceProviderResult.spId)
                        ? "tppModal.spid"
                        : "tppModal.respOrg"
                    }
                    values={{ item: props.serviceProviderResult.spId }}
                  />
                </h5>
                <h5 className="mb-0 text-gray">
                  {Object.keys(SvType).find(
                    (x) => SvType[x] === props.serviceProviderResult.type
                  )}
                </h5>
                {props.tpp?.united && (
                  <h5 className="mb-0 text-success">
                    <FormattedMessage id="tppModal.united" />
                  </h5>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="mr-2">
        {props.readOnlyMode && (
          <button
            aria-label="Close"
            className="close position-absolute top-modify-resp right-0 sticky-top p-0 pc-close-modal mr-5"
            data-dismiss="modal"
            type="button"
            onClick={() => props.setReadOnlyMode(false)}
          >
            {/* <i
              className="fa fa-xs fa-edit cursor-pointer"
              data-toggle="tooltip"
              data-placement="top"
              title="Modify"
            /> */}
          </button>
        )}
      </div>

    </CardHeader>
  );
}
