export const SvType: Record<string, string> = {
  Wireline: "wireline",
  Wireless: "wireless",
  class2_voip_no_num_assgnmt: "class2_voip_no_num_assgnmt",
  vowifi: "vowifi",
  prepaid_wireless: "prepaid_wireless",
  class1_and_2_voip_with_num_assgnmt: "class1_and_2_voip_with_num_assgnmt",
  sv_type_6: "sv_type_6",
  sv_type_7: "sv_type_7",
  sv_type_8: "sv_type_8",
  sv_type_9: "sv_type_9",
};
