
import { TableSettings } from "services/uiSettings/TableSettings";
import { TableIdentifiers } from "services/uiSettings/TableIdentifiers";

export const DefaultTableSettings: Record<TableIdentifiers, TableSettings> = {
  [TableIdentifiers.DisasterRecoveries]: {
    identifier: TableIdentifiers.DisasterRecoveries,
    columnSettings: [
    ],
  },
  [TableIdentifiers.TollFreeNumbers]: {
    identifier: TableIdentifiers.TollFreeNumbers,
    columnSettings: [
    ],
  }
};
