import { InstructionDto } from "services/disasterRecovery/InstructionDto";
import { HttpClient } from "services/httpClient/HttpClient";
import { WorkingTemplateInfo } from "./types/template/WorkingTemplateInfo";

export const TemplateApi = {
  list: (routingOnly: boolean, hasSmsName: boolean, includeTollFreeNumbers: boolean, pageNumber: number, pageSize: number): Promise<WorkingTemplateInfo[]> => {
    return HttpClient.get<WorkingTemplateInfo[]>(`templates?routingOnly=${routingOnly}&hasSmsName=${hasSmsName}&includeTollFreeNumbers=${includeTollFreeNumbers}&pageNumber=${pageNumber}&pageSize=${pageSize}`, true, true);

  },

  getInstructions: (templateId: number, includeOrigValues: boolean): Promise<InstructionDto[]> => {
    return HttpClient.get<InstructionDto[]>(`templates/${templateId}/instructions?includeOrigValues=${includeOrigValues}`, true, true);
  },
};
